<template>
<div>
    <div class="loading" v-if="fetchData">
        <load-data></load-data>
    </div>
    <div class="attainmentCO" v-else>
        <b-row>
            <b-col md="12">
                <div>
                    <div>
                        <table class="table table-bordered table-striped">
                            <thead class="thead-dark text-left">
                                <tr>
                                    <th></th>
                                    <th class="align-middle" v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                                        {{ co.code }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="reports.enable_assessment">
                                    <td class="align-middle">Assessments</td>
                                    <td v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                                        {{
                          reports.assessmentAttainment[co.id].attainment_level ==
                            null
                            ? 0
                            : reports.assessmentAttainment[co.id].attainment_level
                      }}
                                    </td>
                                </tr>
                                <tr v-if="reports.enable_assignment">
                                    <td class="align-middle">Assignment</td>
                                    <td v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                                        {{
                          reports.assignmentAttainment[co.id].attainment_level ==
                            null
                            ? 0
                            : handleValue(
                              reports.assignmentAttainment[co.id]
                                .attainment_level
                            )
                      }}
                                    </td>
                                </tr>
                                <tr v-if="(courseDetails.type && courseDetails.type.slug == 'laboratory')">
                                    <td class="align-middle">Laboratory</td>
                                    <td class="align-middle" v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                                        {{
                          reports.laboratoryAttainment[co.id] ==
                            null
                            ? 0
                            : handleValue(
                              reports.laboratoryAttainment[co.id]
                            )
                      }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-middle">University Exam</td>
                                    <td class="align-middle" v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                                        {{ handleValue(reports.boardexamAttainment[co.id]
                          .attainment_level)
                      }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-middle">Survey</td>
                                    <td class="align-middle" v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                                        {{
                          reports.surveyAttainment[co.id] == null
                            ? 0
                            : handleValue(reports.surveyAttainment[co.id])
                      }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-middle">Final</td>
                                    <td v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                                        {{ handleValue(reports.final_attainment[co.id]) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-col>
        </b-row>
        <!-- <b-row>
        <b-col>
          <div class="selectReportType mb-2 d-flex justify-content-center">
            <b-form-radio v-model="reportType" :value="0"
              >Oridinary Report</b-form-radio
            >
            <b-form-radio v-model="reportType" :value="1" class="ml-3"
              >ISO Report</b-form-radio
            >
          </div>
        </b-col>
      </b-row> -->
        <b-row>
            <b-col>
                <b-button class="my-3" @click="saveAttainmentConfigs()" variant="success" size="sm" :disabled="btn.disabled">Save
                    selection and configuration for course file report</b-button>
            </b-col>
        </b-row>

        <div class="table-responsive">
            <table class="table table-bordered table-striped">
                <thead class="thead-dark text-left">
                    <tr>
                        <th colspan="2">Download Reports PDF </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Course Outcomes</td>
                        <td>
                            <div>
                                <b-button @click="downloadAttainmentReport('courseoutcome', false)" variant="info" size="sm" :disabled="btn.disabled">Download Report</b-button>
                                <b-button style="margin-left: 1px" variant="primary" @click="downloadAttainmentReport('courseoutcome', true)" size="sm">View Report
                                </b-button>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>Assessment</td>
                        <td>
                            <div>
                                <b-button @click="downloadAttainmentReport('assessment', false)" variant="info" size="sm" :disabled="btn.disabled">
                                    Download Report</b-button>
                                    <b-button style="margin-left: 1px" variant="primary" @click="downloadAttainmentReport('assessment', true)" size="sm">View Report
                                    </b-button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Assignment</td>
                        <td>
                            <div>
                                <b-button @click="downloadAttainmentReport('assignment', false)" variant="info" size="sm" :disabled="btn.disabled">
                                    Download Report</b-button>
                                <b-button style="margin-left: 1px" variant="primary" @click="downloadAttainmentReport('assignment', true)" size="sm">View Report
                                </b-button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Course Exit Survey</td>
                        <td>
                            <div>
                                <b-button @click="downloadAttainmentReport('courseexitsurvey', false)" variant="info" size="sm" :disabled="btn.disabled">Download Report</b-button>
                                <b-button style="margin-left: 1px" variant="primary" @click="downloadAttainmentReport('courseexitsurvey', true)" size="sm">View Report
                                </b-button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Laboratory</td>
                        <td>
                            <div>
                                <b-button @click="downloadAttainmentReport('laboratory', false)" variant="info" size="sm" :disabled="btn.disabled">
                                    Download Report</b-button>
                                <b-button style="margin-left: 1px" variant="primary" @click="downloadAttainmentReport('laboratory', true)" size="sm">View Report
                                </b-button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>University Exam</td>
                        <td>
                            <div>
                                <b-button @click="downloadAttainmentReport('boardexam', false)" variant="info" size="sm" :disabled="btn.disabled">
                                    Download Report</b-button>
                                <b-button style="margin-left: 1px" variant="primary" @click="downloadAttainmentReport('boardexam', true)" size="sm">View Report
                                </b-button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>CO Attainment</td>
                        <td>
                            <div>
                                <b-button @click="downloadAttainmentReport('attainment', false)" variant="info" size="sm" :disabled="btn.disabled">
                                    Download Report</b-button>
                                <b-button style="margin-left: 1px" variant="primary" @click="downloadAttainmentReport('attainment', true)" size="sm">View Report
                                </b-button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>PO Attainment</td>
                        <td>
                            <div>
                                <b-button @click="
                      downloadAttainmentReport('co_po_pso_mapping', false)
                    " variant="info" size="sm" :disabled="btn.disabled">
                                    Download Report</b-button>
                                <b-button style="margin-left: 1px" variant="primary" @click="downloadAttainmentReport('co_po_pso_mapping', true)" size="sm">View Report
                                </b-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ["courseReport", "courseDetails"],
    async created() {
        this.fetchData = true;
        await this.getAttainmentReport();
        this.fetchData = false;
    },
    data() {
        return {
            fetchData: false,
            assignments: [],
            assignmentQuestions: {},
            selectedAssignments: [],
            targetPercentage: "",
            assignmentsArray: [],
            reports: {},
            btn: {
                text: "DOWNLOAD PDF REPORT",
                disabled: false,
            },
            reportType: 0,
        };
    },
    methods: {
        async downloadAttainmentReport(step, viewReport) {
            // this.downloadData = true;
            // this.$bvModal.hide("attainmentReport");
            this.btn = {
                text: "Please wait...",
                disabled: true,
            };
            const url =
                this.$store.getters.getAPIKey.mainAPI +
                `/coattainment/report/pdfexport`;
            await this.$axios
                .post(
                    url, {
                        course: this.courseReport.course_id,
                        step: step
                    }, {
                        responseType: "blob"
                    }
                )
                .then((response) => {
                    const pdfBlob = new Blob([response.data], {
                        type: "application/pdf",
                    });
                    const pdfUrl = window.URL.createObjectURL(pdfBlob);
                    let newTab = null;
                    if (viewReport) {
                        newTab = window.open(pdfUrl, "_blank");
                    }
                    if (!newTab) {
                        if (viewReport)
                            this.$toast.error(
                                "Failed to show pdf file, insted the file will be downloaded", {
                                    position: "top",
                                    duration: 3000,
                                }
                            );
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "AttainmentList.pdf");
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.$toast.error(error.response.data.message, {
                            position: "top",
                            duration: 3000,
                        });
                    } else {
                        this.$toast.error("Failed to download", {
                            position: "top",
                            duration: 3000,
                        });
                    }
                    // this.downloadData = false;
                });
            this.btn = {
                text: "DOWNLOAD PDF REPORT",
                disabled: false,
            };
        },

        async saveAttainmentConfigs() {
            const url =
                this.$store.getters.getAPIKey.mainAPI +
                `/coattainment/savedata`;
            await this.$axios
                .post(url, {
                    course: this.courseReport.course_id
                })
                .then(() => {
                    this.$toast.success('Configuration saved successfully', {
                        position: "top",
                        duration: 3000,
                    });
                })
                .catch(() => {
                    this.$toast.error('Failed save configuration', {
                        position: "top",
                        duration: 5000,
                    });
                });
        },
        handleValue(num) {
            if (isNaN(num)) {
                return "";
            } else {
                return num % 1 == 0 ? Math.floor(num) : parseFloat(num).toFixed(2);
            }
        },
        async getAttainmentConfigration() {

        },
        async setAttainmentConfigration() {

        },
        async getAttainmentReport() {
            const url =
                this.$store.getters.getAPIKey.mainAPI + `/coattainment/report`;
            await this.$axios
                .post(url, {
                    course: this.courseReport.course_id,
                    step: "attainment",
                })
                .then((response) => {
                    this.reports = response.data;
                });
        },
    },
};
</script>
