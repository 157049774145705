<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="attainmentCO" v-else>
      <b-row>
        <b-col md="12">
          <div class="viewOrConfig mb-3">
            <b-button size="sm" :variant="config && !report ? 'success' : 'outline-primary'" class="font-weight-bolder"
              disabled>Configured</b-button>
            <b-button size="sm" disabled :variant="!config && !report ? 'success' : 'outline-primary'"
              class="ml-4 font-weight-bolder">View Data</b-button>
            <b-button size="sm" disabled :variant="report ? 'success' : 'outline-primary'"
              class="ml-4 font-weight-bolder">View Report</b-button>
          </div>

          <div v-if="config && !report">
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark text-left">
                  <tr>
                    <th>Select</th>
                    <th>University Exams</th>
                    <th>Questions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(boardexam, boardexamIndex) in boardexams" :key="boardexamIndex">
                    <td class="align-middle">
                      <b-form-radio @change="selectAllQuestionsOfBoardexam(boardexam.value)" :value="boardexam.value"
                        class="ml-3" v-model="selectedBoardexamId">
                      </b-form-radio>
                    </td>
                    <td class="align-middle">
                      {{ boardexam.text }}
                    </td>
                    <td class="align-middle text-left">
                      <div class="d-flex flex-wrap" v-if="selectedBoardexamId == boardexam.value">
                        <div v-for="(ques, quesIndex) in boardexamQuestions[boardexam.value].questions"
                          :key="quesIndex">
                          <b-form-checkbox class="ml-3" v-model="selectedQuestionIds" :value="ques.value">
                            {{ ques.text }}
                          </b-form-checkbox>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="!config && !report">
            <table class="table table-bordered " v-if="boardexam.mark_entry_type=='subject_wise'">
              <thead class="thead-dark text-left">
                <tr>
                  <th>Maximum mark</th>
                  <th>Pass Mark</th>
                </tr>
              </thead>
              <tbody class="thead-dark text-left">
                <tr>
                  <td>
                    {{ boardExamConfig.exam_properties.maximum_mark }}
                  </td>
                  <td>
                    {{ boardExamConfig.exam_properties.pass_mark }}
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="table-responsive" v-if="boardexam.mark_entry_type=='subject_wise'">
              <h5>Subject wise Score of participants in University exam</h5>
              <table class="table table-bordered table-striped">
                <thead class="thead-dark text-left">
                  <tr>
                    <th></th>
                    <th class="text-center">
                      <span v-if="boardExamConfig.exam_properties.enable_grading">Grade</span>
                      <br />
                      <span v-if="!boardExamConfig.exam_properties.enable_grading">Score</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student,
                  studentIndex) in course_students" :key="studentIndex">
                    <td class="align-middle">
                      {{ student.first_name || " " }}
                      {{ student.last_name || " " }}
                    </td>
                    <td class="text-center">
                      <span>
                        <span v-if="boardExamConfig.exam_properties.enable_grading">
                          {{participantsScore[student.id]['grade']}}</span>
                        <br />
                        <span v-if="!boardExamConfig.exam_properties.enable_grading">
                          {{participantsScore[student.id]['score']}}</span>
                      </span>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive" v-if="boardexam.mark_entry_type=='question_wise'">
              <h5>Question wise Score of participants in University exam</h5>
              <table class="table table-bordered table-striped">
                <thead class="thead-dark text-left">
                  <tr>
                    <th class="text-center" colspan="100">
                      Ques. No. & Maximum Score
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th v-for="(question,
      questionIndex) in questions" :key="questionIndex">
                      <div class="d-flex">
                        {{ question.question_no }}
                        ({{ question.maximum_mark }})
                        <div v-if="question.course_outcome_ids.length != 0">
                          <span v-for="(outcome,
      outcomeIndex) in question.course_outcome_ids" :key="outcomeIndex">
                            <span v-for="(co, coIndex) in courseoutcomes" :key="coIndex" class="text-center">
                              <span v-if="co.id == outcome">{{ co.code }}
                                <span v-if="outcomeIndex !=
      question.course_outcome_ids.length - 1
      ">,</span>
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student,
                  studentIndex) in course_students" :key="studentIndex">
                    <td class="align-middle">
                      {{ student.first_name || " " }}
                      {{ student.last_name || " " }}
                    </td>
                    <td v-for="(question,
                    questionIndex) in questions" :key="questionIndex" class="text-center">
<span
                        v-if="studsSelectedScoreQuestionIds[student.id].includes(question.id)">
                        {{
                            handleValue(
                             participantsScore[student.id]['question_score'][question.id]
                            )
                        }}</span>
                      <span style="font-size: 10px !important;" v-else>
                        {{
                            handleValue(
                              participantsScore[student.id]['question_score'][question.id]
                            )
                        }}</span>


                    
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="table-responsive" v-if="boardexam.mark_entry_type=='co_wise'">
              <h5>Co wise Score of participants in University exam</h5>
              <table class="table table-bordered table-striped">
                <thead class="thead-dark text-left">
                  <tr>
                    <th class="text-center" colspan="100">
                      Course Outcome
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th v-for="(co, coIndex) in courseoutcomes" :key="coIndex" class="text-center">
                      {{ co.code }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student,
                  studentIndex) in course_students" :key="studentIndex">
                    <td class="align-middle">
                      {{ student.first_name || " " }}
                      {{ student.last_name || " " }}
                    </td>
                    <td v-for="(co, coIndex) in courseoutcomes" :key="coIndex" class="text-center">
                      <span v-if="participantsScore[student.id]['co_score']">
                        {{participantsScore[student.id]['co_score'][co.id]?participantsScore[student.id]['co_score'][co.id]:0}}/{{participantsScore[student.id]['co_max_score'][co.id]
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="table-responsive" v-if="report">
            <table class="table table-striped table-bordered" v-if="boardExamsReports.equation_type == 1">
              <thead class="thead-dark">
                <tr>
                    <th class="text-center" colspan="100">
                      Ques. No. ) Course Outcome
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th v-for="(question,
      questionIndex) in questions" :key="questionIndex">
                      <div class="d-flex">
                        {{ question.question_no }})
                        <div v-if="question.course_outcome_ids.length != 0">
                          <span v-for="(outcome,
      outcomeIndex) in question.course_outcome_ids" :key="outcomeIndex">
                            <span v-for="(co, coIndex) in courseoutcomes" :key="coIndex" class="text-center">
                              <span v-if="co.id == outcome && outcomeIndex ==0">
                                {{ co.code }}
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </th>
                  </tr>
              </thead>
              <tbody>
                <tr v-for="(student, studentIndex) in course_students" :key="studentIndex">
                  <td class="align-middle">
                    {{ student.first_name || " " }}
                    {{ student.last_name || " " }}
                  </td>
                  <td v-for="(question,
      questionIndex) in questions" :key="questionIndex" class="text-center">
                    {{boardExamsReports.participantQuestionScorePersantage[student.id][question.id]}}
                  </td>
                </tr>
                <tr>
                  <td>Target Achived Students</td>
                  <td v-for="(question,
      questionIndex) in questions" :key="questionIndex">
                    {{ boardExamsReports.computed_question_datas[question.id].targetAchived }}
                  </td>
                </tr>
                <tr>
                  <td>Total students count</td>
                  <td v-for="(question,
      questionIndex) in questions" :key="questionIndex">
                    {{ boardExamsReports.computed_question_datas[question.id].max_stud}}
                  </td>
                </tr>
                <tr>
                  <td>% of students achived target</td>
                  <td v-for="(question,
      questionIndex) in questions" :key="questionIndex">
                    {{ Math.round(boardExamsReports.computed_question_datas[question.id].target_achived_persantage )}}
                  </td>
                </tr>
                <tr>
                  <td>Attainment level</td>
                  <td v-for="(question,
      questionIndex) in questions" :key="questionIndex">
                    {{ boardExamsReports.computed_question_datas[question.id].attainment_level }}
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table table-striped table-bordered" v-if="boardExamsReports.equation_type == 2">
              <h4>Course outcomes attainment value</h4>
              <thead class="thead-dark">
                <tr>
                  <th></th>
                  <th v-for="(co, coIndex) in courseoutcomes" :key="coIndex" class="text-center">
                    {{ co.code }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(student, studentIndex) in course_students" :key="studentIndex">
                  <td class="align-middle">
                    {{ student.first_name || " " }}
                    {{ student.last_name || " " }}
                  </td>
                  <td v-for="(co, coIndex) in courseoutcomes" :key="coIndex" class="text-center">
                    {{boardExamsReports.participantsCoScore[student.id][co.id]}}
                  </td>
                </tr>
                <tr>
                  <td>Target Achived Students</td>
                  <td v-for="(co, coIndex) in courseoutcomes" :key="coIndex">
                    {{ boardExamsReports.computed_co_datas[co.id].target_achived_students }}
                  </td>
                </tr>
                <tr>
                  <td>Total students count</td>
                  <td v-for="(co, coIndex) in courseoutcomes" :key="coIndex">
                    {{ boardExamsReports.computed_co_datas[co.id].max_student_count }}
                  </td>
                </tr>
                <tr>
                  <td>% of students achived target</td>
                  <td v-for="(co, coIndex) in courseoutcomes" :key="coIndex">
                    {{ Math.round(boardExamsReports.computed_co_datas[co.id].target_achived_persantage )}}
                  </td>
                </tr>
                <tr>
                  <td>Attainment level</td>
                  <td v-for="(co, coIndex) in courseoutcomes" :key="coIndex">
                    {{ boardExamsReports.computed_co_datas[co.id].attainment_level }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["courseReport"],
  async created() {
    this.fetchData = true;
    await this.getAttainmentConfigration();
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: false,
      // configTab:false,dataTab:false,reportTab:false,
      boardExamConfig: {
        // exams: {},
        scores: {},
        // participants: [],
        // targetPercentage: "",
        exam_properties: {},
      },
      config: true,
      report: false,
      // boardExamsArray: [],
      boardExamsReports: {},
      // boardExamsDatas: {},
      participantsScore: {},
      participantsCoScore: {},
      course_students: {},
      courseoutcomes: {},
      boardexams:[],
      boardexamQuestions:[],
      selectedBoardexamId:null,
      selectedQuestionIds:[],
      boardexam:[],
      question:[],
      question_co:{},
      studsSelectedScoreQuestionIds:[]
    };
  },
  methods: {
    handleValue(num) {
      if (isNaN(num)) {
        return "";
      } else {
        return num % 1 == 0 ? Math.floor(num) : parseFloat(num).toFixed(2);
      }
    },
    selectAllQuestionsOfBoardexam(value) {
      this.selectedQuestionIds =[];
        this.boardexamQuestions[value].questions.forEach((ques) => {
          this.selectedQuestionIds.push(ques.value);
        }); 
    },
    async getAttainmentConfigration() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/getconfig`;
      await this.$axios
        .post(url, { course: this.courseReport.course_id, step: "boardexam" })
        .then((response) => {
          this.boardExamConfig = {
            scores: response.data.participantsScores,
            exam_properties: response.data.exam_properties,
          };
          this.course_students = response.data.course_students;
          let questions = response.data.boardexams_with_questions;
          this.selectedQuestionIds = response.data.selected_questions;
          this.selectedBoardexamId = response.data.selected_boardexam;
          questions.forEach((boardexam) => {
            this.boardexams.push({
              value: boardexam.id,
              text: boardexam.name,
            });
            this.$set(this.boardexamQuestions, boardexam.id, {
              questions: [],
            });
            boardexam.questions.forEach((ques) => {
              this.boardexamQuestions[boardexam.id].questions.push({
                value: ques.id,
                text: ques.question_no,
              }); 
            });
          });
        });
    },
    async setAttainmentConfigration() { 
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/setconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "boardexam",
          boardexamId: this.selectedBoardexamId,
          questionIds: this.selectedQuestionIds,
          // target_persantage: this.boardExamConfig.targetPercentage,
        })
        .then(() => { });
    },

    async getAttainmentData() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/getdata`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "boardexam",
        })
        .then((response) => {
          // this.boardExamsArray = response.data;
          this.boardexam=response.data.boardexam;
          this.questions=response.data.questions;
          this.participantsScore = response.data.participantsScore;
          this.course_students = response.data.course_students;
          this.boardExamConfig.exam_properties = response.data.exam_properties;
          this.courseoutcomes = response.data.course_outcomes;
          this.studsSelectedScoreQuestionIds=response.data.studsSelectedScoreQuestionIds;
        })
        .catch((error) => {
          let errortxt = "";
          if (error.response)
            if (error.response.status == 422) {
              let errormsgs = error.response.data.errors;
              for (const key in errormsgs) {
                errortxt = errormsgs[key][0];
              }
            } else {
              errortxt = error.response.data.message;
            }
          else {
            errortxt = "Failed: server error";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 3000,
          });
        });
    },
    async getAttainmentReport() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/report`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "boardexam",
        })
        .then((response) => {
          this.boardExamsReports = response.data.boardexam;
          this.courseoutcomes = response.data.course_outcomes;
        });
    },
  },
};
</script>
