<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="attainmentCO mx-3 my-2" v-else>
      <div v-if="config">
        <h5 class="text-center mb-4" style="font-size: 15px!important;">Configuration for Calculating Course CO and PO
          Attainment</h5>

        <div class=" p-4 bg-white rounded shadow">
          <div style="margin-left: -10px; font-size: 13px !important;">Input the percentage weightage split for direct
            and indirect CO assessment tools for the purpose of calculating course CO and PO attainment:</div>
          <b-row class="">
            <b-form-group class="col">
              <label for="direct-weightage-input">Direct Weightage:</label>
              <b-form-input v-model="directWeightage" style="width: 70px" @keypress="handleNumber"
                @blur="checkWeigtage('direct')" id="direct-weightage-input"></b-form-input>
            </b-form-group>
            <b-form-group class="col">
              <label for="indirect-weightage-input">Indirect Weightage:</label>
              <b-form-input v-model="indirectWeightage" @keypress="handleNumber" @blur="checkWeigtage('indirect')"
                style="width: 70px" id="indirect-weightage-input"></b-form-input>
            </b-form-group>
          </b-row>
        </div>

        <div class="  mt-4  p-4 bg-white rounded shadow">
          <div style="margin-left: -10px; font-size: 13px !important;">Input the percentage weightage split for all CO
            direct assessment tools:</div>
          <div class="">
            <b-form-checkbox v-model="assesmenttools_weightage.equal_weigtage">Apply equal weightage for Assessment,
              Assignment, University Exam, Laboratory</b-form-checkbox>
          </div>
          <b-row v-if="!assesmenttools_weightage.equal_weigtage">
            <b-col>
              <div class="">
                Assessment Weightage
                <b-form-input v-model="assesmenttools_weightage.assessment" @keypress="handleNumber"
                  @blur="checkWeigtageofAssessmentTools" type="number"></b-form-input>
              </div>
            </b-col>
            <b-col>
              <div class="">
                Assignment Weightage
                <b-form-input v-model="assesmenttools_weightage.assignment" @keypress="handleNumber"
                  @blur="checkWeigtageofAssessmentTools" type="number"></b-form-input>
              </div>
            </b-col>
            <b-col>
              <div class="">
                University Exam Weightage
                <b-form-input v-model="assesmenttools_weightage.boardexam" @keypress="handleNumber"
                  @blur="checkWeigtageofAssessmentTools" type="number"></b-form-input>
              </div>
            </b-col>
            <b-col>
              <div class="">
                Laboratory Weightage
                <b-form-input v-model="assesmenttools_weightage.laboratory" @keypress="handleNumber"
                  @blur="checkWeigtageofAssessmentTools" type="number"></b-form-input>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="  mt-4  p-4 bg-white rounded shadow">
          <div class="" style="margin-left: -10px; font-size: 13px !important;">Choose the equation for calculating
            percentage score obtained by a student for each CO:</div>
          <b-row class="mt-2">
            <b-col>
              <b-form-group label="Calculate using  :">
                <div>
                  <b-form-radio :value="true" class="ml-3"
                    v-model="activity_student_percentage_simple_average_calculation">Simple average equation
                  </b-form-radio>
                  <b-form-radio class="ml-3" :value="false"
                    v-model="activity_student_percentage_simple_average_calculation">Weighted average equation
                  </b-form-radio>
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <div class="" v-if="activity_student_percentage_simple_average_calculation">
                <p>Equation used for
                  % of student in CO1 = SUM (Score of student in questions linked to CO1 ) / SUM (Maximum mark of
                  questions linked to CO1 )</p>
              </div>
              <div v-else>
                <p>Equation used for
                  % of student in CO1 = SUM (% score of student in question linked to CO1 ] / number of questions
                  linked to CO1</p>

                <h5>OR</h5>
                <p>
                  % of student in CO1 = [ (score of student in question1 linked to CO1/ maximum mark of question1) +
                  (score of student in question2 linked to CO1/ maximum mark of question2)+ ... ]/number of questions
                  linked to CO1</p>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="  mt-4 p-4 bg-white rounded shadow">
          <div class="mb-2" style="margin-left: -10px; font-size: 13px !important;">Set the target percentage value for
            CO for all CO direct assessment tools:</div>

          <!-- need a table vith initially same target for all tools and then same target for all co -->
          <div>
            <b-form-checkbox @change="targetPercentageInputChanges()"
              v-model="co_target_percentages.same_target_for_all_tools">Apply same target percentage for
              all direct
              assessment tools
              (Assessment, Assignment,
              University
              Exam, Laboratory)</b-form-checkbox>
          </div>
          <div v-if="!co_target_percentages.same_target_for_all_tools">
            <b-form-checkbox @change="targetPercentageInputChanges()"
              v-model="co_target_percentages.same_target_for_all_cos">Apply same target percentage for
              all COs on every tools</b-form-checkbox>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th class="p-1">COs/Tools</th>
                  <th class="p-1">Assessment</th>
                  <th class="p-1">Assignment</th>
                  <th class="p-1">University Exam</th>
                  <th class="p-1">Laboratory</th>
                </tr>
              </thead>
              <tr v-for="(co, cindex) in course_outcomes" :key="cindex">
                <td>{{ co.code }}</td>
                <td class="text-center" style="width: 80px;"
                  v-for="(tool, tindex) in ['assessment', 'assignment', 'boardexam', 'laboratory']" :key="tindex">
                  <b-form-input class="p-0" @change="targetPercentageInputChanges()"
                    v-if="targetPercentageInputIsEnabled(cindex, tindex)" v-model="co_target_percentages[tool][co.id]"
                    type="number"></b-form-input>
                  <span v-else> {{ co_target_percentages[tool][co.id] }}</span>
                </td>
              </tr>
            </table>
          </div>

        </div>

        <div class="  mt-4  p-4 bg-white rounded shadow">
          <div class=" mb-2" style="margin-left: -10px; font-size: 13px !important;">Set the threshold values to be
            applied for all direct assessment tools for calculating attainment level:</div>
          <div class="mb-4">
            <b-form-checkbox v-model="attainmentThresholds.equal_thresholds"
              @change="changeInAttainmentToolsEqualThresholds">Apply same threshold for all direct
              assessment tools
              (Assessment, Assignment,
              University
              Exam, Laboratory)</b-form-checkbox>
          </div>
          <div v-if="attainmentThresholds.equal_thresholds">
            <!-- same thresholds  -->
            <div>
              <ul>
                <li class="" v-for="(attainment, attainmentIndex) in attainmentThresholds.assessment"
                  :key="attainmentIndex">
                  <b-row class="mx-2 my-1">
                    <span class="mr-1" style="align-self: center;"> % of student achieved target </span>
                    <b-form-select class="mr-1" style="width: 60px;" :options="operators"
                      v-model="attainment.comparisan"></b-form-select>
                    <b-form-input class="mr-1" style="width: 60px;" @keypress="handleNumber"
                      v-model="attainment.compare_by"></b-form-input>
                    <span class="mr-1" style="align-self: center;"> then attainment level is </span>
                    <b-form-input class="mr-4" style="width: 60px;" v-model.number="attainment.value"
                      @keypress="handleNumber"></b-form-input>
                    <span style="align-self: center;" class="btn p-0 m-0 text-danger"
                      @click="attainmentThresholds.assessment.splice(attainmentIndex, 1)">
                      <i class="fas fa-minus-circle" style="font-size:14px !important;"></i>
                    </span>
                  </b-row>
                </li>
                <li> <b-row class="mx-2 my-1"> <b-link @click="addValue('assessment')"> Add
                      Level</b-link></b-row></li>
              </ul>

            </div>



          </div>
          <div v-else>
            <div>
              <h5 style=" font-weight: bold;">Assessment</h5>
              <ul>
                <li class="" v-for="(attainment, attainmentIndex) in attainmentThresholds.assessment"
                  :key="attainmentIndex">
                  <b-row class="mx-2 my-1">
                    <span class="mr-1" style="align-self: center;"> % of student achieved target </span>
                    <b-form-select class="mr-1" style="width: 60px;" :options="operators"
                      v-model="attainment.comparisan"></b-form-select>
                    <b-form-input class="mr-1" style="width: 60px;" @keypress="handleNumber"
                      v-model="attainment.compare_by"></b-form-input>
                    <span class="mr-1" style="align-self: center;"> then attainment level is </span>
                    <b-form-input class="mr-4" style="width: 60px;" v-model.number="attainment.value"
                      @keypress="handleNumber"></b-form-input>
                    <span style="align-self: center;" class="btn p-0 m-0 text-danger"
                      @click="attainmentThresholds.assessment.splice(attainmentIndex, 1)">
                      <i class="fas fa-minus-circle" style="font-size:14px !important;"></i>
                    </span>
                  </b-row>
                </li>
                <li> <b-row class="mx-2 my-1"> <b-link @click="addValue('assessment')"> Add
                      Level</b-link></b-row></li>
              </ul>
            </div>


            <div>
              <h5 style=" font-weight: bold;">Assignment</h5>
              <ul>
                <li class="" v-for="(attainment, attainmentIndex) in attainmentThresholds.assignment"
                  :key="attainmentIndex">
                  <b-row class="mx-2 my-1">
                    <span class="mr-1" style="align-self: center;"> % of student achieved target </span>
                    <b-form-select class="mr-1" style="width: 60px;" :options="operators"
                      v-model="attainment.comparisan"></b-form-select>
                    <b-form-input class="mr-1" style="width: 60px;" @keypress="handleNumber"
                      v-model="attainment.compare_by"></b-form-input>
                    <span class="mr-1" style="align-self: center;"> then attainment level is </span>
                    <b-form-input class="mr-4" style="width: 60px;" v-model.number="attainment.value"
                      @keypress="handleNumber"></b-form-input>
                    <span style="align-self: center;" class="btn p-0 m-0 text-danger"
                      @click="attainmentThresholds.assignment.splice(attainmentIndex, 1)">
                      <i class="fas fa-minus-circle" style="font-size:14px !important;"></i>
                    </span>
                  </b-row>
                </li>
                <li> <b-row class="mx-2 my-1"> <b-link @click="addValue('assignment')"> Add
                      Level</b-link></b-row></li>
              </ul>
            </div>


            <div>
              <h5 style="font-weight: bold;">University Exam</h5>
              <ul>
                <li class="" v-for="(attainment, attainmentIndex) in attainmentThresholds.boardexam"
                  :key="attainmentIndex">
                  <b-row class="mx-2 my-1">
                    <span class="mr-1" style="align-self: center;"> % of student achieved target </span>
                    <b-form-select class="mr-1" style="width: 60px;" :options="operators"
                      v-model="attainment.comparisan"></b-form-select>
                    <b-form-input class="mr-1" style="width: 60px;" @keypress="handleNumber"
                      v-model="attainment.compare_by"></b-form-input>
                    <span class="mr-1" style="align-self: center;"> then attainment level is </span>
                    <b-form-input class="mr-4" style="width: 60px;" v-model.number="attainment.value"
                      @keypress="handleNumber"></b-form-input>
                    <span style="align-self: center;" class="btn p-0 m-0 text-danger"
                      @click="attainmentThresholds.boardexam.splice(attainmentIndex, 1)">
                      <i class="fas fa-minus-circle" style="font-size:14px !important;"></i>
                    </span>
                  </b-row>
                </li>
                <li> <b-row class="mx-2 my-1"> <b-link @click="addValue('boardexam')"> Add
                      Level</b-link></b-row></li>
              </ul>
            </div>


            <div>
              <h5 style=" font-weight: bold;">Laboratory</h5>
              <ul>
                <li class="" v-for="(attainment, attainmentIndex) in attainmentThresholds.laboratory"
                  :key="attainmentIndex">
                  <b-row class="mx-2 my-1">
                    <span class="mr-1" style="align-self: center;"> % of student achieved target </span>
                    <b-form-select class="mr-1" style="width: 60px;" :options="operators"
                      v-model="attainment.comparisan"></b-form-select>
                    <b-form-input class="mr-1" style="width: 60px;" @keypress="handleNumber"
                      v-model="attainment.compare_by"></b-form-input>
                    <span class="mr-1" style="align-self: center;"> then attainment level is </span>
                    <b-form-input class="mr-4" style="width: 60px;" v-model.number="attainment.value"
                      @keypress="handleNumber"></b-form-input>
                    <span style="align-self: center;" class="btn p-0 m-0 text-danger"
                      @click="attainmentThresholds.laboratory.splice(attainmentIndex, 1)">
                      <i class="fas fa-minus-circle" style="font-size:14px !important;"></i>
                    </span>
                  </b-row>
                </li>
                <li> <b-row class="mx-2 my-1"> <b-link @click="addValue('laboratory')"> Add
                      Level</b-link></b-row></li>
              </ul>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["courseReport"],
  async created() {
    this.fetchData = true;
    await this.getAttainmentConfigration();
    this.fetchData = false;
  },

  data() {
    return {
      fetchData: false,
      attainmentThresholds: {
        equal_thresholds: true,
        assessment: [
          { value: 1, comparisan: '>=', compare_by: 50 },
          { value: 2, comparisan: '>', compare_by: 65 },
          { value: 3, comparisan: '>', compare_by: 80 },
        ],
        assignment: [],
        boardexam: [],
        laboratory: [],
      },
      operators: [
        { value: null, text: "Select Operator" },
        { value: "<", text: "<" },
        { value: ">", text: ">" },
        { value: "<=", text: "<=" },
        { value: ">=", text: ">=" },
      ],
      config: true,
      directWeightage: null,
      indirectWeightage: null,

      assesmenttools_weightage: {
        equal_weigtage: true,
        assessment: 50,
        assignment: 30,
        boardexam: 20,
        laboratory: 20,
      },
      activity_student_percentage_simple_average_calculation: true,

      co_target_percentages: {
        same_target_for_all_tools: true,
        same_target_for_all_cos: true,
        assessment: {},
        assignment: {},
        boardexam: {},
        laboratory: {},
      },
      course_outcomes: [],
    };
  },
  methods: {
    targetPercentageInputChanges() {
      let firstCoId = this.course_outcomes[0].id;
      if (this.co_target_percentages.same_target_for_all_tools) {
        let sameTarget = this.co_target_percentages.assessment[firstCoId];
        ['assessment', 'assignment', 'boardexam', 'laboratory'].forEach(tool => {
          this.course_outcomes.forEach(co => {
            this.co_target_percentages[tool][co.id] = sameTarget;
          });
        });
      } else if (this.co_target_percentages.same_target_for_all_cos) {
        let sameTargets = { assessment: this.co_target_percentages.assessment[firstCoId], assignment: this.co_target_percentages.assignment[firstCoId], boardexam: this.co_target_percentages.boardexam[firstCoId], laboratory: this.co_target_percentages.laboratory[firstCoId] };
        ['assessment', 'assignment', 'boardexam', 'laboratory'].forEach(tool => {
          this.course_outcomes.forEach(co => {
            this.co_target_percentages[tool][co.id] = sameTargets[tool];
          });
        });
      } else {
        return;
      }
    },
    targetPercentageInputIsEnabled(cIndex, tIndex) {
      if (this.co_target_percentages.same_target_for_all_tools)
        return (cIndex == 0 && tIndex == 0);
      if (this.co_target_percentages.same_target_for_all_cos)
        return cIndex == 0; //first 
      return true;
    },
    changeInAttainmentToolsEqualThresholds() {
      // const assssmentthrashods = [
      //     { value: 1, comparisan: '>=', compare_by: 50 },
      //     { value: 2, comparisan: '>', compare_by: 65 },
      //     { value: 3, comparisan: '>', compare_by: 80 },
      //   ];
      if (!this.attainmentThresholds.equal_thresholds) {
        this.attainmentThresholds.assignment = [
          { value: 1, comparisan: '>=', compare_by: 50 },
          { value: 2, comparisan: '>', compare_by: 65 },
          { value: 3, comparisan: '>', compare_by: 80 },
        ];
        this.attainmentThresholds.boardexam = [
          { value: 1, comparisan: '>=', compare_by: 50 },
          { value: 2, comparisan: '>', compare_by: 65 },
          { value: 3, comparisan: '>', compare_by: 80 },
        ];
        this.attainmentThresholds.laboratory = [
          { value: 1, comparisan: '>=', compare_by: 50 },
          { value: 2, comparisan: '>', compare_by: 65 },
          { value: 3, comparisan: '>', compare_by: 80 },
        ];
      } else {
        this.attainmentThresholds.assignment = [];
        this.attainmentThresholds.boardexam = [];
        this.attainmentThresholds.laboratory = [];
      }
    },
    checkWeigtage(input) {
      if (Number(this.directWeightage) + Number(this.indirectWeightage) != 100) {
        if (Number(this.directWeightage) > 100 || Number(this.indirectWeightage) > 100) {
          this.$toast.error(
            "The sum of direct indirect weightage should be equal to 100",
            {
              position: "top",
              durartion: 3000,
            }
          );
          this.directWeightage = 0;
          this.indirectWeightage = 0;
          return;
        }
        if (input == 'direct') {
          this.indirectWeightage = 100 - Number(this.directWeightage);
        } else {
          this.directWeightage = 100 - Number(this.indirectWeightage);
        }
      }
    },

    checkWeigtageofAssessmentTools() {
      if (
        Number(this.assesmenttools_weightage.assessment) +
        Number(this.assesmenttools_weightage.assignment) +
        Number(this.assesmenttools_weightage.boardexam) +
        Number(this.assesmenttools_weightage.laboratory) !=
        100
      ) {
        this.$toast.error("The sum of weightage should be equal to 100", {
          position: "top",
          durartion: 4000,
        });
      }
    },
    addValue(tool) {
      this.attainmentThresholds[tool].push({
        value: "",
        comparisan: null,
        compare_by: "",
      });
    },
    handleNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    async getAttainmentConfigration() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/getconfig`;
      await this.$axios
        .post(url, { course: this.courseReport.course_id, step: "attainment" })
        .then((response) => {
          this.attainmentThresholds = response.data.direct_tools_attainment_thresholds;
          this.directWeightage = response.data.directmethod_weigtage;
          this.indirectWeightage = response.data.indirectmethod_weigtage;
          this.assesmenttools_weightage = response.data.assesmenttools_weightage;
          this.activity_student_percentage_simple_average_calculation = response.data.activity_student_percentage_simple_average_calculation;
          this.co_target_percentages = response.data.co_target_percentages;
          for (const coId in response.data.course_outcomes) {
            const element = response.data.course_outcomes[coId];
            this.course_outcomes.push({ id: element.id, code: element.code });
          }
        });
    },
    async setAttainmentConfigration() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/setconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "attainment",
          direct_tools_attainment_thresholds: this.attainmentThresholds,
          directmethod_weigtage: this.directWeightage,
          indirectmethod_weigtage: this.indirectWeightage,
          assesmenttools_weightage: this.assesmenttools_weightage,
          activity_student_percentage_simple_average_calculation: this.activity_student_percentage_simple_average_calculation,
          co_target_percentages: this.co_target_percentages
        })
        .then(() => { });
    },
    // async getAttainmentCourseOutcomes() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/coattainment/getdata`;
    //   await this.$axios
    //     .post(url, { course: this.courseReport.course_id, step: "attainment" })
    //     .then(() => { });
    // },
  },
};
</script>
