<template>
  <div class="container">
    <b-container>

      <!-- this option is to choose copy from , but option is prop from parant -->
      <!-- <div>
        <b-form-group label="Choose Resources: ">
          <b-form-select @change="changeCopyFrom()"
            :options="[{ value: '', text: 'Select' }, { value: 'template', text: 'KTU Resources' }, { value: 'myinstitute', text: 'My Institute' }]"
            v-model="whereCopyFrom">
          </b-form-select>
        </b-form-group>
      </div> -->


      <!-- copy course property from template  -->
      <div v-if="whereCopyFrom == 'template'">
        <!-- <b-form-group>
          <label class="required-label" for="">Choose Resources:</label>
          <b-form-select @change="getTemplateUsergroups()" :options="templateInstitutes"
            v-model="templateInstitute"></b-form-select>
          <span class="text-danger" v-if="(templateInstitutes.length == 1)">Please wait...</span>
        </b-form-group> -->

        <b-form-group :label="'Choose Resources Programme(Scheme)'">
          <b-form-select @change="changeUsergroup()" :options="usergroups" v-model="usergroup">
          </b-form-select>
        </b-form-group>

        <b-form-group :label="'Choose ' + getCustomNames.userSubgroup.custom_name != null
        ? getCustomNames.userSubgroup.custom_name : 'Subgroup'" v-if="subgroups.length > 0">
          <b-form-select @change="changeSubgroup()" :options="subgroups" v-model="subgroup">
          </b-form-select>
        </b-form-group>
        <div v-else>{{ getCustomNames.userSubgroup.custom_name != null
            ? getCustomNames.userSubgroup.custom_name : 'Subgroup'
        }} Not available / change above selection</div>
        <b-form-group label="Choose Course" v-if="courses.length > 0">
          <b-form-select @change="changeCourse()" :options="courses" v-model="course">
          </b-form-select>
        </b-form-group>
        <div v-else>Course Not available / change above selection</div>

        <div v-if="showProperty" class=" m-1">
          <b-form-group label="Select components to be copied:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group id="checkbox-group-1" v-model="selectedCourse_properties"
              :options="course_properties" :aria-describedby="ariaDescribedby" name="flavour-1"></b-form-checkbox-group>
          </b-form-group>
        </div>
        <div v-if="progress">
          <b-spinner variant="primary"></b-spinner>
        </div>
      </div>

      <!-- copy from my institute  -->
      <div v-if="whereCopyFrom == 'myinstitute'">
        <b-form-group label="Choose Academic Year">
          <b-form-select @change="changeAcademicYear()" :options="$acedemicYears" v-model="academic_year">
          </b-form-select>
        </b-form-group>
        <b-form-group :label="'Choose ' + getCustomNames.userSubgroup.custom_name != null
        ? getCustomNames.userSubgroup.custom_name : 'Subgroup'" v-if="subgroups.length > 0">
          <b-form-select @change="changeSubgroup()" :options="subgroups" v-model="subgroup">
          </b-form-select>
        </b-form-group>
        <div v-else>{{ getCustomNames.userSubgroup.custom_name != null
            ? getCustomNames.userSubgroup.custom_name : 'Subgroup'
        }} Not available / change above selection</div>
        <b-form-group label="Choose Course" v-if="courses.length > 0">
          <b-form-select @change="changeCourse()" :options="courses" v-model="course">
          </b-form-select>
        </b-form-group>
        <div v-else>Course Not available / change above selection</div>

        <div v-if="showProperty" class=" m-1">

          <b-form-group label="Select components to be copied:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group id="checkbox-group-1" v-model="selectedCourse_properties"
              :options="course_properties" :aria-describedby="ariaDescribedby" name="flavour-1"></b-form-checkbox-group>
          </b-form-group>

        </div>
        <div v-if="progress">
          <b-spinner variant="primary"></b-spinner>
        </div>
      </div>
      <b-form-group>
        <div class="d-flex flex-row justify-content-between">
          <b-button variant="danger" size="md" @click="cancel()">Cancel</b-button>
          <div>
            <b-button variant="primary" size="md" @click="postCopyProperties()" :disabled="proceedButton.disabled">
              {{ proceedButton.text }}</b-button>
          </div>
        </div>
      </b-form-group>

    </b-container>

  </div>
</template>

<script>
import axios from "axios";
// import createModule from "./ModuleCreation";
export default {
  props: [
    "course_id", "copy_from_suggestion"
  ],
  async created() {
    if (this.copy_from_suggestion != '') {
      this.whereCopyFrom = this.copy_from_suggestion;
      this.changeCopyFrom();
    }

  },
  data() {
    return {
      progress: false,
      proceedButton: {
        disabled: true,
        text: "Proceed"
      },
      academic_year: null,
      templateGroupSubgroupCourses: {},
      usergroups: [],
      usergroup: null,
      subgroups: [],
      subgroup: null,
      courses: [],
      course: null,
      whereCopyFrom: null,// 'template' / 'myinstitute'
      showProperty: false,
      // course_properties: {
      //   boardexam_config: true, modules: true, lessonplan: true,
      //   co_po_pso_mapping: true, text_books: true, reference_docs: true, pre_requisites: true,
      //   industrial_relevence: true, content_beyond_syllabus: true, details_of_lerning_matials: true, ltp_credits: true
      // },
      course_properties: [
        'boardexam_config', 'modules', 'lessonplans', 'lab_experiments',
        'co_po_pso_mapping', 'text_books', 'reference_docs', 'pre_requisites',
        'industrial_relevence', 'content_beyond_syllabus', 'details_of_lerning_matials',
        'ltp_credits', 'course_outcomes', 'aims', 'objectives'
      ],
      selectedCourse_properties: [
        'boardexam_config',
        'modules',
        'lessonplans',
        'co_po_pso_mapping',
        'text_books',
        'reference_docs',
        'pre_requisites',
        'industrial_relevence',
        'content_beyond_syllabus',
        'details_of_lerning_matials',
        'ltp_credits',
        'course_outcomes',
        'lab_experiments',
        'aims',
        'objectives'
      ],
      // templateInstitutes: [],
      // templateInstitute: '',
    };
  },
  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
  },
  methods: {
    // courseComponentSelectionChanged(selectedValues) {
    //   const lastSelectedValue = selectedValues[selectedValues.length - 1]
    //   if (lastSelectedValue == 'lessonplans') {
    //     if (!this.selectedCourse_properties.includes('modules')) this.selectedCourse_properties.push('modules');
    //     if (!this.selectedCourse_properties.includes('course_outcomes')) this.selectedCourse_properties.push('course_outcomes');
    //   }
    // },

    async changeCopyFrom() {
      this.usergroups = [];
      this.subgroups = [];
      this.courses = [];
      if (this.whereCopyFrom == 'myinstitute') return true;
      this.progress = true;
      this.course_properties.splice(this.course_properties.indexOf('boardexam_config'), 1);
      this.course_properties.splice(this.course_properties.indexOf('co_po_pso_mapping'), 1);

      await this.getTemplateUsergroups();
      // this.templateInstitutes = [{ value: '', text: 'Select' },];
      //   const url =
      //     this.$store.getters.getAPIKey.mainAPI +
      //     `/institutions/templates`;
      //   await this.$axios
      //     .get(url)
      //     .then((response) => {
      //       response.data.forEach((institute) => {
      //         this.templateInstitutes.push({
      //           value: institute.id,
      //           text: `${institute.name}`,
      //         });
      //       });
      //     })
      //     .catch((error) => {
      //       error;
      //     });

      this.progress = false;
    },
    async getTemplateUsergroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/templates?course_to=${this.course_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.templateGroupSubgroupCourses = response.data;
          response.data.forEach((group) => {
            this.usergroups.push({
              value: group.id,
              text: `${group.name} (${group.code}` + (group.scheme ? `-${group.scheme} Scheme)` : ')'),
            });
          });
        })
        .catch((error) => {
          error;
        });
    },

    async changeUsergroup() {
      this.progress = true;
      this.subgroups = [];
      this.courses = [];
      this.templateGroupSubgroupCourses.forEach((group) => {
        if (group.id == this.usergroup) {
          group.usersubgroups.forEach((subgroup) => {
            this.subgroups.push({
              value: subgroup.id,
              text: `${subgroup.name} (${subgroup.code})`,
            });
          });
        }
      });
      this.progress = false;
    },


    cancel() {
      this.$emit("cancel");
    },
    async changeAcademicYear() {
      this.progress = true;
      this.subgroups = [];
      this.courses = [];
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/course/copyproperties`;
      await axios
        .post(url, {
          academic_year: this.academic_year
        })
        .then((response) => {
          this.subgroups = response.data.subgroups;
        });

      this.progress = false;
    },

    async changeSubgroup() {
      this.progress = true;
      this.courses = [];
      if (this.whereCopyFrom == 'myinstitute') {
        const url =
          this.$store.getters.getAPIKey.mainAPI + `/course/copyproperties`;
        await axios
          .post(url, {
            subgroup: this.subgroup
          })
          .then((response) => {
            this.courses = response.data.courses;
          });
      } else {
        this.templateGroupSubgroupCourses.forEach((group) => {
          if (group.id == this.usergroup) {
            group.usersubgroups.forEach((subgroup) => {
              if (subgroup.id == this.subgroup) {
                subgroup.courses.forEach((course) => {
                  this.courses.push({
                    value: course.id,
                    text: `${course.name} (${course.code})`,
                  });

                });
              }
            });
          }
        });
      }
      this.progress = false;
    },

    async changeCourse() {
      this.showProperty = true;
      this.proceedButton.disabled = false;
    },


    async postCopyProperties() {

      if (this.selectedCourse_properties.includes('lessonplans') && (!this.selectedCourse_properties.includes('modules') || !this.selectedCourse_properties.includes('course_outcomes'))) {
        if (!confirm('Warning: Attempting to copy lesson plan without copying outcomes or modules. Manual linking may be required if outcomes or modules does not exist already. Proceed?')) {
          return;
        }
      }

      this.progress = true;
      this.proceedButton.text = 'Copying...';
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/course/copyproperties`;
      await axios
        .post(url, {
          copy_from: this.whereCopyFrom,
          from_course: this.course,
          to_course: this.course_id,
          course_properties: this.selectedCourse_properties,
        })
        .then(() => {
          this.$toast.success("Successfully copied", {
            position: "top",
            duration: 3000,
          });
          this.$emit("success");
        }).catch((error) => {
          try {
            if (error.response.status == 403)
              this.$toast.error(error.response.data.message, {
                position: "top",
                duration: 6000,
              });
            if (error.response.status == 409)
              this.$toast.error(error.response.data.message, {
                position: "top",
                duration: 6000,
              });
          } catch (error) {
            error;
          }
        });
      this.proceedButton.text = 'Proceed';
      this.progress = false;
    },

  },
};
</script>

<style >

</style>
