<template>
<div>
    <div class="loading" v-if="fetchData">
        <load-data></load-data>
    </div>
    <div class="examForm p-2" v-else>
        <b-overlay :show="loadExams" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">

            <!--*************** Public Exam Creation********************** --->
            <div v-if="exam.course_id == 0">
                <PublicExamCreated @examFormCancelled="cancel()" :exam="exam" @getExams="getExams()" 
                :publicexamToBeUpdated = "publicexamToBeUpdated">
                </PublicExamCreated>
            </div>
            <!--*************** Public Exam Creation********************** --->

            <!-- {{ exam }} -->
            <b-form @submit.prevent="prepareExamPostDataForApi()" v-if="exam.course_id!=0">

                <b-form-group class="">
                    <label class="required-label" for="">Select Course:</label>
                    <b-form-select :options="coursesOptions" v-model="exam.course_id" :class="{ 'form-group-error': $v.exam.course_id.$error }" :disabled="courseDisabled">
                    </b-form-select>
                    <span class="input-error-message" v-if="exam.course_id == 0">
                        This will be an online exam</span>
                    <span class="input-error-message" v-if="!$v.exam.course_id.required && $v.exam.course_id.$dirty">Please Select a course!</span>
                </b-form-group>

                <b-form-group class="">
                    <label class="required-label" for="">Activity Name :</label>
                    <b-form-input placeholder="Enter Activity Name..." :class="[{ 'form-group-error': $v.exam.name.$error }, 'required']" v-model="exam.name"></b-form-input>
                    <span class="input-error-message" v-if="!$v.exam.name.required && $v.exam.name.$dirty">Activity Name is
                        required!</span>
                </b-form-group>

                <b-form-group label="Description:">
                    <b-form-textarea placeholder="Enter topic..." rows="3" max-rows="6" v-model="exam.summary">
                    </b-form-textarea>
                </b-form-group>

                <b-form-group v-if="!examToBeUpdated.id">
                    <label class="required-label" for="">Select Activity Type:</label>
                    <b-form-select v-model="exam.activity_type" :options="activityOptions"></b-form-select>
                    <span class="input-error-message" v-if="
              !$v.exam.activity_type.required && $v.exam.activity_type.$dirty
            ">Select activity type!</span>
                </b-form-group>

                <b-form-group label="Activity Type" v-if="examToBeUpdated.id && examToBeUpdated.id != ''">
                    <p class="text-info" style="font-size: 14px; font-weight: 600" v-if="exam.activity_type == 'tutorial'">
                        Tutorial
                    </p>
                    <p class="text-info" style="font-size: 14px; font-weight: 600" v-else-if="exam.activity_type == 'assessment'">
                        Assessment
                    </p>
                    <p class="text-info" style="font-size: 14px; font-weight: 600" v-else>
                        Assignment
                    </p>
                </b-form-group>

                <b-form-group label="Select Activity Group" v-if="exam.activity_type != 'tutorial'">
                    <b-form-select v-model="exam.category_id" :options="examCategoriesOptions"></b-form-select>
                    <span class="text-info">This selection will helps to automatically filter activities in various
                        reports</span>
                </b-form-group>

                <b-form-group label="Course Linked Assessment" v-if="exam.activity_type == 'assessment'">
                    <b-form-checkbox switch size="md" v-model="courseLinkedAssessment"></b-form-checkbox>
                </b-form-group>

                <b-form-group label="Select Participants" v-if="!courseLinkedAssessment">
                    <UserubgroupUsers />
                </b-form-group>
                <b-form-group label="Selected Course" v-if="courseLinkedAssessment && examToBeUpdated.id">
                    <p style="font-size: 14px; font-weight: 600" class="text-info">
                        {{ selectedCourse }}
                    </p>
                </b-form-group>

                <b-form-group label="Online/Offline Activity" v-if="exam.activity_type != 'tutorial'">
                    <b-form-checkbox switch size="md" v-model="exam.offline_activity"></b-form-checkbox>
                    <span class="text-info">Now the activity is {{ exam.offline_activity ?
                "Offline, so student cannot upload answer sheet online" : 'Online'
            }}</span>
                </b-form-group>

                <b-form-group label="Set Maximum time allowed for a participant to complete the activity:" v-if="exam.activity_type == 'assessment' && !exam.offline_activity">
                    <b-form-checkbox switch size="md" v-model="limittedtimeExam"></b-form-checkbox>
                </b-form-group>

                <b-form-group label="MCQ exam" v-if="exam.activity_type == 'assessment' && !exam.offline_activity">
                    <b-form-checkbox switch size="md" v-model="exam.is_objective_only"></b-form-checkbox>
                    <span class="text-info" v-if="exam.is_objective_only">Now the activity is MCQ</span>
                </b-form-group>

                <b-form-group>

                    <label :class="{ 'required-label': !exam.offline_activity }" for=""> {{ exam.activity_type == null ||
                exam.activity_type == 'assessment'
                ? exam.offline_activity ? 'Select Date' : 'Select Date and Time'
                : 'Select Last Date of Submission'
            }}</label>

                    <b-form-datepicker placeholder="Select Activity Date" v-model="date" :class="{ 'form-group-error': $v.date.$error }"></b-form-datepicker>

                    <span class="input-error-message" v-if="!$v.date.required && $v.date.$dirty">This Field is
                        required!</span>
                    <br />
                    <div class="time d-flex mt-2" v-if="exam.activity_type == 'assessment' && !exam.offline_activity">
                        <div>
                            <b-form-timepicker :class="{ 'form-group-error': $v.examStart.$error }" v-model="examStart" locale="en" placeholder="Activity Start Time"></b-form-timepicker>
                            <span class="input-error-message" v-if="!$v.examStart.required && $v.examStart.$dirty">Activity Start
                                Time is required!</span>
                        </div>
                        <div class="ml-3">
                            <b-form-timepicker :class="{ 'form-group-error': $v.examEnd.$error }" v-model="examEnd" locale="en" placeholder="Activity End Time"></b-form-timepicker>
                            <span class="input-error-message" v-if="!$v.examEnd.required && $v.examEnd.$dirty">Activity End Time is
                                required!</span>
                        </div>
                        <br>
                    </div>
                    <span v-if="!exam.offline_activity" class="text-info">Participant can attempt the activity anytime in
                        between the start time and end
                        time</span>
                </b-form-group>

                <div v-if="(exam.is_objective_only && !exam.offline_activity)">
                    <b-form-group>
                        <b-form-checkbox switch v-model="exam.shuffle_questions">Shuffle Questions</b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                        <b-form-checkbox v-model="exam.shuffle_choices" switch>Shuffle Answers</b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                        <b-form-checkbox switch v-model="exam.auto_evaluation">Auto Evaluation</b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                        <b-form-checkbox switch @change="(exam.auto_evaluation = exam.publish_result ? true : exam.auto_evaluation)" v-model="exam.publish_result">Publish result at the
                            end of examination
                        </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                        <b-form-checkbox switch v-model="differentScores">Every question has different scores</b-form-checkbox>
                    </b-form-group>

                    <b-row class="mx-0" v-if="!differentScores">
                        <b-form-group class="col-6" label="Score for correct answer">
                            <b-form-input placeholder="Score for correct answer" v-model="exam.positive_mark" @onkeypress="numberValidation"></b-form-input>
                        </b-form-group>
                        <b-form-group class="col-6" label="Score for incorrect answer">
                            <b-form-input placeholder="Score for incorrect answer" v-model="exam.negative_mark" @onkeypress="numberValidation"></b-form-input>
                        </b-form-group>
                    </b-row>
                </div>

                <b-form-group label="Select Max Time" v-if="
            exam.activity_type == 'assessment' &&
            date != '' &&
            examStart != '' &&
            examEnd != '' &&
            limittedtimeExam
          ">
                    <div class="d-flex">
                        <b-form-select :options="hoursOptions" v-model="selectedHour" @change="getTotalTime"></b-form-select>
                        <b-form-select :options="minutesOptions" v-model="selectedMinute" @change="getTotalTime"></b-form-select>
                    </div>
                </b-form-group>

                <!-- assingment and tutorial  -->
                <b-form-group v-if="
            exam.activity_type != 'assessment'
          " label="Date of Return">
                    <b-form-datepicker placeholder="Select Return Date" v-model="return_at_date"></b-form-datepicker>

                    <!-- <span class="input-error-message" v-if="!$v.return_at_date.required && $v.return_at_date.$dirty">This Field
              is
              required!</span> -->
                </b-form-group>
                <b-form-group label="Allow submission of assignment after last date:" v-if="
            exam.activity_type == 'assignment' && !exam.offline_activity
          ">
                    <b-form-checkbox switch size="md" v-model="exam.respond_after_close"></b-form-checkbox>
                </b-form-group>
                <!-- ------------------------- -->

                <!-- common options  -->
                <b-form-group label="Make Activity Hidden for Students:">
                    <b-form-checkbox switch size="md" v-model="exam.hidden"></b-form-checkbox>
                </b-form-group>

                <b-form-group label="Exclude from report:">
                    <b-form-checkbox switch size="md" v-model="exam.exclude_from_report"></b-form-checkbox>
                </b-form-group>
                <!-- ------------------------ -->

                <b-form-group>
                    <div class="w-100 d-flex justify-content-between">
                        <b-button variant="primary" size="md" type="submit" :disabled="examBtn.disabled">{{ examBtn.text }}
                        </b-button>
                        <b-button variant="danger" size="md" @click="cancel()" :disabled="examBtn.disabled">Cancel</b-button>
                    </div>
                </b-form-group>
            </b-form>
        </b-overlay>
    </div>
</div>
</template>

<script>
import {
    required,
    requiredIf
} from "vuelidate/lib/validators";
import moment from "moment";
import UserubgroupUsers from "./UserubgroupUsers.vue";
import PublicExamCreated from "../public_exam/PublicExamCreation.vue";
export default {
    props: ["examToBeUpdated","publicexamToBeUpdated"],
    components: {
        UserubgroupUsers,
        PublicExamCreated,
    },
    async created() {
        this.fetchData = true;
        
        
        this.$set(this.exam, "course_id", null);
        this.$set(this.exam, "co_score_evaluation", false);
        this.$set(this.exam, "offline_activity", true);
        this.$set(this.exam, "activity_type", null);
        this.$set(this.exam, "summary", null);
        this.$set(this.exam, "respond_after_close", false);
        this.$set(this.exam, "exclude_from_report", false);
        this.$set(this.exam, "is_objective_only", false);
        this.$set(this.exam, "shuffle_questions", false);
        this.$set(this.exam, "shuffle_choices", false);
        this.$set(this.exam, "auto_evaluation", false);
        this.$set(this.exam, "publish_result", false);
        this.hoursOptions = [{
            value: null,
            text: "Select Hour",
            disabled: true
        }];
        for (let hour = 0; hour <= 12; hour++) {
            this.hoursOptions.push({
                value: hour < 10 ? "0" + hour : hour,
                text: hour < 10 ? "0" + hour : hour,
            });
        }
        this.minutesOptions = [{
            value: null,
            text: "Select Minute",
            disabled: true
        }, ];
        for (let min = 0; min <= 60; min++) {
            this.minutesOptions.push({
                value: min < 10 ? "0" + min : min,
                text: min < 10 ? "0" + min : min,
            });
        }

        await this.getExamCategories();
        // await this.getMaximumAttemptsAndChoices();
        await this.getCourses();

        if (this.examToBeUpdated.id != "") {
            // const examData = await this.examToBeUpdated.exam;
            // this.exam = await this.examToBeUpdated.exam;
            if (!this.examToBeUpdated.exam.positive_mark) {
                this.differentScores = true;
            }
            for (var key in this.examToBeUpdated.exam) {
                this.$set(this.exam, key, this.examToBeUpdated.exam[key]);
            }
            let seconds = this.examToBeUpdated.exam.limitted_time_seconds;
            if (seconds && seconds > 0) {
                this.limittedtimeExam = true;
                var hours = Math.floor(seconds / 3600);
                seconds = seconds - hours * 3600;
                var minute = Math.floor(seconds / 60);
                this.selectedHour = hours < 10 ? `0${hours}` : `${hours}`;
                this.selectedMinute = minute < 10 ? `0${minute}` : `${minute}`
            }
            this.exam.offline_activity = this.examToBeUpdated.exam.offline_activity == 1 ? true : false;

            this.courseDisabled = true;
            let courseId = await this.coursesOptions.findIndex((course) => {
                return course.value == this.exam.course_id;
            });
            this.selectedCourse = this.coursesOptions[courseId].text;
            this.courseLinkedAssessment = this.exam.course_id ? true : false;
            await this.exam.starts_at;
            let theDate = await moment(
                moment.utc(this.exam.starts_at, "YYYY-MM-DD HH:mm:ss").toDate()
            );
            moment.utc(this.exam.starts_at).local();

            this.date = new Date(theDate.format("YYYY/MM/DD"));

            this.examStart = moment(
                moment.utc(this.exam.starts_at, "YYYY-MM-DD HH:mm:ss").toDate()
            ).format("HH:mm");
            this.examEnd = moment(
                moment.utc(this.exam.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
            ).format("HH:mm");

            if (this.exam.activity_type != 'assessment') {
                let returnDate = await moment(this.exam.return_at, "YYYY-MM-DD HH:mm:ss");
                this.return_at_date = new Date(returnDate.format("YYYY/MM/DD"));
                let endDate = await moment.utc(this.exam.end_at, "YYYY-MM-DD HH:mm:ss").local();
                this.date = new Date(endDate.format("YYYY/MM/DD"));
            }
        }
       
        this.$forceUpdate();
    },
    data() {
        return {
            fetchData: false,
            loadExams: false,
            courseDisabled: false,
            differentScores: false,
            exam: {},
            date: "",
            theDate: "",
            examStart: "",
            examEnd: "",
            return_at_date: "",
            limittedtimeExam: false,
            maxTimeAllowedToCompleteExam: {
                status: false,
                hours: "",
                minutes: "",
            },
            courseLinkedAssessment: true,
            examBtn: {
                text: "Submit",
                disabled: false,
            },
            examCategoriesOptions: [],
            // maximumAttemptsAllowed: [],
            // maxChoicesAllowed: [],
            coursesOptions: [],
            activityOptions: [{
                    value: null,
                    text: "Select Activity Type"
                },
                {
                    value: "assessment",
                    text: "Assessment"
                },
                {
                    value: "assignment",
                    text: "Assignment"
                },
                {
                    value: "tutorial",
                    text: "Tutorial"
                },
            ],
            selectedCourse: null,
            hoursOptions: [],
            minutesOptions: [],
            selectedHour: null,
            selectedMinute: null,
        };
    },
    validations: {
        date: {
            required: requiredIf(function () {
                if (this.exam.activity_type != "tutorial" && !this.exam.offline_activity) {
                    return true;
                }
            }),
        },
        examStart: {
            required: requiredIf(function () {
                if (this.exam.activity_type == "assessment" && !this.exam.offline_activity) {
                    return true;
                }
            }),
        },
        examEnd: {
            required: requiredIf(function () {
                if (this.exam.activity_type == "assessment" && !this.exam.offline_activity) {
                    return true;
                }
            }),
        },
        // return_at_date: {
        //   required: requiredIf(function () {
        //     if (this.exam.activity_type == "tutorial") {
        //       return true;
        //     }
        //   }),
        // },
        exam: {
            name: {
                required,
            },
            activity_type: {
                required,
            },
            course_id: {
                required,
            },
        },
    },
    methods: {
        // changePublishResult() {
        //   if (this.exam.publish_result) this.exam.auto_evaluation = true;
        // },
        getTotalTime() {
            const date = this.date;
            const startT = moment(date + " " + this.examStart);
            const endT = moment(date + " " + this.examEnd);
            const tTime = moment.duration(endT.diff(startT)).asSeconds();

            // console.log(tTime);
            if (
                this.selectedHour != null &&
                this.selectedMinute != null &&
                this.limittedtimeExam
            ) {
                // console.log(
                //   moment
                //     .duration(
                //       this.selectedHour + ":" + this.selectedMinute + ":00",
                //       "hh:mm:ss"
                //     )
                //     .asSeconds()
                // );
                if (
                    tTime <=
                    moment
                    .duration(
                        this.selectedHour + ":" + this.selectedMinute + ":00",
                        "hh:mm:ss"
                    )
                    .asSeconds()
                ) {
                    this.$toast.error("Please choose a time duration less than total exam duration", {
                        duration: 3000,
                        position: "top",
                    });
                    this.selectedHour = null;
                    this.selectedMinute = null;
                }
            }
            // console.log(tTime);
        },
        //********* Prepare Exam Post Data For API **********/
        numberValidation(e) {
            if (isNaN(e)) {
                e.preventDefault();
            }
        },
        async prepareExamPostDataForApi() {
            await this.$v.$touch();
            if (this.$v.$invalid) {
                const tabHeight = document.getElementById(
                    "staffListTab__BV_tab_container_"
                );
                this.$smoothScroll({
                    container: tabHeight,
                    scrollTo: document.querySelector(".form-group-error"),
                    duration: 1000,
                    offset: -120,
                });
                this.submitStatus = "ERROR";
                return;
            } // validation

            this.examBtn.text = "Please wait...";
            this.examBtn.disabled = true;
            this.loadExams = true;
            let startT;
            if (this.exam.activity_type == "assessment") {
                if (this.exam.offline_activity) {
                    startT =
                        moment(this.date).format("YYYY/MM/DD");
                    this.exam.starts_at = moment(startT, "YYYY-MM-DD").isValid() ?
                        moment(startT, "YYYY-MM-DD").format("YYYY-MM-DD") : null;
                } else {
                    startT =
                        moment(this.date).format("YYYY/MM/DD") + " " + this.examStart;
                    this.exam.starts_at =
                        startT != null ?
                        await moment(startT, "YYYY-MM-DD HH:mm:ss")
                        .utc()
                        .format() :
                        startT;
                }
            }
            let endT;
            // console.log
            if (this.exam.activity_type == "assessment" && this.examEnd && moment(this.date).isValid()) {
                endT = moment(this.date).format("YYYY/MM/DD") + " " + this.examEnd;
                this.exam.end_at = await moment(endT, "YYYY-MM-DD HH:mm:ss")
                    .utc()
                    .format();
            } else if (this.exam.activity_type != "assessment" && moment(this.date).isValid()) {
                endT = moment(this.date).format("YYYY/MM/DD") + " " + this.examEnd;
                this.exam.end_at = await moment(endT, "YYYY-MM-DD HH:mm:ss")
                    .utc()
                    .format();
            } else {
                true;
            }
            if (
                this.exam.activity_type == "assessment" &&
                this.exam.limitted_time_seconds
            ) {
                this.exam.limitted_time_seconds = moment
                    .duration(
                        moment(
                            this.date +
                            " " +
                            this.selectedHour +
                            ":" +
                            this.selectedMinute +
                            ":00"
                        ).format("hh:mm:ss")
                    )
                    .asSeconds();
            }
            if (this.exam.activity_type != 'assessment' && moment(this.return_at_date).isValid()) {
                let returnT = moment(this.return_at_date).format("YYYY/MM/DD");
                this.exam.return_at = await moment(returnT, "YYYY-MM-DD HH:mm:ss").format();
            }

            if (this.exam.activity_type == 'assessment' && this.limittedtimeExam) {
                // console.log();
                this.exam.limitted_time_seconds = 0;
                this.exam.limitted_time_seconds = Number(this.selectedHour) * 60 * 60;
                this.exam.limitted_time_seconds += Number(this.selectedMinute) * 60;
            }

            if (this.differentScores) {
                this.exam.negative_mark = null;
                this.exam.positive_mark = null;
            }

            // console.log(this.exam);
            // this.examBtn.text = "Submit";
            // this.loadExams = false;
            // this.examBtn.disabled = false;
            // return;
            let examStatus;
            if (this.examToBeUpdated.id == "") {
                examStatus = await this.createExam();
            } else {
                examStatus = await this.updateExam();
            }

            if (examStatus) {
                const msg =
                    (await this.examToBeUpdated.id) == "" ?
                    "Activity Successfully created" :
                    "Activity Successfully updated";
                this.$toast.success(msg, {
                    position: "top",
                    duration: 3000,
                });
                this.clearResponse(true);
            }
            if (!examStatus) {
                this.$toast.error("Something went wrong!", {
                    position: "top",
                    duration: 3000,
                });
                this.clearResponse(false);
            }

        },
        //********* Create Exam with API **********/
        async createExam() {
            let url = this.$store.getters.getAPIKey.examination;
            let status = null;
            await this.$axios
                .post(url, this.exam)
                .then((response) => {
                    status = true;
                    response;
                })
                .catch((error) => {
                    let errortxt = "Failed to Create";
                    if (error.response && error.response.status == 422) {
                        let errormsgs = error.response.data.errors;
                        for (const key in errormsgs) {
                            errortxt = errormsgs[key][0];
                        }
                    } else {
                        errortxt = error.response.data.message;
                    }
                    this.$toast.error(errortxt, {
                        position: "top",
                        duration: 3000,
                    });

                    status = false;
                });
            return status;
        },
        //********* Create Exam with API **********/
        async updateExam() {
            let url =
                this.$store.getters.getAPIKey.examination +
                `/${this.examToBeUpdated.id}`;
            let status = null;
            await this.$axios
                .put(url, this.exam)
                .then((response) => {
                    status = true;
                    response;
                })
                .catch((error) => {
                    let errortxt = "Failed to Create";
                    if (error.response && error.response.status == 422) {
                        let errormsgs = error.response.data.errors;
                        for (const key in errormsgs) {
                            errortxt = errormsgs[key][0];
                        }
                    } else {
                        errortxt = error.response.data.message;
                    }
                    this.$toast.error(errortxt, {
                        position: "top",
                        duration: 3000,
                    });

                    status = false;
                });
            return status;
        },
        //********* Reset Or Clear Response **********/
        cancel() {
            this.$emit("examFormCancelled");
            const examData = {
                name: "",

                course_id: "",
                category_id: null,
                hidden: false,
                starts_at: "",
                end_at: "",
                limitted_time_seconds: "",
                maximum_mark: null,
                negative_mark: null,
                is_objective_only: false,
                shuffle_questions: false,
                shuffle_choices: false,
                exclude_from_report: false,
                ordered_choice_list: false,
                auto_evaluation: true,
                publish_result: false,
            };
            this.exam = examData;
        },
        clearResponse(status) {
            this.examBtn.text = "Submit";
            this.loadExams = false;
            setTimeout(() => {
                if (status) {
                    this.$emit("getExams");
                    this.cancel();
                }
                this.examBtn.disabled = false;
            }, 3000);
        },
        //******** Form Required DATA ****************/
        async getExamCategories() {
            const url = this.$store.getters.getAPIKey.examCategory;
            await this.$axios
                .get(url)
                .then((response) => {
                    let examCategories = response.data;
                    this.examCategoriesOptions = [{
                        value: null,
                        text: examCategories.length == 0 ?
                            "No categories found!" :
                            "Please Select One",
                        disabled: true,
                    }, ];
                    examCategories.forEach((category) => {
                        this.examCategoriesOptions.push({
                            value: `${category.id}`,
                            text: category.name,
                        });
                    });
                    response;
                })
                .catch((error) => {
                    error;
                });
        },
        // getMaximumAttemptsAndChoices() {
        //   this.maximumAttemptsAllowed = [
        //     { value: null, text: "Select Maximum Attempts allowed" },
        //   ];
        //   this.maxChoicesAllowed = [
        //     { value: null, text: "Select Maximum Choices allowed" },
        //   ];

        //   let maxAttempts = 5;
        //   let maxChoices = 5;
        //   for (let i = 0; i <= maxAttempts; i++) {
        //     this.maximumAttemptsAllowed.push({
        //       value: i,
        //       text: i,
        //     });
        //   }
        //   for (let i = 2; i <= maxChoices; i++) {
        //     this.maxChoicesAllowed.push({
        //       value: i,
        //       text: i,
        //     });
        //   }
        // },
        async getCourses() {
            this.loadCourses = true;
            const url =
                this.$store.getters.getAPIKey.mainAPI +
                `/institutionuser/courses/withusers`;
            await this.$axios
                .get(url)
                .then((response) => {
                    this.coursesOptions = [];
                    let courses = response.data;
                    this.coursesOptions = [{
                        value: null,
                        text: courses.length != 0 ? "Select Course" : "No Courses Found!",
                        disabled: true,
                    }, ];
                    courses.forEach((course) => {
                        this.coursesOptions.push({
                            value: `${course.id}`,
                            text: course.name +
                                " (" +
                                course.usersubgroup.usergroup.name +
                                " - " +
                                course.usersubgroup.name +
                                ")",
                        });
                    });
                    this.coursesOptions.push({
                        value: "0",
                        text: "Not linked to course"
                    });
                    response;
                })
                .catch((error) => {
                    error;
                });
            this.fetchData = await false;
        },
    },
};
</script>
