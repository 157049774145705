<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>

    <div class="attainmentCO" v-else>
      <div class="viewOrConfig mb-3">
        <b-button size="sm" :variant="config ? 'success' : 'outline-primary'" class="font-weight-bolder"
          disabled>Configured</b-button>
        <p>There are two methods for calculating CO attainment of a Course. Choose one of the methods listed below:</p>

      </div>
      <b-row class="">
        <b-form-group class="col">
          <i style="font-size: 18px !important; color: #1ea2ed;" @click="$bvModal.show('showMethod1');"
            class="fas fa-info-circle  fa-xm"
            animation="fade"></i>
          <b-modal class="no-print" id="showMethod1" size="md" hide-footer centered>
            <h5><b><u>Steps:</u></b></h5>
            <p>
              1. The CO mapped question-wise marks of each student for an assessment are noted.
            </p>
            <p>
              2. The marks obtained for each question are converted to a percentage score for that question.
            </p>
            <p>
              3. This percentage marks for each question are then compared with the target percentage of the CO to which
              the question is mapped.
            </p>
            <p>
              4. The total number of students who have achieved the CO target for each question is obtained.
            </p>
            <p>
              5. This total number of students who achieved the target is converted to a percentage value.
            </p>
            <p>
              6. This percentage is compared to a threshold value, and the attainment level for each question is
              determined.
            </p>
            <p>
              7. The average value of the attainment level of all questions mapped to the same CO is calculated.
            </p>
            <p>
              8. This average value becomes the attainment value for each CO for that assessment.
            </p>


            <h5><b><u>Advantages:</u></b></h5>
            <p>
              1. Granular Analysis: By assessing attainment at the question level, educators gain detailed insights into
              student performance, allowing for targeted interventions.
            </p>
            <p>
              2. Sensitivity to Question Difficulty: This method considers the difficulty level of individual questions,
              providing a more nuanced understanding of CO attainment.
            </p>
            <p>
              3. Flexibility: Educators can adjust the target percentage for each CO based on the difficulty level of
              questions, allowing for more accurate assessment.
            </p>



            <h5><b><u>Disadvantages:</u></b></h5>
            <p>
              1. Complexity: The method involves analyzing performance at the question level, which may be more complex
              compared to Method 2.
            </p>
            <p>
              2. Subjectivity in Setting Target Percentage: Setting target percentages for individual questions requires
              careful consideration and may introduce subjectivity into the assessment process.
            </p>
            <b-row class="p-2">
              <b-col style="text-align:left;">
                <b-button variant="danger" class="text-white sm" size="sm"
                  @click="$bvModal.hide('showMethod1')">Close</b-button>
              </b-col>

            </b-row>

          </b-modal>
          <label for="direct-weightage-input"><b>Method 1: </b>Question-Based Targeted Attainment Method (QBTAM)</label>
          <br><span>Explanation: This method calculates the attainment level for each CO based on the percentage of
            students who achieved the target percentage for individual questions mapped to that CO.</span>
        </b-form-group>
        <b-form-group class="col">
          <i style="font-size: 18px !important; color: #1ea2ed;" @click="$bvModal.show('showMethod2');"
            class="fas fa-info-circle  fa-xm"></i>
          <b-modal class="no-print" id="showMethod2" size="md" hide-footer centered>
            <h5><b><u>Steps:</u></b></h5>
            <p>
              1. The CO mapped question-wise marks of each student for an assessment are noted.
            </p>
            <p>
              2. The average marks for each CO are calculated from these marks.
            </p>
            <p>
              3. The percentage marks for each CO are calculated based on the average marks.
            </p>
            <p>
              4. The percentage marks for each CO are then compared with the target percentage for each CO.
            </p>
            <p>
              5. The total number of students who have achieved the target for each CO is obtained.
            </p>
            <p>
              6. From this, the percentage of students who achieved the target for each CO is calculated.
            </p>
            <p>
              7. This percentage is compared to a threshold value, and the attainment level for each CO is determined.
            </p>
            <h5><b><u>Advantages:</u></b></h5>
            <p>
              1. Simplicity: The method is straightforward and easy to understand, making it accessible to educators.
            </p>
            <p>
              2. Focus on Overall CO Performance: By averaging the performance of students across all questions mapped
              to a particular CO, this method provides a holistic view of CO attainment.
            </p>
            <p>
              3. Clear Comparison to Target: By comparing the average percentage marks for each CO with the target
              percentage, educators can easily assess whether the COs are being met.
            </p>
            <h5><b><u>Disadvantages:</u></b></h5>
            <p>
              1. Potential Oversimplification: Averaging the marks may oversimplify the assessment, potentially masking
              variations in student performance across different questions.
            </p>
            <p>
              2. Lack of Granularity: This method does not provide detailed insights into student performance at the
              question level, which may limit the ability to identify specific areas for improvement.
            </p>
            <p>
              3. Ignoring Question Difficulty: It does not take into account the difficulty level of individual
              questions, which may impact the overall CO attainment assessment.
            </p>

            <b-row class="p-2">
              <b-col style="text-align:left;">
                <b-button variant="danger" class="text-white sm" size="sm"
                  @click="$bvModal.hide('showMethod2')">Close</b-button>
              </b-col>

            </b-row>
          </b-modal>
          <label for="indirect-weightage-input"><b>Method 2: </b>CO-Targeted Average Attainment Method (CTAAM)</label>
          <br><span>Explanation: This method calculates the attainment level for each Course Outcome (CO) by averaging
            the percentage marks achieved by students against the target percentage for each CO.</span>
        </b-form-group>
      </b-row>
      <b-row>
        <b-col md="12">
          <div class="table-responsive" v-if="config">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>
                    Assignment
                    <b-form-group label="Choose Equation Type:">
                      <div class="d-flex">
                        <b-form-radio :value="1" v-model="selectedEquationType['assignment']">Method 1</b-form-radio>
                        <b-form-radio :value="2" v-model="selectedEquationType['assignment']" class="ml-3">Method
                          2</b-form-radio>
                      </div>
                    </b-form-group>
                  </th>
                  <th>
                    Assessment
                    <b-form-group label="Choose Equation Type:">
                      <div class="d-flex">
                        <b-form-radio :value="1" v-model="selectedEquationType['assessment']">Method 1</b-form-radio>
                        <b-form-radio :value="2" v-model="selectedEquationType['assessment']" class="ml-3">Method
                          2</b-form-radio>
                      </div>
                    </b-form-group>

                  </th>
                  <th>
                    Board Exam
                    <b-form-group label="Choose Equation Type:">
                      <div class="d-flex">
                        <b-form-radio :value="1" v-model="selectedEquationType['boardexam']">Method 1</b-form-radio>
                        <b-form-radio :value="2" v-model="selectedEquationType['boardexam']" class="ml-3">Method
                          2</b-form-radio>
                      </div>
                    </b-form-group>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["courseReport"],
  async created() {
    this.fetchData = true;
    await this.getAttainmentConfigration();
    this.fetchData = false;
  },

  data() {
    return {
      fetchData: false,
      equationTypes: [],
      config: true,
      selectAll: true,
      selectedEquationType: {
        assessment: null,
        assignment: null,
        boardexam: null
      }
    };
  },
  methods: {
    async getAttainmentConfigration() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/coattainment/getconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "equation_config",
        })
        .then((response) => {
          this.selectedEquationType.assessment = response.data.assessment;
          this.selectedEquationType.assignment = response.data.assignment;
          this.selectedEquationType.boardexam = response.data.boardexam;
        });
    },
    async setAttainmentConfigration() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/coattainment/setconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "equation_config",
          selectedEquationType: this.selectedEquationType,
        })
        .then(() => { });
    },
  },
};
</script>
