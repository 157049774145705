<template>
  <div>
    <CourseDiaryReport
      v-if="reportConfigComponant == 'CourseDiaryReport'"
      :courseReport="courseReport"
      @cancel="reportConfigComponant = ''"
    />
    <div v-if="reportConfigComponant == ''" class="courseReports">
      <b-overlay
        :show="downloadData"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Reports</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="align-middle">Course Diary</td>
              <td class="align-middle">
                <span
                  class="btn p-0 m-0 text-info font-weight-bolder"
                  @click="reportConfigComponant = 'CourseDiaryReport'"
                  >Configure & Download</span
                >
              </td>
            </tr>
            <tr>
              <td class="align-middle">Instructor Schedule</td>
              <td class="align-middle">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->
                <b-modal id="selectInstructor" hide-footer size="md">
                  <b-form-select
                    :options="instructorsOptions"
                    v-model="instructor"
                  ></b-form-select>
                  <div>
                    <br />
                    <b-button
                      variant="danger"
                      @click="instructorSelected(false)"
                      size="sm"
                      >Save & Downlod Report</b-button
                    >
                    <b-button
                      style="margin-left: 1px"
                      variant="primary"
                      @click="instructorSelected(true)"
                      size="sm"
                      >Save & View Report
                    </b-button>
                  </div>
                </b-modal>
                <b-button
                  v-if="institutionUserId"
                  class="mt-2"
                  variant="primary"
                  @click="instructorSelected(false)"
                  block
                >
                  Download Report</b-button
                >
                <b-button
                  v-else
                  variant="danger"
                  size="sm"
                  @click="$bvModal.show('selectInstructor')"
                  >Download Report
                </b-button>
              </td>
            </tr>
            <tr>
              <td class="align-middle">Course Syllabus</td>
              <td class="align-middle">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->
                <b-button
                  variant="danger"
                  size="sm"
                  @click="downloadCourseModulePDF(false)"
                  >Download Report</b-button
                >
                <b-button
                  style="margin-left: 1px"
                  variant="primary"
                  @click="downloadCourseModulePDF(true)"
                  size="sm"
                  >View Report
                </b-button>
              </td>
            </tr>
            <tr>
              <td class="align-middle">Course Outcome(CO)</td>
              <td class="align-middle">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->
                <b-button
                  variant="danger"
                  size="sm"
                  @click="downloadCourseOutcomePDF(false)"
                  >Download Report</b-button
                >
                <b-button
                  style="margin-left: 1px"
                  variant="primary"
                  @click="downloadCourseOutcomePDF(true)"
                  size="sm"
                  >View Report
                </b-button>
              </td>
            </tr>
            <tr>
              <td class="align-middle">Program Outcome(PO)</td>
              <td class="align-middle">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->
                <b-button
                  variant="danger"
                  size="sm"
                  @click="downloadProgramOutcomePDF(false)"
                  >Download Report</b-button
                >
                <b-button
                  style="margin-left: 1px"
                  variant="primary"
                  @click="downloadProgramOutcomePDF(true)"
                  size="sm"
                  >View Report
                </b-button>
              </td>
            </tr>
            <tr>
              <td class="align-middle">Program Specific Outcome(PSO)</td>
              <td class="align-middle">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->
                <b-button
                  variant="danger"
                  size="sm"
                  @click="downloadProgramSpecificOutcome(false)"
                  >Download Report</b-button
                >
                <b-button
                  style="margin-left: 1px"
                  variant="primary"
                  @click="downloadProgramSpecificOutcome(true)"
                  size="sm"
                  >View Report
                </b-button>
              </td>
            </tr>
            <tr>
              <td class="align-middle">CO/PO/PSO Mapping</td>
              <td class="align-middle">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->
                <b-button
                  variant="danger"
                  @click="downloadCOPOPSOMappingPDF(false)"
                  size="sm"
                  >Download Report</b-button
                >
                <b-button
                  style="margin-left: 1px"
                  variant="primary"
                  @click="downloadCOPOPSOMappingPDF(true)"
                  size="sm"
                  >View Report
                </b-button>
              </td>
            </tr>
            <tr>
              <td class="align-middle">Lesson Plan</td>
              <td class="align-middle">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->
                <b-button
                  variant="danger"
                  size="sm"
                  @click="downloadLessonPlansPDF(false)"
                  >Download Report</b-button
                >
                <b-button
                  style="margin-left: 1px"
                  variant="primary"
                  @click="downloadLessonPlansPDF(true)"
                  size="sm"
                  >View Report
                </b-button>
              </td>
            </tr>

            <tr>
              <td class="align-middle">Student Roll List</td>
              <td class="align-middle">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->
                <b-button
                  variant="danger"
                  size="sm"
                  @click="downloadCourseStudentListPDF(false)"
                  >Download Report</b-button
                >
                <b-button
                  style="margin-left: 1px"
                  variant="primary"
                  @click="downloadCourseStudentListPDF(true)"
                  size="sm"
                  >View Report
                </b-button>
              </td>
            </tr>

            <tr>
              <td class="align-middle">Details of assignment</td>
              <td class="align-middle">
                <b-modal
                  id="detailsOfAssignment"
                  no-close-on-backdrop
                  no-close-on-keyboard
                  no-close-on-esc
                  hide-footer
                  size="xl"
                >
                  <DetailsOfAssignments :courseReport="courseReport" />
                </b-modal>

                <span
                  class="btn p-0 m-0 text-info font-weight-bolder"
                  @click="$bvModal.show('detailsOfAssignment')"
                  >Configure & Download</span
                >
              </td>
            </tr>

            <tr>
              <td class="align-middle">
                Details of tutorials (<span class="text-info"
                  >Report contains Attendance linked tutorials and Activity
                  tutorials separately</span
                >)
              </td>
              <td class="align-middle">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->
                <b-button
                  variant="danger"
                  size="sm"
                  @click="downloadTutorials(false)"
                  >Download Report</b-button
                >
                <b-button
                  style="margin-left: 1px"
                  variant="primary"
                  @click="downloadTutorials(true)"
                  size="sm"
                  >View Report
                </b-button>
              </td>
            </tr>

            <tr>
              <td class="align-middle">Tutorial List Log Register</td>
              <td class="align-middle">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->
                <b-button
                  variant="danger"
                  size="sm"
                  @click="downloadTutorialLogRegister(false)"
                  >Download Report</b-button
                >
                <b-button
                  style="margin-left: 1px"
                  variant="primary"
                  @click="downloadTutorialLogRegister(true)"
                  size="sm"
                  >View Report
                </b-button>
              </td>
            </tr>

            <tr>
              <td class="align-middle">Assesment Instruments & COs</td>
              <td class="align-middle">
                <b-modal
                  id="assessmentTools"
                  no-close-on-backdrop
                  no-close-on-keyboard
                  no-close-on-esc
                  hide-footer
                  size="xl"
                >
                  <AssessmentTools :courseReport="courseReport" />
                </b-modal>

                <span
                  class="btn p-0 m-0 text-info font-weight-bolder"
                  @click="$bvModal.show('assessmentTools')"
                  >Configure & Download</span
                >
              </td>
            </tr>

            <tr
              v-for="({ name, option }, reportIndex) in additionalReports"
              :key="'additional' + reportIndex"
            >
              <td class="align-middle">{{ name }}</td>
              <td class="align-middle">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->
                <b-button
                  variant="danger"
                  size="sm"
                  @click="downloadCoursePropertiesPDF(option, name, false)"
                  >Download Report
                </b-button>
                <b-button
                  style="margin-left: 1px"
                  variant="primary"
                  @click="downloadCoursePropertiesPDF(option, name, true)"
                  size="sm"
                  >View Report
                </b-button>
              </td>
            </tr>

            <tr
              v-for="({ name, option }, classIndex) in classDetails"
              :key="'class' + classIndex"
            >
              <td class="align-middle">{{ name }}</td>
              <td class="align-middle">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->
                <b-button
                  variant="danger"
                  size="sm"
                  @click="downloadAdditionalInfoPDF(option, name, false)"
                  >Download Report
                </b-button>
                <b-button
                  style="margin-left: 1px"
                  variant="primary"
                  @click="downloadAdditionalInfoPDF(option, name, true)"
                  size="sm"
                  >View Report
                </b-button>
              </td>
            </tr>
            <tr>
              <td class="align-middle">Attendance Reports</td>
              <td class="align-middle">
                <div
                  class="selectReportType mb-2 d-flex justify-content-center"
                >
                  <span
                    class="text-info btn p-0 m-0 font-weight-bolder"
                    @click="openAttendanceReportModel"
                  >
                    Configure & download
                  </span>
                </div>

                <b-modal
                  id="attendanceReportModel"
                  hide-footer
                  hide-header
                  centered
                  size="md"
                >
                  <div style="text-align: right">
                    <b-button
                      variant="danger"
                      @click="$bvModal.hide('attendanceReportModel')"
                      >x</b-button
                    >
                  </div>

                  <b-container class="mt-2 pt-2">
                    <b-form-group>
                      <b-row>
                        <label class="" for=""
                          >Choose date range (optional):
                        </label>
                        <b-form-checkbox
                          class="ml-2"
                          v-if="!enableAttendanceReportDateRange"
                          v-model="enableAttendanceReportDateRange"
                        ></b-form-checkbox>

                        <div
                          class="col-2"
                          v-if="
                            attendanceReport_date_from ||
                            attendanceReport_date_upto
                          "
                        >
                          <b-link
                            @click="
                              attendanceReport_date_from = null;
                              attendanceReport_date_upto = null;
                            "
                            ><i class="text-info fas fa-times-circle"></i
                          ></b-link>
                        </div>
                      </b-row>

                      <b-row v-if="enableAttendanceReportDateRange">
                        <b-col md="6">
                          <label for="example-datepickerfrom">From:</label>
                          <b-form-datepicker
                            id="example-datepickerfrom"
                            v-model="attendanceReport_date_from"
                            class="mb-2"
                          ></b-form-datepicker>
                        </b-col>
                        <b-col md="6">
                          <label for="example-datepickerto">To:</label>
                          <b-form-datepicker
                            id="example-datepickerto"
                            v-model="attendanceReport_date_upto"
                            class="mb-2"
                          >
                          </b-form-datepicker> </b-col
                      ></b-row>
                    </b-form-group>
                    <b-form-group>
                      <label class="" for=""
                        >No. of date,session per page(optional):</label
                      >
                      <b-col md="6" class="pl-1">
                        <b-form-input
                          id="example-datepickerfrom"
                          class="mb-2"
                          type="number"
                          max="30"
                          v-model="attendanceReport_date_per_page"
                        ></b-form-input
                      ></b-col>
                    </b-form-group>
                    <b-form-group
                      id="input-group-2"
                      label="Include by class type:"
                      label-for="input-2"
                    >
                      <b-form-checkbox-group
                        id="checkbox-group-2"
                        v-model="selectedClassTypes"
                        name="flavour-2"
                      >
                        <b-form-checkbox
                          v-for="(cType, scindex) in classTypesOptionsArray"
                          :key="scindex"
                          :value="cType.value"
                          >{{ cType.text }}</b-form-checkbox
                        >
                      </b-form-checkbox-group>
                    </b-form-group>

                    <b-row>
                      <b-button
                        pill
                        @click="
                          downloadConsolidatedAttendenceReports(true, false)
                        "
                        class="ml-2"
                        variant="outline-success"
                        >Save & Download Consolidated Report
                      </b-button>
                      <b-button
                        style="margin-left: 1px"
                        variant="primary"
                        @click="
                          downloadConsolidatedAttendenceReports(true, true)
                        "
                        size="sm"
                        >Save & View Report
                      </b-button>
                    </b-row>

                    <b-row class="ml-1 my-4">
                      <b-button
                        pill
                        @click="downloadDetailedAttendenceReports(true, false)"
                        variant="outline-success"
                        >Save & Download Detailed Report
                      </b-button>
                      <b-button
                        style="margin-left: 1px"
                        variant="primary"
                        @click="downloadDetailedAttendenceReports(true, true)"
                        size="sm"
                        >Save & View Report
                      </b-button>
                    </b-row>
                  </b-container>
                </b-modal>
              </td>
            </tr>

            <tr>
              <td class="align-middle">Lab Experiments</td>
              <td class="align-middle">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->
                <b-button
                  variant="danger"
                  size="sm"
                  @click="downloadLAbExperimentsListPDF(false)"
                  >Download Report</b-button
                >
                <b-button
                  style="margin-left: 1px"
                  variant="primary"
                  @click="downloadLAbExperimentsListPDF(true)"
                  size="sm"
                  >View Report
                </b-button>
              </td>
            </tr>
            <tr>
              <td class="align-middle">Lab Evaluation sheet</td>
              <td class="align-middle">
                <div
                  class="selectReportType mb-2 d-flex justify-content-center"
                >
                  <span
                    class="text-info btn p-0 m-0 font-weight-bolder"
                    @click="openLabEvaluationSheetReportModel"
                  >
                    Configure & download
                  </span>
                </div>

                <b-modal
                  id="labEvaluationSheetReportModel"
                  hide-footer
                  hide-header
                  centered
                  size="md"
                >
                  <div style="text-align: right">
                    <b-button
                      variant="danger"
                      @click="$bvModal.hide('labEvaluationSheetReportModel')"
                      >x</b-button
                    >
                  </div>

                  <b-container class="mt-2 pt-2">
                    <div class="col">
                      <b-form-group
                        label="Evaluation sheet of:"
                        v-slot="{ evaluationSheetDownloadOption }"
                      >
                        <b-form-radio
                          @input="
                            NoOfExperimentsInLabEvaluationSheet =
                              LabEvaluationSheetOf == 'lab' ? 10 : 0
                          "
                          v-model="LabEvaluationSheetOf"
                          :aria-describedby="evaluationSheetDownloadOption"
                          name="some-radios"
                          value="lab"
                          >Continuous evaluation</b-form-radio
                        >
                        <b-form-radio
                          v-model="LabEvaluationSheetOf"
                          :aria-describedby="evaluationSheetDownloadOption"
                          name="some-radios"
                          value="internal_exam"
                          >Internal exam</b-form-radio
                        >
                        <b-form-radio
                          v-model="LabEvaluationSheetOf"
                          :aria-describedby="evaluationSheetDownloadOption"
                          name="some-radios"
                          value="viva_voce"
                          >Viva voce</b-form-radio
                        >
                      </b-form-group>
                    </div>

                    <b-row class="my-2">
                      <b-col>
                        <span
                          >No. of experiments/page (
                          <span class="text-danger">
                            0 for score without experiments</span
                          >):</span
                        ></b-col
                      >
                      <b-col>
                        <b-form-input
                          class="mb-2"
                          type="number"
                          v-model="NoOfExperimentsInLabEvaluationSheet"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="m-2"
                      ><b-button
                        pill
                        @click="downloadLabEvaluationSheetPDF(true, false)"
                        variant="outline-success"
                        >Save & Download Report
                      </b-button>
                      <b-button
                        style="margin-left: 1px"
                        variant="primary"
                        @click="downloadLabEvaluationSheetPDF(true, true)"
                        size="sm"
                        >Save & View Report
                      </b-button>
                    </b-row>
                  </b-container>
                </b-modal>
              </td>
            </tr>
            <tr>
              <td class="align-middle">Lab summary report</td>
              <td class="align-middle">
                <div
                  class="selectReportType mb-2 d-flex justify-content-center"
                >
                  <span
                    class="text-info btn p-0 m-0 font-weight-bolder"
                    v-b-modal="'labSummaryReportModel'"
                  >
                    Configure & download
                  </span>
                </div>

                <b-modal
                  id="labSummaryReportModel"
                  hide-footer
                  hide-header
                  centered
                  size="md"
                >
                  <div style="text-align: right">
                    <b-button
                      variant="danger"
                      @click="$bvModal.hide('labSummaryReportModel')"
                      >x</b-button
                    >
                  </div>

                  <b-container class="mt-2 pt-2">
                    <b-row class="mt-2">
                      <b-col>
                        <span>Score for Continuous Assessment:</span></b-col
                      >
                      <b-col>
                        <b-form-input
                          class="mb-2"
                          type="number"
                          v-model="laboratoryAssessmentScore"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col> <span>Score for Internal Test :</span></b-col>
                      <b-col>
                        <b-form-input
                          class="mb-2"
                          type="number"
                          v-model="laboratoryInternalTestScore"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col> <span>Score for Viva voce:</span></b-col>
                      <b-col>
                        <b-form-input
                          class="mb-2"
                          type="number"
                          v-model="laboratoryVivaVoceScore"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col> <span>Score for Attendance:</span></b-col>
                      <b-col>
                        <b-form-input
                          class="mb-2"
                          type="number"
                          v-model="laboratoryAttendanceScore"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <div
                      class="col"
                      v-if="
                        laboratoryAttendanceScore != null &&
                        laboratoryAttendanceScore > 0
                      "
                    >
                      <div class="row score">
                        <div class="col">
                          <div class="row">
                            <div class="col">
                              <span style="color: gray"
                                >Note: By default the entire attendance for
                                course will be considered for report, use date
                                picker only if attendance has to be filtered
                                according to date.</span
                              >
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <label for="example-datepickerfrom"
                                >Date From:</label
                              >
                              <b-form-datepicker
                                id="example-datepickerfrom"
                                v-model="attendence.date_from"
                                class="mb-2"
                              ></b-form-datepicker>
                              <br />
                            </div>

                            <div class="col">
                              <label for="example-datepickerto"
                                >Date Upto:</label
                              >
                              <b-form-datepicker
                                id="example-datepickerto"
                                v-model="attendence.date_upto"
                                class="mb-2"
                              ></b-form-datepicker>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row score">
                        <div class="col">
                          <b-form-group
                            label="Attendance score round :"
                            v-slot="{ attendancescoreroundingradio }"
                          >
                            <b-form-radio
                              v-model="attendence.roundAttendenceScoreTo"
                              :aria-describedby="attendancescoreroundingradio"
                              name="some-radios"
                              value="up"
                              >Up</b-form-radio
                            >
                            <b-form-radio
                              v-model="attendence.roundAttendenceScoreTo"
                              :aria-describedby="attendancescoreroundingradio"
                              name="some-radios"
                              value="down"
                              >Down</b-form-radio
                            >
                            <b-form-radio
                              v-model="attendence.roundAttendenceScoreTo"
                              :aria-describedby="attendancescoreroundingradio"
                              name="some-radios"
                              value="decimals"
                              >To decimals</b-form-radio
                            >
                            <b-form-radio
                              v-model="attendence.roundAttendenceScoreTo"
                              :aria-describedby="attendancescoreroundingradio"
                              name="some-radios"
                              value="mapping"
                              >To Custom mapping</b-form-radio
                            >
                          </b-form-group>
                        </div>
                        <div class="col">
                          <div
                            v-if="
                              attendence.roundAttendenceScoreTo == 'decimals'
                            "
                          >
                            <label for="roundAttendenceScoreDecimals"
                              >Number of decimals:</label
                            >
                            <b-form-input
                              v-model="attendence.roundAttendenceScoreDecimals"
                              id="roundAttendenceScoreDecimals"
                              type="number"
                            ></b-form-input>
                          </div>
                        </div>
                      </div>

                      <b-container
                        fluid
                        v-if="attendence.roundAttendenceScoreTo == 'mapping'"
                      >
                        <h3>Attendance percentage - Score mapping</h3>
                        <p>
                          (Conditions are compare with student attendance
                          percentage one by one, if condition is true, the score
                          is selected for that student )
                        </p>
                        <b-row
                          style="text-align: left"
                          class="my-1"
                          v-for="(
                            mapping, mapkey
                          ) in attendence.roundAttendenceScoreCustomMapping"
                          :key="mapkey"
                        >
                          <b-col sm="1" class="px-0">
                            <label>{{ mapkey + 1 }}</label>
                          </b-col>
                          <b-col sm="3" class="px-0">
                            <label :for="`1type-${mapkey}`">Percentage:</label>
                          </b-col>
                          <b-col sm="2" class="px-0">
                            <b-form-input
                              style="width: 60px"
                              :id="`1type-${mapkey}`"
                              type="number"
                              min="0"
                              max="100"
                              step=".1"
                              placeholder="persantage"
                              v-model="mapping.persantage"
                            ></b-form-input>
                          </b-col>
                          <b-col sm="3" class="px-0">
                            <label :for="`type-${mapkey}`"
                              >Score for
                              <code>{{ mapping.persantage }}%</code> and
                              above:</label
                            >
                          </b-col>
                          <b-col sm="2" class="px-0">
                            <b-form-input
                              style="width: 60px"
                              :id="`type-${mapkey}`"
                              v-model="mapping.score"
                              type="number"
                              min="0"
                              step=".1"
                            ></b-form-input>
                          </b-col>
                          <b-col sm="1" class="px-0">
                            <b-button
                              @click="removescoremapping(mapkey)"
                              variant="danger"
                              >-</b-button
                            >
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-button @click="addscoremapping()" variant="success"
                            >Add new row</b-button
                          >
                        </b-row>
                      </b-container>

                      <div>
                        <b-form-group label="Class Types Selection (Optional):">
                          <b-form-checkbox-group
                            id="checkbox-group-5"
                            v-model="selectedClassTypes"
                            name="flavour-2"
                          >
                            <b-form-checkbox
                              v-for="(
                                classType, ctIndex
                              ) in classTypesOptionsArray"
                              :key="ctIndex"
                              :value="classType.value"
                              >{{ classType.text }}</b-form-checkbox
                            >
                          </b-form-checkbox-group>
                        </b-form-group>
                      </div>
                    </div>

                    <b-row class="m-2">
                      <b-button
                        pill
                        @click="downloadLabSummaryReportPDF(true, false)"
                        variant="outline-success"
                        >Save & Download Report
                      </b-button>
                      <b-button
                        style="margin-left: 1px"
                        variant="primary"
                        @click="downloadLabSummaryReportPDF(true, true)"
                        size="sm"
                        >Save & View Report
                      </b-button>
                    </b-row>
                  </b-container>
                </b-modal>
              </td>
            </tr>

            <tr>
              <td class="align-middle">Course Coverage Report</td>
              <td class="align-middle">
                
                <div
                  class="selectReportType mb-2 d-flex justify-content-center"
                >
                  <span
                    class="text-info btn p-0 m-0 font-weight-bolder"
                    @click="openCourseCoverageReportModel"
                  >
                    Configure & download
                  </span>
                </div>

                <b-modal
                  id="courseCoverageReportModel"
                  hide-footer
                  hide-header
                  centered
                  size="md"
                >
                  <div style="text-align: right">
                    <b-button
                      variant="danger"
                      @click="$bvModal.hide('courseCoverageReportModel')"
                      >x</b-button
                    >
                  </div>

                  <b-container class="mt-2 pt-2">
                    <b-form-group
                      id="input-group-2"
                      label="Select to exclude class type attendances:"
                      label-for="input-2"
                    >
                      <b-form-checkbox-group
                        id="checkbox-group-2"
                        v-model="selectedClassTypes"
                        name="flavour-2"
                      >
                        <b-form-checkbox
                          v-for="(cType, scindex) in classTypesOptionsArray"
                          :key="scindex"
                          :value="cType.value"
                          >{{ cType.text }}</b-form-checkbox
                        >
                      </b-form-checkbox-group>
                    </b-form-group>

                    <b-row>
                      <b-button
                        pill
                        @click="
                          downloadCourseCoveragePDF(false)
                        "
                        class="ml-2"
                        variant="outline-success"
                        >Save & Download Report
                      </b-button>
                      <b-button
                        style="margin-left: 1px"
                        variant="primary"
                        @click="
                          downloadCourseCoveragePDF(true)
                        "
                        size="sm"
                        >Save & View Report
                      </b-button>
                    </b-row>
                  </b-container>
                </b-modal>
              </td>
            </tr>
            <tr>
              <td class="align-middle">Internal Evaluation Report</td>
              <td class="align-middle">
                <span
                  class="btn p-0 m-0 text-info"
                  @click="$bvModal.show('configureAndDownload')"
                  style="font-size: 14px; font-weight: 600"
                  >Configure & Download</span
                >

                <b-modal
                  id="configureAndDownload"
                  :title="'Internal Configuration' + ' - ' + courseReport.name"
                  no-close-on-backdrop
                  no-close-on-keyboard
                  no-close-on-esc
                  hide-footer
                  size="lg"
                >
                  <CourseConfigration :courseReport="courseReport" />
                </b-modal>
              </td>
            </tr>
            <tr>
              <td class="align-middle">Attainment Reports</td>
              <td class="align-middle">
                <span
                  class="btn p-0 m-0 text-info"
                  @click="$bvModal.show('attainmentReport')"
                  style="font-size: 14px; font-weight: 600"
                  >Configure & Download</span
                >

                <b-modal
                  id="attainmentReport"
                  :title="
                    'Attainment Configuration' + ' - ' + courseReport.name
                  "
                  no-close-on-backdrop
                  no-close-on-keyboard
                  no-close-on-esc
                  hide-footer
                  size="xl"
                >
                  <Attainment
                    @finishAttainment="$bvModal.hide('attainmentReport')"
                    :courseReport="courseReport"
                  />
                </b-modal>
              </td>
            </tr>

            <tr
              v-if="institutionAdminRole || groupAdminRole || institutionUserId"
            >
              <td class="align-middle">Feedback Survey Reports</td>
              <td class="align-middle">
                <div
                  class="selectReportType mb-2 d-flex justify-content-center"
                >
                  <span
                    class="text-info btn p-0 m-0 font-weight-bolder"
                    v-b-modal="'feedbackSurveyReportModel'"
                    @click="getCourseFeedbackSurveys()"
                  >
                    Configure & download
                  </span>
                </div>

                <b-modal
                  id="feedbackSurveyReportModel"
                  hide-footer
                  hide-header
                  centered
                  size="md"
                >
                  <div style="text-align: right">
                    <b-button
                      variant="danger"
                      @click="$bvModal.hide('feedbackSurveyReportModel')"
                      >x</b-button
                    >
                  </div>

                  <b-container class="mt-2 pt-2">
                    <b-row class="mt-2">
                      <b-col> <span>Choose survey:</span></b-col>
                      <b-col>
                        <b-form-select
                          :options="feedbackSurveys"
                          v-model="selectedFeedbackSurvey"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2" v-if="!institutionUserId">
                      <b-col> <span>Choose faculty:</span></b-col>
                      <b-col>
                        <b-form-select
                          :options="instructorsOptions"
                          v-model="instructor"
                        ></b-form-select>
                      </b-col>
                    </b-row>

                    <b-row class="m-2"
                      ><b-button
                        pill
                        @click="downloadFeedbackSurveyReport(true, false)"
                        variant="outline-success"
                        >Save & Download Report
                      </b-button>
                      <b-button
                        style="margin-left: 1px"
                        variant="primary"
                        @click="downloadFeedbackSurveyReport(true, true)"
                        size="sm"
                        >Save & View Report
                      </b-button>
                    </b-row>
                  </b-container>
                </b-modal>
              </td>
            </tr>
          </tbody>
        </table>
      </b-overlay>
    </div>
  </div>
</template>
<script>
import CourseDiaryReport from "./CourseDiaryReport.vue";
import CourseConfigration from "./course_config/CourseConfigration.vue";
import Attainment from "./attainment/Attainment.vue";
import DetailsOfAssignments from "./DetailsOfAssignments";
import AssessmentTools from "./AssessmentTools.vue";
import acl from "../../../../authorization/acl.js";
export default {
  props: ["courseReport", "institutionUserId"],
  async created() {
    const institutionAdmin = await acl.isUserGranted("institutionAdmin");
    this.institutionAdminRole = institutionAdmin;
    const groupAdmin = await acl.isUserGranted("groupAdmin");
    this.groupAdminRole = groupAdmin;

    this.downloadData = true;
    if (!this.institutionUserId) await this.getInstructors();
    this.downloadData = false;
  },
  components: {
    CourseConfigration,
    Attainment,
    DetailsOfAssignments,
    AssessmentTools,
    CourseDiaryReport,
  },
  data() {
    return {
      reportConfigComponant: "",
      enableAttendanceReportDateRange: false,
      institutionAdminRole: null,
      groupAdminRole: null,
      mainGroupAdmin: null,
      userGroupAdmin: null,
      downloadData: false,
      instructorsOptions: [],
      instructor: null,
      reportType: 0,
      additionalReports: [
        {
          name: "Details of learning material provide",
          option: "details_of_lerning_matials",
        },
        {
          name: "Content beyond the syllabus",
          option: "content_beyond_syllabus",
        },
        {
          name: "Industrial relevance of course",
          option: "industrial_relevence",
        },
      ],
      classDetails: [
        { name: "Details of extra class", option: "extra_class" },
        { name: "Details of Remedial Class", option: "remedial_class" },
        { name: "Remedial/ Bridge class log register", option: "bridge_class" },
        { name: "Details of Revision class", option: "revision" },
      ],
      attendanceReport_date_from: null,
      attendanceReport_date_upto: null,
      attendanceReport_date_per_page: 24,

      feedbackSurveys: [],
      selectedFeedbackSurvey: null,
      laboratoryAttendanceScore: 15,
      laboratoryInternalTestScore: 30,
      laboratoryAssessmentScore: 15,
      laboratoryVivaVoceScore: 15,
      LabEvaluationSheetOf: "lab",
      NoOfExperimentsInLabEvaluationSheet: 10,
      save_config: false,
      classTypesOptions: [],
      selectedClassTypes: [],

      attendence: {
        date_from: null,
        date_upto: null,
        roundAttendenceScoreTo: null,
        roundAttendenceScoreDecimals: null,
        roundAttendenceScoreCustomMapping: [
          // {persanatge:score}
          { persantage: "90", score: "10" },
          { persantage: "85", score: "9" },
          { persantage: "80", score: "8" },
          { persantage: "75", score: "7" },
          { persantage: "70", score: "6" },
          { persantage: "0", score: "5" },
        ],
        selectedClassTypes: [],
      },
    };
  },
  computed: {
    classTypesOptionsArray() {
      if (this.classTypesOptions.length === 0) {
        this.getClassTypes();
      }
      return this.classTypesOptions;
    },
  },
  methods: {
    async openLabEvaluationSheetReportModel() {
      this.$bvModal.show("labEvaluationSheetReportModel");
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/labevaluations/studentsvsexperiments/report/pdfexport`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          reportOptions: true,
        })
        .then((response) => {
          this.NoOfExperimentsInLabEvaluationSheet =
            response.data.experiments_per_page;
        })
        .catch(() => {});
    },
    async openAttendanceReportModel() {
      this.$bvModal.show("attendanceReportModel");
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/coursestudents/detailed/pdfexport`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          reportOptions: true,
        })
        .then((response) => {
          let configs = response.data;
          this.selectedClassTypes = configs.class_types;
          this.attendanceReport_date_per_page = configs.dates_per_page;
          this.attendanceReport_date_upto = configs.upto_date
            ? configs.upto_date.replace(/\//g, "-")
            : null;
          this.attendanceReport_date_from = configs.start_date
            ? configs.start_date.replace(/\//g, "-")
            : null;
          if (
            this.attendanceReport_date_upto != null ||
            this.attendanceReport_date_from != null
          )
            this.enableAttendanceReportDateRange = true;
        })
        .catch(() => {});
    },
    removescoremapping(mapkey) {
      this.attendence.roundAttendenceScoreCustomMapping.splice(mapkey, 1);
    },
    addscoremapping() {
      this.attendence.roundAttendenceScoreCustomMapping.push({
        persantage: "0",
        score: "5",
      });
    },
    getCourseFeedbackSurveys() {
      if (this.feedbackSurveys.length) return;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/studfbsurveys?course=${
          this.courseReport.course_id
        }&course_instructor=${
          this.institutionUserId ?? this.instructor
        }&survey_type=student_feedback`;

      this.$axios
        .get(url)
        .then((response) => {
          this.feedbackSurveys = [
            { value: null, text: "Select Survey", disabled: true },
          ];
          const surveys = response.data;
          surveys.forEach((survey) => {
            this.feedbackSurveys.push({
              value: survey.id,
              text: survey.name,
            });
          });
        })
        .catch(() => {
          return;
        });
    },
    async downloadFeedbackSurveyReport(save_config, viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/studfbsurveys/${this.selectedFeedbackSurvey}/report/pdfexport?custom_report=${this.reportType}`;

      await this.$axios
        .post(
          url,
          {
            course: this.courseReport.course_id,
            course_instructor: this.institutionUserId ?? this.instructor,
            save_config: save_config,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "FeedbackSurveyReport.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    //InstructorList

    async getInstructors() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/${this.courseReport.course_id}/instructors`;
      await this.$axios.get(url).then((res) => {
        this.instructorsOptions = [
          { value: null, text: "Select Instructor", disabled: true },
        ];
        const intructors = res.data;
        intructors.forEach((instructor) => {
          this.instructorsOptions.push({
            value: instructor.pivot.institution_user_id,
            text:
              instructor.first_name +
              " " +
              (instructor.last_name || "") +
              `( ${instructor.email})`,
          });
        });
        res;
      });
    },
    async instructorSelected(viewReport) {
      this.$bvModal.hide("selectInstructor");
      this.downloadData = true;
      await this.downloadInstructorSchedulePDF(viewReport);
      this.downloadData = false;
    },
    //------------------Additional Info-------------------------------------------------
    async downloadAdditionalInfoPDF(option, name, viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/classtype/course/deliveryhistory/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          {
            course: this.courseReport.course_id,
            classtype: option,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (option == "extra_class") {
              link.setAttribute("download", "Details of extra class.pdf");
            }
            if (option == "remedial_class") {
              link.setAttribute("download", "Details of Remedial Class.pdf");
            }
            if (option == "bridge_class") {
              link.setAttribute(
                "download",
                "Remedial/ Bridge Class Log Register List.pdf"
              );
            }
            if (option == "revision") {
              link.setAttribute("download", "Details of Revision Class.pdf");
            }
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    async downloadCoursePropertiesPDF(option, name, viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/properties/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          {
            course: this.courseReport.course_id,
            course_property: option,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (option == "details_of_lerning_matials") {
              link.setAttribute(
                "download",
                "Details of learning material provide.pdf"
              );
            }
            if (option == "content_beyond_syllabus") {
              link.setAttribute("download", "Content beyond the syllabus.pdf");
            }
            if (option == "industrial_relevence") {
              link.setAttribute(
                "download",
                "Industrial relevance of course.pdf"
              );
            }
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    //------------------Instructor Schedule---------------------------------------- --Not Working
    async downloadInstructorSchedulePDF(viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courseschedules/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          {
            course: this.courseReport.course_id,
            institution_user: this.institutionUserId ?? this.instructor,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "InstructorScheduleList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    //------------------Course Module----------------------------------------
    async downloadCourseModulePDF(viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/coursemodules/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          { course: this.courseReport.course_id },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(pdfBlob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "CourseSyllabus.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }

          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    //Tutorial
    async downloadTutorials(viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/tutorials/detailes/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          { course: this.courseReport.course_id },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "TutorialsList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    //Tutorial Log Register
    async downloadTutorialLogRegister(viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/tutorials/logregister/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          { course: this.courseReport.course_id },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "TutorialLogRegisterList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },

    //------------------Course Outcome----------------------------------------
    async downloadCourseOutcomePDF(viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courseoutcomes/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          { course: this.courseReport.course_id },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "CourseOutcomeList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    //------------------Lesson Plans---------------------------------------- --NOT WORKING
    async downloadLessonPlansPDF(viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/lessonplans/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          { course: this.courseReport.course_id },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "LessonPlansList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    //------------------Attendance Reports----------------------------------------
    // async downloadAttendenceReports() {
    //   await this.downloadConsolidatedAttendenceReportPDF();
    //   await this.downloadDetailedAttendenceReportSummaryPDF();
    //   this.$bvModal.hide("attendanceReportModel");
    // },
    async downloadDetailedAttendenceReports(save_config, viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/coursestudents/detailed/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          {
            course: this.courseReport.course_id,
            start_date: this.attendanceReport_date_from,
            upto_date: this.attendanceReport_date_upto,
            dates_per_page: this.attendanceReport_date_per_page,
            save_config: save_config,
            class_types: this.selectedClassTypes,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "DetailedAttendenceList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    async downloadConsolidatedAttendenceReports(save_config, viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/coursestudents/summery/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          {
            course: this.courseReport.course_id,
            start_date: this.attendanceReport_date_from,
            upto_date: this.attendanceReport_date_upto,
            save_config: save_config,
            class_types: this.selectedClassTypes,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "ConsolidatedAttendencList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    //------------------Course Coverage Report----------------------------------------
    async openCourseCoverageReportModel() {
      this.selectedClassTypes = [];
      this.$bvModal.show("courseCoverageReportModel");
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/coursedeliveryhistorys/report/pdfexport`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          reportOptions: true,
        })
        .then((response) => {
          let configs = response.data;
          this.selectedClassTypes = configs.exclude_att_for_classtype;
        })
        .catch(() => {});
    },
    async downloadCourseCoveragePDF(viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/coursedeliveryhistorys/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          { course: this.courseReport.course_id, exclude_att_for_classtype: this.selectedClassTypes, save_config: true },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "CourseCoverageList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    //------------------Course Coverage Report----------------------------------------
    async downloadAssessmentPDF() {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/coursedeliveryhistorys/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          { course: this.courseReport.course_id },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "AssessmentReport.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    //------------------CO-PO/PSO Mapping----------------------------------------
    async downloadCOPOPSOMappingPDF(viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/copopsomappings/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          { course: this.courseReport.course_id },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "COPOPSOMappingList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    //------------------Course Student List----------------------------------------
    async downloadCourseStudentListPDF(viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courseusers/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          { course: this.courseReport.course_id },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "CourseStudentList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    //------------------Course Student List----------------------------------------
    async downloadProgramOutcomePDF(viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/programmeoutcomes/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          { course: this.courseReport.course_id },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "ProgramOutcomeList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
    //------------------Course Student List----------------------------------------
    async downloadProgramSpecificOutcome(viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/programmespecificoutcomes/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          { usergroup: this.courseReport.usergroup_id, course: this.courseReport.course_id },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "ProgramSpecificOutcomeList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },

    async downloadLAbExperimentsListPDF(viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/labexperiments/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          { course: this.courseReport.course_id },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "LAbExperimentsList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },

    async downloadLabEvaluationSheetPDF(save_config, viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/labevaluations/studentsvsexperiments/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          {
            course: this.courseReport.course_id,
            lab_type: this.LabEvaluationSheetOf,
            experiments_per_page: this.NoOfExperimentsInLabEvaluationSheet,
            save_config: save_config,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "LabEvaluationSheetList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },

    async downloadLabSummaryReportPDF(save_config, viewReport) {
      this.downloadData = true;
      this.attendence.selectedClassTypes = this.selectedClassTypes;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/labevaluations/summary/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          {
            course: this.courseReport.course_id,
            assessment_score: this.laboratoryAssessmentScore,
            internaltest_score: this.laboratoryInternalTestScore,
            vivavoce_score: this.laboratoryVivaVoceScore,
            attendance_score: this.laboratoryAttendanceScore,
            attendance_config: this.attendence,
            save_config: save_config,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "LabSummaryReportList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },

    async getClassTypes() {
      // if (this.classTypesOptions.length > 0) return this.classTypesOptions;
      const url = this.$store.getters.getAPIKey.mainAPI + `/classtypes`;
      await this.$axios.get(url).then((res) => {
        const options = res.data;
        this.classTypesOptions = [{ value: 0, text: "Regular" }];
        this.selectedClassTypes.push(0);
        options.forEach(({ id, name }) => {
          this.classTypesOptions.push({ value: id, text: name });
          this.selectedClassTypes.push(id);
        });
      });
      // return this.classTypesOptions;
    },
  },
};
</script>

<style scoped>
table button {
  font-size: 12px;
  font-weight: 600;
}

table tbody tr td:nth-child(2) {
  text-align: center;
}
</style>

<style>
.courseReports table tr td:first-child {
  text-transform: capitalize;
}

.courseReports table tbody td:nth-child(2) {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
</style>
