<template>
  <div>
    <b-skeleton-table
      v-if="fetchData"
      :rows="3"
      :columns="2"
      :table-props="{ bordered: true, striped: true }"
    >
    </b-skeleton-table>
    <div class="detailsOfAssignment" v-else>
      <b-overlay
        :show="loadReport"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <div
          class="submitAssignments mb-3"
          v-if="selectedAssignments.length != 0"
        >
          <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
            <b-form-radio v-model="reportType" :value="0"
              >Oridinary Report</b-form-radio
            >
            <b-form-radio v-model="reportType" :value="1" class="ml-3"
              >ISO Report</b-form-radio
            >
          </div> -->
          <b-row class="m-2">
            <b-button
              variant="danger"
              size="sm"
              @click="downloadAssignmentPDF(true, false)"
              >Save & Download Report</b-button
            >
            <b-button
              style="margin-left: 1px"
              variant="primary"
              @click="downloadAssignmentPDF(true, true)"
              size="sm"
              >Save & View Report
            </b-button>
          </b-row>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead class="thead-dark">
              <tr>
                <th class="align-middle"></th>
                <th class="align-middle">Assignment</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="exams.length == 0" class="align-middle">
                <td
                  colspan="100"
                  class="text-center font-weight-bolder text-danger"
                >
                  No Assignments Found!
                </td>
              </tr>
              <tr
                v-else
                v-for="({ name, id }, examIndex) in exams"
                :key="examIndex"
              >
                <td class="align-middle">
                  <b-form-checkbox
                    v-model="selectedAssignments"
                    :value="id"
                  ></b-form-checkbox>
                </td>
                <td class="align-middle">
                  {{ name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
export default {
  props: ["courseReport"],
  async created() {
    this.fetchData = true;
    await this.getExams();
    await this.getReportOptions();
    this.fetchData = false;
    this.$forceUpdate();
  },
  data() {
    return {
      exams: [],
      selectedAssignments: [],
      reportType: 0,
      loadReport: false,
      save_config: false,
    };
  },
  methods: {
    async downloadAssignmentPDF(save_config, viewReport) {
      this.loadReport = true;
      const postData = {
        course: this.courseReport.course_id,
        exams: this.selectedAssignments,
        save_config: save_config,
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/exams/detailes/report/pdfExport?custom_report=${this.reportType}`;
      await this.$axios
        .post(url, postData, { responseType: "blob" })
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "AssignmentList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          // this.loadReport = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
        });
      this.loadReport = false;
    },
    async getReportOptions() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/exams/detailes/report/pdfExport`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          reportOptions: true,
        })
        .then((response) => {
          this.selectedAssignments = response.data.exam_ids;
        })
        .catch(() => {});
    },
    async getExams() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/${this.courseReport.course_id}/exams`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.exams = response.data.filter((assignment) => {
            return assignment.activity_type == "assignment";
          });
          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>
