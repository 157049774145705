<template>
  <div>
    <div class="courseConfigration">
      <b-row>
        <b-col md="12">
          <b-overlay :show="loadData" rounded="sm">
            <template #overlay>
              <b-icon
                icon="stopwatch"
                variant="info"
                scale="3"
                shift-v="8"
                shift-h="8"
                class="reportIcon"
              ></b-icon>
            </template>
            <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
              <b-form-radio v-model="reportType" :value="0"
                >Oridinary Report</b-form-radio
              >
              <b-form-radio v-model="reportType" :value="1" class="ml-3"
                >ISO Report</b-form-radio
              >
            </div> -->
            <div v-if="!loadConfigData">
              <div class="attendence">
                <AttendenceConfig
                  ref="attendenceConfig"
                  :saved_configs="saved_configs"
                  :courseReport="courseReport"
                  :classTypes="classTypes"
                  :selectedClassTypesProp="selectedClassTypes"
                />
              </div>
              <br />
              <div class="ml-2">
                <div class="attendence border border-info rounded p-2">
                  <div class="">
                    <b-row>
                      <b-col>
                        <div class="">
                          <b-form-checkbox
                            class="ml-2"
                            v-model="activity_simple_calculation"
                            @change="updateActivitySimpleCalculation"
                          >
                            <p class="btn p-0 m-0 text-info">
                              Simple activity score calculation
                            </p>
                          </b-form-checkbox>
                        </div>
                      </b-col>
                      <b-col>
                        <div class="">
                          <b-form-checkbox
                            class=""
                            v-model="remove_weigtage_column"
                            @change="updateActivitySimpleCalculation"
                          >
                            <p class="btn p-0 m-0 text-info">
                              Remove weigtage column from report
                            </p>
                          </b-form-checkbox>
                        </div>
                      </b-col>
                      <b-col>
                        <div class="">
                          <b-form-checkbox class="" v-model="description_page">
                            <p class="btn p-0 m-0 text-info">
                              Description page
                            </p>
                          </b-form-checkbox>
                        </div>
                      </b-col>
                      <b-col>
                        <div class="">
                          <b-form-checkbox
                            class=""
                            v-model="final_score_rounding"
                          >
                            <p class="btn p-0 m-0 text-info">
                              Roundup Final Score
                            </p>
                          </b-form-checkbox>
                        </div>
                      </b-col>
                      <b-col>
                        <div class="">
                          <b-form-checkbox
                            class=""
                            v-model="final_score_roundup"
                          >
                            <p class="btn p-0 m-0 text-info">
                              Final score rounded up to next integer (eg: 11.2
                              => 12)
                            </p>
                          </b-form-checkbox>
                        </div>
                      </b-col>
                    </b-row>
                    <p v-if="activity_simple_calculation" class="m-1">
                      Score of assessments of a student = score for activities *
                      sum(score of student in activity) / sum(max. score of
                      activity)
                    </p>
                    <p v-if="!activity_simple_calculation" class="m-1">
                      Calculations details available in downloaded report
                    </p>
                  </div>
                </div>
              </div>
              <br />

              <div class="assignment">
                <AssignmentConfig
                  ref="assignmentConfig"
                  :saved_configs="saved_configs"
                  :courseReport="courseReport"
                  :activity_simple_calculation="activity_simple_calculation"
                />
              </div>
              <br />
              <div class="assessment">
                <AssessmentConfig
                  ref="assessmentConfig"
                  :saved_configs="saved_configs"
                  :courseReport="courseReport"
                  :activity_simple_calculation="activity_simple_calculation"
                />
              </div>
              <br />

              <div class="assessment border border-info rounded p-2">
                <div
                  class="checkAssignment d-flex justify-content-between align-items-baseline mb-2"
                >
                  <b-row>
                    <b-col class="sm-12">
                      <div class="d-flex">
                        <b-form-checkbox class="ml-2" v-model="seminarCheckBox">
                          <p class="btn p-0 m-0 text-info">Seminar</p>
                        </b-form-checkbox>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="sm-6">
                      <div class="" v-if="seminarCheckBox">
                        <label class="required" for="scoreForSeminar"
                          >Score:</label
                        >
                        <b-form-input
                          id="scoreForSeminar"
                          v-model="seminarScore"
                          placeholder="Enter Score"
                        ></b-form-input>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <br />

              <div class="border border-info rounded p-2">
                <b-form-checkbox class="ml-2" v-model="laboratory.checkLab">
                  <p class="btn p-0 m-0 text-info">Laboratory</p>
                </b-form-checkbox>

                <div v-if="laboratory.checkLab">
                  <b-row>
                    <b-col class="sm-6" role="group">
                      <label class="required" for=""
                        >Score for Continuous Assessment:</label
                      >
                      <b-form-input
                        id=""
                        v-model="laboratory.assessmentScore"
                        placeholder="Enter Score"
                      ></b-form-input>
                    </b-col>

                    <b-col class="sm-6" role="group">
                      <label class="required" for=""
                        >Score for Internal Test:</label
                      >
                      <b-form-input
                        id=""
                        v-model="laboratory.internalTestScore"
                        placeholder="Enter Score"
                      ></b-form-input>
                    </b-col>

                    <b-col class="sm-6" role="group">
                      <label class="required" for=""
                        >Score for Viva voce:</label
                      >
                      <b-form-input
                        id=""
                        v-model="laboratory.vivaVoceScore"
                        placeholder="Enter Score"
                      ></b-form-input>
                    </b-col>

                    <b-col class="sm-6" role="group">
                      <label class="required" for="">Score for Record:</label>
                      <b-form-input
                        id=""
                        v-model="laboratory.recordScore"
                        placeholder="Enter Score"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <br />

              <div class="border border-info rounded p-2">
                <b-row>
                  <b-col>
                    <b-form-checkbox class="ml-2" v-model="project.enable">
                      <p class="btn p-0 m-0 text-info">Project</p>
                    </b-form-checkbox></b-col
                  >
                  <b-col v-if="project.enable">
                    <div class="sm-6" role="group">
                      <label class="required" for="">Score for Project:</label>
                      <b-form-input
                        id=""
                        v-model="project.score"
                        placeholder="Enter Score"
                      ></b-form-input>
                    </div> </b-col
                ></b-row>
              </div>
              <br />

              <b-row class="m-2">
                <b-button
                  variant="danger"
                  size="sm"
                  @click="$bvModal.show('downloadConfirmation')"
                  >Download Report
                </b-button>

                <b-modal
                  id="downloadConfirmation"
                  no-close-on-backdrop
                  no-close-on-keyboard
                  no-close-on-esc
                  hide-footer
                  size="md"
                >
                  <h3 style="text-align: center">
                    <b
                      >The download will happen without saving the new
                      configuration value</b
                    >
                  </h3>
                  <br />
                  <b-button
                    style="margin-left: 230px"
                    variant="danger"
                    @click="prepareConfigrationForPDFDownload(false, false)"
                    size="sm"
                    >Ok
                  </b-button>
                </b-modal>

                <b-button
                  style="margin-left: 1px"
                  variant="danger"
                  size="sm"
                  @click="prepareConfigrationForPDFDownload(true, false)"
                  >Save & Download Report</b-button
                >
                <b-button
                  style="margin-left: 1px"
                  variant="primary"
                  @click="prepareConfigrationForPDFDownload(true, true)"
                  size="sm"
                  >Save & View Report
                </b-button>
              </b-row>
            </div>
            <br />
          </b-overlay>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AttendenceConfig from "./AttendenceConfig.vue";
import AssignmentConfig from "./AssignmentConfig.vue";
import AssessmentConfig from "./AssessmentConfig.vue";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
required;
export default {
  props: ["courseReport"],
  components: {
    AttendenceConfig,
    AssignmentConfig,
    AssessmentConfig,
  },
  async created() {
    //
    this.loadData = true;
    await this.getContinuesEvaluationOptions();
    // console.log('parent', this.selectedClassTypes);
    this.loadData = false;
  },
  data() {
    return {
      loadConfigData: true,
      loadData: false,
      reportType: 0,
      activity_simple_calculation: false,
      remove_weigtage_column: false,
      description_page: false,
      final_score_roundup: false,
      final_score_rounding: false,

      seminarScore: null,
      seminarCheckBox: false,
      save_config: false,

      classTypes: [],
      selectedClassTypes: [],

      laboratory: {
        checkLab: false,
        assessmentScore: "",
        internalTestScore: "",
        vivaVoceScore: "",
        recordScore: "",
      },
      project: { enable: false, score: "" },
      saved_configs: null,
    };
  },
  methods: {
    async getContinuesEvaluationOptions() {
      this.loadConfigData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/continuousevaluation/report/options`;
      await this.$axios
        .post(url, { course: this.courseReport.course_id })
        .then((response) => {
          this.classTypes = response.data.class_types_options;
          // this.selectedClassTypes = this.classTypes.map((option) => option.id);
          // this.selectedClassTypes.push(0);
          this.saved_configs = response.data.saved_config;
        })
        .catch(() => {
          // console.log(error.response);
        });
      if (this.saved_configs != null) {
        this.activity_simple_calculation =
          this.saved_configs.activity_catagory_configs.activity_simple_calculation;
        this.remove_weigtage_column =
          this.saved_configs.activity_catagory_configs.remove_weigtage_column;
        this.description_page = this.saved_configs.description_page;
        this.final_score_roundup = this.saved_configs.final_score_roundup;
        this.final_score_rounding = this.saved_configs.final_score_rounding;

        this.seminarScore = this.saved_configs.seminar_score;
        this.seminarCheckBox = this.saved_configs.enable_seminar;

        this.selectedClassTypes = this.saved_configs.class_types;

        this.laboratory = this.saved_configs.laboratory_config;

        this.project = this.saved_configs.project_config;
      }

      this.loadConfigData = false;
    },
    updateActivitySimpleCalculation() {
      // this.$forceUpdate();
    },
    async prepareConfigrationForPDFDownload(save_config, viewReport) {
      this.$bvModal.hide("downloadConfirmation");
      this.loadData = true;
      let attendenceComponent = await this.$refs.attendenceConfig;
      await attendenceComponent.checkValidation();

      let assignmentComponent = await this.$refs.assignmentConfig;
      await assignmentComponent.checkValidation();

      let assessmentComponent = await this.$refs.assessmentConfig;
      await assessmentComponent.checkValidation();

      if (
        attendenceComponent.$v.$anyError ||
        assignmentComponent.$v.$anyError ||
        assessmentComponent.$v.$anyError
      ) {
        return (this.loadData = false);
      }

      // if (
      //   !attendenceComponent.attendence.checkAttendence &&
      //   !assignmentComponent.assignment.checkAssignment &&
      //   !assessmentComponent.assessment.checkAssessment
      // ) {
      //   this.loadData = false;
      //   return this.$toast.error("Please Select atleast one", {
      //     position: "top",
      //     duration: 3000,
      //   });
      // }

      let attendence = await {
        checkAttendence: attendenceComponent.attendence.checkAttendence,
        scoreForAttendence: attendenceComponent.attendence.scoreForAttendence,
        date_from: attendenceComponent.attendence.date_from,
        date_upto: attendenceComponent.attendence.date_upto,
        roundAttendenceScoreTo:
          attendenceComponent.attendence.roundAttendenceScoreTo,
        roundAttendenceScoreDecimals:
          attendenceComponent.attendence.roundAttendenceScoreDecimals,
        roundAttendenceScoreCustomMapping:
          attendenceComponent.attendence.roundAttendenceScoreCustomMapping,
      };

      let assignmentDataFromComponent =
        await assignmentComponent.assignmentConfigStructure;
      let assignment = await {
        checkAssignment: assignmentComponent.assignment.checkAssignment,
        scoreForAssignment: assignmentComponent.assignment.scoreForAssignment,
        best_of_scores: assignmentComponent.assignment.best_of_scores,
        assignmentData: assignmentDataFromComponent,
      };
      let assessmentDataFromComponent =
        await assessmentComponent.assessmentConfigStructure;
      let assessment = await {
        checkAssessment: assessmentComponent.assessment.checkAssessment,
        scoreForAssessment: assessmentComponent.assessment.scoreForAssessment,
        best_of_scores: assessmentComponent.assessment.best_of_scores,
        assessmentData: assessmentDataFromComponent,
      };

      let internalsPostData = {
        save_config: save_config,
        enable_seminar: this.seminarCheckBox,
        seminar_score: this.seminarScore,
        final_score_roundup: this.final_score_roundup,
        final_score_rounding: this.final_score_rounding,
        description_page: this.description_page,
        course: this.courseReport.course_id,
        activity_catagory_configs: {
          attendance: {
            enable: attendence.checkAttendence,
            start_date:
              attendence.date_from != null
                ? moment(attendence.date_from).format("YYYY/MM/DD")
                : null,
            upto_date:
              attendence.date_upto != null
                ? moment(attendence.date_upto).format("YYYY/MM/DD")
                : null,
            score: attendence.scoreForAttendence,
            round_to: attendence.roundAttendenceScoreTo,
            decimal_count: attendence.roundAttendenceScoreDecimals,
            roundAttendenceScoreCustomMapping:
              attendence.roundAttendenceScoreCustomMapping,
          },
          activity_simple_calculation: this.activity_simple_calculation,
          remove_weigtage_column: this.remove_weigtage_column,
          exams: {
            enable: assessment.checkAssessment,
            score: assessment.scoreForAssessment,
            best_of_scores: assessment.best_of_scores,
            resourses: {
              exam: {},
            },
          },
          assignments: {
            enable: assignment.checkAssignment,
            score: assignment.scoreForAssignment,
            best_of_scores: assignment.best_of_scores,
            resourses: {
              exam: {},
            },
          },
        },
        class_types: attendenceComponent.selectedClassTypes,
        laboratory: this.laboratory,
        project: this.project,
      };
      await assignment.assignmentData;
      let dataFromAssignment = assignment.assignmentData;
      for (let assignment in dataFromAssignment) {
        if (dataFromAssignment[assignment].checkbox) {
          this.$set(
            internalsPostData.activity_catagory_configs.assignments.resourses
              .exam,
            assignment,
            {
              id: assignment,
              persantage_of_wightage: dataFromAssignment[assignment].input,
            }
          );
        }
      }
      await assessment.assessmentData;
      let dataFromAssessment = assessment.assessmentData;
      for (let assessment in dataFromAssessment) {
        if (dataFromAssessment[assessment].checkbox) {
          this.$set(
            internalsPostData.activity_catagory_configs.exams.resourses.exam,
            assessment,
            {
              id: assessment,
              persantage_of_wightage: dataFromAssessment[assessment].input,
            }
          );
        }
      }

      console.log(
        "assignments",
        internalsPostData.activity_catagory_configs.assignments.resourses.exam
      );
      console.log(
        "exams",
        internalsPostData.activity_catagory_configs.exams.resourses.exam
      );

      await this.downloadInternalsPDF(internalsPostData, viewReport);
      this.loadData = false;
    },

    async downloadInternalsPDF(internalsPostData, viewReport) {
      this.downloadData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/continuousevaluation/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(url, internalsPostData, { responseType: "blob" })
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "InternalsList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.downloadData = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
          this.downloadData = false;
        });
    },
  },
};
</script>
