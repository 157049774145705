<template>
  <div>
    <div>
      <h4>Invite Students to {{ groupInfo.name }}</h4>
      <b-form @submit.prevent="sendInvitations()">
        <h6 class="alert alert-success text-center" v-if="invitationSuccess">
          Invitations Sent
        </h6>
        <b-alert v-if="smsCount < 1000" variant="danger" class="text-center" show>Invitations cannot be sent via SMS if
          the SMS count is low</b-alert>
        <!-- <b-alert variant="info" class="text-center text-danger" show>You can directly copy and paste students
          email/phone numbers
          from an excel column and click on add button, this
          will direct convert the data to comma separated value</b-alert> -->

        <!-- <b-form-group class="ml-4">
          <b-form-group label="Choose how invitation will sent:" v-slot="{ ariaDescribedby }">
            <b-form-radio v-model="invitationType" :aria-describedby="ariaDescribedby" name="some-radios"
              value="normal">Normal</b-form-radio>
            <b-form-radio v-model="invitationType" :aria-describedby="ariaDescribedby" name="some-radios"
              value="advanced">Advanced</b-form-radio>
          </b-form-group>
          <span v-if="invitationType == 'advanced'" class="text-danger">
            With Ezygo's latest invitation option, student profiles will be automatically created prior to their
            enrollment at Ezygo. This means that faculty members can mark attendance, add students to courses, update
            marks, and more, even before they officially sign up and enroll at the institution in Ezygo. This feature
            provides a seamless experience for both faculty and students, ensuring that everyone is ready to hit the
            ground running from day one.
          </span>
          <span v-if="invitationType == 'normal'" class="text-danger">
            Students can sign up and enroll in our institution using an invitation code.
            However, please note that faculty members can only mark attendance, add students to courses,
            and perform other related tasks after students have officially enrolled in our institution.
          </span>
        </b-form-group> -->
        <!-- invite only- legacy modes -->
        <b-form-group v-if="invitationType == 'normal'" class="ml-4">
          <b-form-tags input-id="tags-remove-on-delete"
            :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }" v-model="invitation.invitee_array"
            separator="'
             ,;'" placeholder="Enter email/91 + 10 digit mobile number separated by commas" remove-on-delete
            no-add-on-enter tag-pills tag-variant="primary" :class="{ 'is-invalid': responseErrors.invitee }"
            v-b-popover.hover.bottom="
              'john8@gmail.com,918765******,test@gmail.com, test2@gmail.com'
            "></b-form-tags>
        </b-form-group>
        <div v-if="invitationType == 'advanced'">

          <b-form-group class="ml-4">
            <b-form-group class="mb-0">

              <label for="" class="required-label">Enter mobile number(91+10 digit number) or email separated by comma.
                You may also copy and paste an entire excel column that contains email or mobile number:</label>
              <b-form-textarea v-model="emailMobileInputData" id="textarea-formatter" rows="3"
                placeholder="student11@gmail.com,918876543210,..."></b-form-textarea>
            </b-form-group>
            <br>
            <b-form-group class="mb-0">
              <label for="" class="required-label">Please enter the names of the students in the same order as the
                corresponding email/mobile number entered in the first field, and separate each name with a comma. This
                will ensure that the correct names are associated with the correct contact information. Instead, you may
                copy and paste an entire Excel column containing student names.
                :</label>
              <b-form-textarea v-model="studentsNameInputData" id="textarea-formatter" rows="3"
                placeholder="Student 1,Student 2, Student 3"></b-form-textarea>
            </b-form-group>
            <br>
            <b-form-group class="mb-0">
              <label for="" class="required-label">Please enter the Roll number of the students in the same order as the
                corresponding email/mobile number entered in the first field, and separate each name with a comma. This
                will ensure that the correct Roll numbers are associated with the correct contact information. Instead,
                you may
                copy and paste an entire Excel column containing student Roll numbers.
                :</label>
              <b-form-textarea v-model="studentsRollNumberInputData" id="textarea-formatter" rows="3"
                placeholder="1,2,3,..."></b-form-textarea>
            </b-form-group>
            <b-button variant="success" size="md" @click="updateTableFromInputData()">Proceed</b-button>
          </b-form-group>

          <b-form-group class="ml-4">
            <div v-if="(inviteesDatas.length > 0)">
              <table class="table  table-striped table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th>Roll No.</th>
                    <th>Email/Mobile</th>
                    <th>Student First Name</th>
                    <th>Last Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(inviteeData, reindex) in inviteesDatas" :key="reindex">
                    <td>{{ inviteeData.roll_number }}</td>
                    <td>{{ inviteeData.invitee }}</td>
                    <td>{{ inviteeData.first_name }}</td>
                    <td>{{ inviteeData.last_name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-button v-if="(inviteesDatas.length > 0)" variant="primary" type="submit" class="font-weight-bolder text-white"
              :disabled="(sendInvites.disabled || inviteesDatas.length < 1)">{{ sendInvites.text }}</b-button>
          </b-form-group>

        </div>
        <b-form-group class="ml-4">
          <span class="text-danger" v-if="failedinvitation != null">Invitation already accepted by {{ failedinvitation
          }}<br />
          </span>
          <span class="text-danger" v-if="responseErrors.invitee">{{
              responseErrors.invitee[0]
          }}</span>
        </b-form-group>

        <b-form-group class="float-right" v-if="invitation.invitee_array.length != 0">
          <b-button variant="primary" type="submit" class="font-weight-bolder text-white"
            :disabled="sendInvites.disabled">{{ sendInvites.text }}</b-button>
          <b-button variant="danger" @click="cancel()" class="ml-3 font-weight-bolder" :disabled="sendInvites.disabled">
            Cancel
          </b-button>
        </b-form-group>
        <b-form-group>
          <p style="font-size: 14px; font-weight: 600" :class="smsCount < 1000 ? 'text-danger' : 'text-dark'">
            Balance SMS Count:
            <span>{{ smsCount }}</span>
          </p>
        </b-form-group>
        <div>
          <p v-if="!invitedusers.show" @click="showInvitedUsersTable"
            style="color: #2929aa; text-decoration: underline; cursor: pointer;">
            Show invited users
          </p>
          <h3 v-if="invitedusers.show && invitedusers.show.lenth == 0">
            Loading...
          </h3>
          <table class="table table-striped table-bordered" v-if="invitedusers.show != 0">
            <thead style="font-size: 14px; font-weight: 600; text-align: center" class="thead-dark">
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Accepted</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, userIndex) in invitedusers.users" :key="userIndex">
                <td>{{ user.invitee }}</td>
                <td><span v-if="user.role_id == 2"></span>Student</td>
                <td>
                  <span v-if="user.accepted_by">✓✓</span><span v-else>
                    <b-button variant="info" size="md" @click="resentEnrolmentCode(user.id)">Resend</b-button>
                    <b-button variant="danger" size="md" @click="deleteInvitation(userIndex)">Delete</b-button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["groupInfo"],
  created() {
    this.smsCount = this.$store.getters.getSmsCount.smsBalance;
  },
  data() {
    return {
      studentsNameInputData: '',
      inviteesDatas: [],
      emailMobileInputData: '',
      studentsRollNumberInputData:'',
      invitationType: 'advanced', // 'normal', 'advanced'
      smsCount: null,
      newTag: "",
      subgroupInvite: false,
      invitation: {
        invitee_array: [],
        invitee: "",
        invite_to: "usersubgroup",
        invite_to_id: "",
        role_id: 2,
      },

      pendingUsers: [],
      sendInvites: {
        text: "Send Enrollment Code",
        disabled: false,
      },
      responseErrors: {},
      invitationSuccess: false,
      failedinvitation: null,
      invitedusers: {
        users: [],
        show: false,
      },
    };
  },
  methods: {
    async updateTableFromInputData() {
      this.inviteesDatas = [];
      let splitDataEmailMobile = await this.emailMobileInputData.replaceAll(' ', '').split(/[\n,]+/);
      let splitDataStudentsName = await this.studentsNameInputData.split(/[\n,]+/);
      let splitDataStudentsRollNumbers = await this.studentsRollNumberInputData.split(/[\n,]+/);
      if (this.emailMobileInputData == '' || this.studentsNameInputData == '' || splitDataEmailMobile.length != splitDataStudentsName.length) {
        this.$toast.error(`Please fill the columns /Number of Email/Mobile not matches number of Student name.`, {
          top: "top",
          duration: 3000,
        });
        return;
      }
      for (let index = 0; index < splitDataStudentsName.length; index++) {
        let names = splitDataStudentsName[index].trim().split(' ');
        let first_name = names[0];
        let last_name = splitDataStudentsName[index].substring(first_name.length + 1);
        this.inviteesDatas.push({
          invitee: splitDataEmailMobile[index], name: splitDataStudentsName[index],
          first_name: first_name, last_name: last_name,
          roll_number: splitDataStudentsRollNumbers[index] ?? null,
        });
      }

      this.sendInvites.text = "Send Enrolment Code";
      this.sendInvites.disabled = false;

    },
    async dataFromNotepad() {
      await this.newTag;
      let dataToProcess = await this.newTag;
      this.newTag = await "";
      let splitData = await dataToProcess.split(",");
      await splitData.forEach((data) => {
        this.invitation.invitee_array.push(data);
      });
    },
    avoidComma($event) {
      if ($event.charCode == 44) {
        return $event.preventDefault();
      }
    },
    resetInputValue() {
      this.newTag = "";
    },
    setTag(text) {
      this.newTag = text;
    },
    async sendInvitations() {
      this.sendInvites.text = "Sending...";
      this.sendInvites.disabled = true;
      let directEnrollment = {
        // invitee: "",
        // invitee_array: [],
        invite_to: "usersubgroup",
        invite_to_id: "",
        role_id: null,
        // invite_with_profiles:[]
      };

      if (this.invitationType == 'normal') {
        directEnrollment.invitee = this.invitation.invitee;
        directEnrollment.invitee_array = this.invitation.invitee_array;
      }
      if (this.invitationType == 'advanced') {
        directEnrollment.invite_with_profiles = this.inviteesDatas;
      }
      directEnrollment.invite_to_id = this.groupInfo.id;
      directEnrollment.role_id = this.invitation.role_id;

      const url = this.$store.getters.getAPIKey.userInvitations;
      axios
        .post(url, directEnrollment)
        .then((response) => {
          this.checkInvitees();
          this.studentsNameInputData = '';
          this.studentsRollNumberInputData = '';
          this.inviteesDatas = [];
          this.emailMobileInputData = '';
          this.invitation.invitee = "";
          this.invitation.invitee_array = [];
          this.invitation.invite_to = "usersubgroup";
          this.invitation.role_id = 2;
          this.sendInvites.text = "Send Enrolment Code";
          this.sendInvites.disabled = false;
          response;
          this.$toast.success(`Invitations Sent Successfully.`, {
            top: "top",
            duration: 3000,
          });
          setTimeout(() => {
            this.clearFields();
          }, 4000);
          // this.checkInvitees();
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          // this.responseErrors =
          this.failedinvitation = error.response.data.failed_invitee;
          // if (failedInvitee) {
          //   this.$toast.error(`Invitation already accepted by ${failedInvitee}.`, {
          //     top: "top",
          //     duration: 4000,
          //   });
          // }
          this.clearFields();
          error;
        });
    },

    async showInvitedUsersTable() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroups/${this.groupInfo.id}/invitees`;
      axios
        .get(url)
        .then((response) => {
          this.invitedusers.users = response.data;
          this.invitedusers.show = true;
        })
        .catch((error) => {
          error;
        });
    },
    async resentEnrolmentCode(id) {
      const url = `${this.$store.getters.getAPIKey.mainAPI}/userinvitations/${id}/resent`;
      axios
        .get(url)
        .then(() => {
          this.$toast.success(`Enrollment code sent successfully`, {
            top: "top",
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast.error(`Failed to sent enrollment code`, {
            top: "top",
            duration: 3000,
          });
        });
    },

    async deleteInvitation(index) {
      if (
        confirm(
          `WARNING: Invitation will be deleted only if invitation not accepted by a user. Automatically created Student/Faculty will not delete along with invitation, if the profile is included in any activity like attendance, schedules, ...`
        ) == false
      ) return
      let invitation = this.invitedusers.users[index];
      const url = `${this.$store.getters.getAPIKey.mainAPI}/userinvitations/${invitation.id}`;
      axios
        .delete(url)
        .then(() => {
          this.invitedusers.users.splice(index, 1);
          this.$toast.success(`Invitation deleted successfully`, {
            top: "top",
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast.error(`Failed to delete invitation`, {
            top: "top",
            duration: 3000,
          });
        });
    },
    async checkInvitees() {
      const url = this.$store.getters.getAPIKey.listInvitees;

      axios
        .get(url)
        .then((response) => {
          this.pendingUsers = response.data;
          response;
        })
        .catch((error) => {
          error;
        });
    },
    clearFields() {
      this.sendInvites.text = "Send Enrolment Code";

      setTimeout(() => {
        this.sendInvites.disabled = false;
        this.failedinvitation = null;
        this.responseErrors = {};
      }, 5000);
    },
    cancel() {
      this.invitation.invitee = "";
      this.responseErrors = {};
      this.$emit("cancel");
    },
  },
};
</script>
