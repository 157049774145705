<template>
  <div>

    <b-col sm="12">
      <h3 class="m-3" style="text-align: center; font-size:16px !important;">
        {{ markAttendanceFor.confirm_text }}
      </h3>
      <!-- progress  -->
      <b-row v-if="(pageLoadProgressCount < 9)" class="mb-2">
        <b-col>
          <b-progress height="3px" :value="((pageLoadProgressCount / 8) * 100)"></b-progress>
        </b-col>
      </b-row>
      <!-- attendance stote response message  -->
      <b-row v-if="slotResponse.message != ''">
        <b-col> <b-alert class=" text-center p-1" show variant="danger">{{ slotResponse.message }}</b-alert> </b-col>
      </b-row>
      <!-- existing attendances list  -->
      <b-row v-if="slotResponse.existingAttendances.length > 0">
        <b-col> <span v-for="existingAtt in slotResponse.existingAttendances" :key="existingAtt.id">
            <b-alert class=" text-center p-1" show variant="warning">
              Session {{ existingAtt.session }} for course {{ existingAtt.course }} marked by {{ existingAtt.marked_by
              }}
            </b-alert>
          </span> </b-col>
      </b-row>

      <!-- attendance table options and marking  -->
      <div class="container border-left border-right border-top border-success pb-4">
        <!-- attendace form options -->
        <div v-if="editAttendanceForm">
          <!-- previous sessoin historu copy  -->
          <b-row :class="'m-2 mb-2 pb-2 border-bottom border-info'"
            v-if="(Object.keys(previousSessionHistory).length > 0)">
            <b-col> Attendance from
              previous
              session <b-button @click="copyPreviousSessionHistory" :variant="success"
                class="small-button">Copy</b-button>
            </b-col></b-row>
          <!-- course groups buttons  -->
          <b-row :class="'m-2 mb-2 pb-2 border-bottom border-info'" v-if="(courseStudentGroups.length > 0)"> <b-col
              cols="2">
              Groups: </b-col>
            <b-col>
              <b-button v-for="grp in courseStudentGroups" :key="grp.id"
                :class="['small-button', (selectedCourseStudGroupIds.includes(grp.id) ? 'bg-success' : 'text-primary bg-white')]"
                @click="restrictStudentByCourseGroup(grp)">{{ grp.name }}</b-button>
            </b-col>
          </b-row>
          <!-- att type all select buttons  -->
          <b-row :class="'m-2 '" v-if="(headerAttTypes.length > 0)">
            <b-col cols="2">Mark : </b-col>
            <b-col>
              <b-button v-for="attType in headerAttTypes" :key="attType.id" class="small-button"
                :variant="attType.name == 'Present' ? 'success' : (attType.name == 'Absent' ? 'danger' : 'warning')"
                @click="markAllStudentSameAttType(attType.id)">All {{
                    attType.name
                }} </b-button>
              <b-button class="small-button text-primary bg-white"
                @click="markAllStudentSameAttType('')">Clear</b-button>
              <span @click="(minimalOption = !minimalOption)" style="cursor: pointer;">Show {{ minimalOption ? 'more' :
                  'less'
              }}..</span>
            </b-col>
          </b-row>

          <!-- mark by roll number  -->
          <b-row :class="'m-2 pt-2 border-top border-info'" v-if="(availableRollNumbers.length > 0)">
            <b-col cols="4">
              <b-form-checkbox v-model="rollnumberPrefix.enable" size="md">Roll number prefixes
                <span class="d-inline-block" v-b-tooltip.hover
                  title="If roll numbers are like, ECU18A 01, ECU18A 02, ECU18A 03... then enter prefix 'ECU18A ' , then you can enter roll number like 01, 02, 03...">
                  <i style="font-size: 18px !important; color: #3c6f39fc;" class="fas fa-info-circle  fa-xm"></i>
                </span>
              </b-form-checkbox>
              <div class="w-50 d-flex" v-if="rollnumberPrefix.enable">
                <b-form-input placeholder="KTUTST" style="width: 100px" v-model="rollnumberPrefix.text"></b-form-input>
              </div>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input autocomplete="off" v-model="attendanceMarkByRollNumberText" :placeholder="
                  `Enter Absentees Roll No's separated by commas or Use roll number range. Example: 1-10 `
                " @keydown.,="markAttendanceByRollNumber" @keypress="avoidComma"></b-form-input>
              </b-input-group>
              <div v-if="(absentStudByRollNumber.length > 0)">
                <b-form-tag :id="('absenteeRollNumberTag' + roll)" v-for="(roll, tagIndex) in absentStudByRollNumber"
                  :key="tagIndex" class="mr-1" variant="primary" @remove="changeAttendanceByRollNumber(tagIndex, '')">{{
                      roll
                  }}
                  <b-popover :target="('absenteeRollNumberTag' + roll)" triggers="hover" placement="top">
                    <b-row class="mx-1">
                      <div v-for="attType in headerAttTypes" :key="attType.id"
                        class="att-type-box unselected-att-type-box">
                        <div @click="changeAttendanceByRollNumber(tagIndex, attType.id)"> {{ attType.code }}</div>
                      </div>
                    </b-row>
                  </b-popover>
                </b-form-tag>
              </div>
              <b-form-text v-else>
                No roll numbers added yet!
              </b-form-text>
            </b-col>
          </b-row>
        </div>
        <!-- attendace form table  -->
        <b-row class="table-container ">
          <b-col :class="{ 'd-flex justify-content-end': cstudIndex == 0 }"
            v-for="(courseStudents, cstudIndex) in halfOfCourseStudents" :key="cstudIndex">
            <table class="table table-bordered black-border " style="width: auto !important;">
              <thead style="position: sticky;top: 0;">
                <!-- headerAttTypes column with minimal option -->
                <tr>
                  <th class="text-center text-white bg-dark">Roll No</th>
                  <th class="text-center text-white bg-dark">Name</th>
                  <th v-for="attType in headerAttTypes" :key="attType.id" style="width: 25px; "
                    class="text-center text-white bg-dark">
                    {{ attType.name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- course students row   -->
                <!-- headerAttTypes column -->
                <tr :class="{
                  'restricted_student': slotResponse.restrictedStudentsIds
                    .includes(stud.id), 'disabled_student': stud.disabled
                }" v-for="(stud, studIndex) in courseStudents" :key="studIndex">
                  <td>{{ stud.roll_number }}</td>
                  <td>{{ stud.name }}</td>

                  <td v-for="attType in headerAttTypes" :key="attType.id">
                    <div @click="studAttTypeSelect(stud, attType.id)" :class="{
                      'att-type-box': true,
                      'selected-att-type-red-box': (studentAttendanceTypeData[stud.id] ==
                        attType.id && (!stud.disabled) && (!reportPositiveAttType(attType.id))),
                      'selected-att-type-green-box': (studentAttendanceTypeData[stud.id] ==
                        attType.id && (!stud.disabled) && (reportPositiveAttType(attType.id))),
                      'unselected-att-type-box': (studentAttendanceTypeData[stud.id] !=
                        attType.id || (stud.disabled))
                    }"> {{ attType.code }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <!-- attendace form options Sms Alert-->
        <div v-if="editAttendanceForm" class="border-top border-bottom border-info">
          <!-- SMS aler buttons  -->
          <b-row :class="'m-2'">
            <b-col cols="2">SMS alert:</b-col>
            <b-col>
              <b-form-checkbox :disabled="slotResponse.adminSettingAttAlertParent"
                v-b-popover.hover.top="slotResponse.adminSettingAttAlertParent ? 'The SMS Alert option is enabled by the admin, so faculties will not be able to disable it.' : ''"
                v-model="parentSmsAlert" name="checkbox-1">
                Send absenteeism SMS alert to guardians
              </b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox :disabled="slotResponse.adminSettingAttAlertStudent"
                v-b-popover.hover.top="slotResponse.adminSettingAttAlertStudent ? 'The SMS Alert option is enabled by the admin, so faculties will not be able to disable it.' : ''"
                v-model="studentSmsAlert" name="checkbox-1">
                Send absenteeism SMS alert to students
              </b-form-checkbox>
            </b-col>
            <b-col>
              <p style="font-size: 14px; font-weight: 600" :class="smsCount < 1000 ? 'text-danger' : 'text-dark'">
                Balance SMS Count:
                <span>{{ smsCount }}</span>
              </p>
            </b-col>
          </b-row>

        </div>
      </div>

      <!-- Add Lesson plans, Class type, Delivery method to Attendance  -->
      <div class="container border-left border-right border-bottom border-info pt-2"
        v-if="(Object.keys(slotResponse.sessionAttendanceAttachments).length > 0)">
        <h4 style="text-align: center;"> <span v-if="!viewAttendanceAttachments"> <b-button
              @click="(viewAttendanceAttachments = !viewAttendanceAttachments)" variant="primary"
              size="sm">Click</b-button> to </span>
          Add
          Lesson plans, Class type, Delivery method to Attendance</h4>
        <b-collapse v-model="(viewAttendanceAttachments)" id="attedance-attachments-collapse">
          <b-form-group v-if="(markAttendanceFor.sessions.length > 1)">
            <b-form-checkbox v-model="commonLessonForAllSession" name="common-lesson-1">
              Attach Same Topic and Delivery for every session.
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-link @click="$bvModal.show('newLessonPlanModel')">
              Didn't find your topic on the list? Click here to add a new topic.
            </b-link>
            <b-modal id="newLessonPlanModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-header-close
              hide-footer hide-header centered size="md">
              <addLessonPlanModelOnNewAttMarking
                @success="getAttendanceDateAdditionals(); $bvModal.hide('newLessonPlanModel')"
                @cancel="$bvModal.hide('newLessonPlanModel')" :courseId="markAttendanceFor.course_id"
                :date="markAttendanceFor.date" :siNo="lessonPlans.length"
                :sessionCount="markAttendanceFor.sessions.length" />
            </b-modal>

          </b-form-group>
          <b-col>
            <div class="border-bottom border-secondary p-3"
              v-for="sessionId in (commonLessonForAllSession ? [markAttendanceFor.sessions[0]] : markAttendanceFor.sessions)"
              :key="sessionId">
              <h4 class="text-center" v-if="(sessionObjects[sessionId] && !commonLessonForAllSession)">Add for session:
                {{
                    sessionObjects[sessionId].name
                }}</h4>
              <b-form-group label="Lesson Plans:">
                <multiselect v-model="slotResponse.sessionAttendanceAttachments[sessionId].lessonplans"
                  tag-placeholder="Add this as new tag" placeholder="Search Lesson plan" label="lesson" track-by="id"
                  :options="lessonPlans" :close-on-select="false" :multiple="true" :searchable="true"
                  :internal-search="true" :taggable="true">
                </multiselect>
              </b-form-group>
              <b-form-group label="Delivery methods:">
                <b-form-checkbox-group v-model="slotResponse.sessionAttendanceAttachments[sessionId].deliverymethods"
                  :options="courseDeliveryMethods" :name="('deliverymethod-1' + sessionId)"></b-form-checkbox-group>
              </b-form-group>
              <b-form-group label="Class Type:">
                <b-form-radio-group v-model="slotResponse.sessionAttendanceAttachments[sessionId].class_type"
                  :name="('classtype-radios' + sessionId)" :options="classTypes"></b-form-radio-group>
              </b-form-group>
            </div>
          </b-col>
        </b-collapse>
      </div>

      <!--attendacne form control buttons  -->
      <b-container>
        <b-row :class="'m-2'" v-if="editAttendanceForm">
          <b-col> <b-button class="sm" :variant="'danger'" @click="$emit('cancelAttendanceMarkForm')">Cancel</b-button>
          </b-col>
          <b-col class="sm text-right"> <b-button class="sm" :variant="'success'"
              @click="viewConfirmAttendanceSummary">Proceed</b-button>
          </b-col>
        </b-row>
      </b-container>

      <!-- confirm attendance  -->
      <div class="container border border-warning mt-4" v-if="enableConfirmAttendance">
        <h4 style="text-align: center;">Attendance Summary</h4>
        <div style="text-align: center;" v-if="(confirmAttMarkedStudents < confirmAttTotalStudents)">
          <span>You missed to mark </span>
          <strong style="font-size: 20px !important; color:red"> {{ (confirmAttTotalStudents - confirmAttMarkedStudents)
          }}/
            {{ confirmAttTotalStudents }}</strong>
          <span> students</span>
        </div>
        <!-- create globalgroup with att selected students for course model -->
        <b-row>
          <b-col>
            <div style="text-align: center;" v-if="(confirmAttTotalStudents != confirmAttMarkedStudents)"
              class="blink_me">
              Create <span @click="createCourseGlobalgroup"
                style="color: #2929aa; text-decoration: underline; cursor: pointer;">Student groups</span>
              to make group-wise attendance marking easier
            </div>

            <b-modal id="createCourseglobalgroupModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
              hide-footer centered size="lg" lazy>
              <coursestudentsgrouping-dash lazy :courseId="markAttendanceFor.course_id"
                :create_with_user_ids_array="createCourseGroupWithStudentIds"
                :create_with_summary="'For marking attendance'"
                :create_with_name="'Attendance group 1'"></coursestudentsgrouping-dash>
            </b-modal>
          </b-col>
        </b-row>

        You have marked attendance for {{ confirmAttMarkedStudents }}/{{ confirmAttTotalStudents }}
        <ul>
          <span v-for="attType in attendanceTypes" :key="attType.id">
            <li v-if="(attTypesMarkedCountSummary[attType.id].count > 0)" style="border: none;">
              {{ attType.name }}({{ attType.code }}) : {{ attTypesMarkedCountSummary[attType.id].count }}
            </li>
          </span>
        </ul>
        <!-- att type limit warnings -->
        <b-row :class="'m-2'" v-if="(attendanceTypeLimitsDatas.length > 0)">
          <table class="table table-bordered black-border  table-striped">
            <thead style="position: sticky;top: 0;">
              <tr>
                <th class="text-center text-white bg-dark">Name</th>
                <th class="text-center text-white bg-dark">Count</th>
                <th class="text-center text-white bg-dark">Limit</th>
                <th class="text-center text-white bg-dark">%</th>
              </tr>
            </thead>
            <tbody>
              <tr :class="{ 'bg-danger': (studData.percentage <= studData.limit) }"
                v-for="(studData, studIndex) in attendanceTypeLimitsDatas" :key="studIndex">
                <td>{{ studData.name }}</td>
                <td>{{ studData.count }}/{{ studData.max_count }}</td>
                <td>{{ studData.attTypeName }}-{{ studData.limit }}</td>
                <td>{{ studData.percentage }}</td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <!--confirm att control buttons  -->
        <b-row :class="'m-2'">
          <b-col> <b-button class="sm" :variant="'warning'"
              @click="enableConfirmAttendance = false; editAttendanceForm = true;">Edit attendance</b-button>
          </b-col>
          <b-col class="text-right"> <b-button class="sm" :variant="'success'"
              @click="confirmAttendanceSummaryAndSubmit()">Submit & Finish
              Attendance Marking</b-button>
          </b-col>
        </b-row>

      </div>


    </b-col>

  </div>
</template>

<script>
// import moment from "moment";
import addLessonPlanModelOnNewAttMarking from './addLessonPlanModelOnNewAttMarking.vue'
import CoursestudentsgroupingDash from "../Course/students_grouping/studentsgrouping.vue";
export default {
  props: ["dataToMarkAttendanceFor"],
  async created() {

    this.smsCount = this.$store.getters.getSmsCount.smsBalance;
    // set date seeion , course and view test from this.markAttendanceFor
    this.markAttendanceFor.sessions = this.dataToMarkAttendanceFor.sessions;
    if (this.markAttendanceFor.sessions.length == 1) this.commonLessonForAllSession = true;
    this.markAttendanceFor.date = this.dataToMarkAttendanceFor.date;
    this.markAttendanceFor.course_id = this.dataToMarkAttendanceFor.course_id;
    this.markAttendanceFor.confirm_text = this.dataToMarkAttendanceFor.confirm_text;
    await this.prepareAttendanceForm();
    if (!this.slotResponse.error) {
      await this.getAttendanceDateAdditionals();
      this.getSessions();
    }
  },
  components: {
    addLessonPlanModelOnNewAttMarking,
    CoursestudentsgroupingDash,
  },
  data() {
    return {
      smsCount: 0,
      markAttendanceFor: {
        sessions: [], date: '', course_id: '', confirm_text: ''
      },
      minimalOption: true,
      studentSmsAlert: false,
      parentSmsAlert: false,
      slotResponse: {
        alreadyMarked: false,
        error: false,
        message: '',
        studentAttHistory: {}, // att already marked for same course
        adminSettingAttAlertStudent: false,
        adminSettingAttAlertParent: false,
        existingAttendances: [], // already marked atts for date,sessions
        restrictedStudentsIds: [], // restrict stud ids that already marked for other course
        sessionAttendanceAttachments: [], // [sessionId:{ lessonplans: [], deliverymethods: [],  class_type: null, },...]
      },
      attendanceTypes: [],
      courseStudents: [],
      studentAttendanceTypeData: {},

      // attSlotLoaded: false,
      // initializePage: true,
      editAttendanceForm: true,
      enableConfirmAttendance: false,

      previousSessionHistory: {},
      courseStudentGroups: [],
      selectedCourseStudGroupIds: [],

      confirmAttTotalStudents: 0,
      confirmAttMarkedStudents: 0,
      attTypesMarkedCountSummary: {},

      attendanceTypeLimitsDatas: {},

      classTypes: [],
      lessonPlans: [],
      courseDeliveryMethods: [],
      viewAttendanceAttachments: false,
      createNewTopic: false,
      commonLessonForAllSession: false,

      availableRollNumbers: [],
      rollnumberPrefix: { enable: false, text: '' },
      attendanceMarkByRollNumberText: '',
      absentStudByRollNumber: [], // {id, name},...
      absentAttTypeId: '', // for mark by roll number

      sessionObjects: {},
      pageLoadProgressCount: 1, // totel api count after load 9

      createCourseGroupWithStudentIds: [],
    };
  },
  computed: {
    halfOfCourseStudents() {
      const middleIndex = Math.ceil(this.courseStudents.length / 2);
      const firstHalf = this.courseStudents.slice(0, middleIndex);
      const secondHalf = this.courseStudents.slice(middleIndex);

      return [firstHalf, secondHalf];
    },
    headerAttTypes() {
      this.minimalOption;
      return this.attendanceTypes.filter((type) => {
        return this.minimalOption ? ((type.name == 'Present' ||
          type.name == 'Absent')) : true;
      });
    },
  },
  methods: {
    changeAttendanceByRollNumber(index, attTypeId) {
      // when remove change present to roll number s that are not in the list
      let rollNumber = this.absentStudByRollNumber[index];
      if (this.absentAttTypeId != '' && attTypeId == this.absentAttTypeId) return;
      this.absentStudByRollNumber.splice(index, 1)
      this.courseStudents.forEach(stud => {
        if (!stud.disabled) {
          if (stud.roll_number == rollNumber || Number(stud.roll_number) == rollNumber)
            this.studentAttendanceTypeData[stud.id] = attTypeId;
        }
      });
    },
    async markAttendanceByRollNumber() {
      // check roll number range
      if (this.attendanceMarkByRollNumberText.match(/([0-9])+([-])+([0-9])/g)) {
        let getRollNumberRange = await this.attendanceMarkByRollNumberText.split("-");
        let startRollNumber = Number(getRollNumberRange[0]);
        let endRollNumber =
          Number(getRollNumberRange[1]);
        if (startRollNumber > endRollNumber) {
          this.$toast.error("Please check the roll number range", {
            position: "top",
            duration: 4000,
          });
          return;
        }
        for (startRollNumber; startRollNumber <= endRollNumber; startRollNumber++
        ) {
          if (this.rollnumberPrefix.enable) {
            if (this.availableRollNumbers.includes(this.rollnumberPrefix.text + startRollNumber))
              if (!this.absentStudByRollNumber.includes(this.rollnumberPrefix.text + startRollNumber))
                this.absentStudByRollNumber.push(this.rollnumberPrefix.text + startRollNumber);
          } else {
            if (this.availableRollNumbers.includes(`${startRollNumber}`))
              if (!this.absentStudByRollNumber.includes(`${startRollNumber}`))
                this.absentStudByRollNumber.push(`${startRollNumber}`);
          }
        }

      } else if (this.attendanceMarkByRollNumberText != '') {
        let rollNumber = '';
        if (this.rollnumberPrefix.enable) {
          rollNumber = this.rollnumberPrefix.text + this.attendanceMarkByRollNumberText;
        } else {
          rollNumber = this.attendanceMarkByRollNumberText;
        }
        if (this.availableRollNumbers.includes(rollNumber))
          if (!this.absentStudByRollNumber.includes(rollNumber))
            this.absentStudByRollNumber.push(rollNumber);
      } else { null; }

      this.markAttendancefromAbsentStudrollNumber(false);
      this.attendanceMarkByRollNumberText = '';
    },
    markAttendancefromAbsentStudrollNumber(elseEmpty) {
      this.courseStudents.forEach(stud => {
        if (!stud.disabled) {
          if (this.absentStudByRollNumber.includes(stud.roll_number)) {
            this.studentAttendanceTypeData[stud.id] = this.absentAttTypeId;
          } else if (this.absentStudByRollNumber.includes(Number(stud.roll_number))) {
            this.studentAttendanceTypeData[stud.id] = this.absentAttTypeId;
          } else {
            if (elseEmpty) this.studentAttendanceTypeData[stud.id] = ''
          }
        }
      });
    },
    async getAttendanceDateAdditionals() {
      this.classTypes = [];
      this.lessonPlans = [];
      this.courseDeliveryMethods = [];
      const url = `${this.$store.getters.getAPIKey.mainAPI}/attendancedates/getadditionals`;
      let respData = null;
      await this.$axios
        .post(url, {
          course: this.markAttendanceFor.course_id,
        })
        .then((response) => {
          respData = response.data;
        })
        .catch((error) => {
          error;
        });
      this.classTypes.push({
        value: null, text: 'Regular'
      });
      respData.classTypes.forEach(classType => {
        this.classTypes.push({
          value: classType.id, text: classType.name
        });
      });
      respData.lessonPlans.forEach(lessonPlan => {
        this.lessonPlans.push({
          id: lessonPlan.id, lesson: lessonPlan.lesson
        });
      });
      respData.courseDeliveryMethods.forEach(method => {
        this.courseDeliveryMethods.push({
          value: method.id, text: method.name
        });
      });
      this.pageLoadProgressCount++;
    },
    async confirmAttendanceSummaryAndSubmit() {
      this.enableConfirmAttendance = false;
      let studAttTypes = {};
      await this.courseStudents.forEach(stud => {
        if (!stud.disabled) {
          studAttTypes[stud.id] = this.studentAttendanceTypeData[stud.id];
        }
      });
      let sessionAttendanceAttachments = {};
      let firstSessionAttachments = null;
      for (const sessionId in this.slotResponse.sessionAttendanceAttachments) {
        let sessionAttachments = this.slotResponse.sessionAttendanceAttachments[sessionId];
        if (!firstSessionAttachments) firstSessionAttachments = sessionAttachments;
        if (this.commonLessonForAllSession) sessionAttachments = firstSessionAttachments;
        sessionAttendanceAttachments[sessionId] = {
          classTypeId: sessionAttachments.class_type,
          courseDeliveryMethodIds: sessionAttachments.deliverymethods,
          lessonPlanIds: sessionAttachments.lessonplans.map(obj => obj.id)
        };
      }

      const url = `${this.$store.getters.getAPIKey.mainAPI}/attendances/mark`;
      await this.$axios
        .post(url, {
          date: this.markAttendanceFor.date,
          sessions: this.markAttendanceFor.sessions,
          course: this.markAttendanceFor.course_id,
          studentAttendanceTypes: studAttTypes,
          studentSmsAlert: this.studentSmsAlert,
          parentSmsAlert: this.parentSmsAlert,
          sessionAttendanceAttachments: sessionAttendanceAttachments,
        })
        .then(() => {
          this.$toast.success('Attendance marked successfully', {
            position: "top",
            duration: 3000,
          });

          this.$emit('successAttendanceMarkForm');
        })
        .catch((error) => {
          let errorText = 'Failed to mark attendance, please try again';
          if (error.response) if (error.response.status == 422) {
            errorText = error.response.data.message;
          }

          this.$toast.success(errorText, {
            position: "top",
            duration: 7000,
          });
        });

    },
    viewConfirmAttendanceSummary() {
      this.enableConfirmAttendance = false;
      this.viewAttendanceAttachments = false;
      this.fetchAttendanceTypeLimits();

      this.confirmAttTotalStudents = 0;
      this.confirmAttMarkedStudents = 0;
      this.attTypesMarkedCountSummary = {};
      this.attendanceTypes.forEach(attType => {
        this.attTypesMarkedCountSummary[attType.id] = {
          count: 0, name: attType.name,
        };
      });

      this.courseStudents.forEach(stud => {
        this.confirmAttTotalStudents++;
        if (!stud.disabled) {
          let studAttTypeId = this.studentAttendanceTypeData[stud.id];
          if (studAttTypeId != '') {
            this.confirmAttMarkedStudents++;
            this.attTypesMarkedCountSummary[studAttTypeId].count++;
          }
        }
      });
      this.editAttendanceForm = false;
      this.enableConfirmAttendance = true;
    },
    async fetchAttendanceTypeLimits() {
      this.attendanceTypeLimitsDatas = {};
      let studAttTypes = {};
      await this.courseStudents.forEach(stud => {
        if (!stud.disabled) {
          studAttTypes[stud.id] = this.studentAttendanceTypeData[stud.id];
        }
      });
      let respData = {};
      const url = `${this.$store.getters.getAPIKey.mainAPI}/attendancereports/markattendance/atttypelimit/check`;
      await this.$axios
        .post(url, {
          date: this.markAttendanceFor.date,
          sessions: this.markAttendanceFor.sessions,
          course: this.markAttendanceFor.course_id,
          studentAttendanceTypes: studAttTypes,
        })
        .then((response) => {
          respData = response.data;
        })
        .catch((error) => {
          error;
        });
      if (respData.dataAvilable) {
        this.attendanceTypeLimitsDatas = respData.studLimittedAttTypeData;
      }
      if (respData.message != '') {
        this.$toast.warning(respData.message, {
          position: "top",
          duration: 6000,
        });
      }
    },
    markAllStudentSameAttType(attTypeId) {
      this.courseStudents.forEach(stud => {
        if (!stud.disabled) {
          this.studentAttendanceTypeData[stud.id] = attTypeId;
        }
      });
      this.markAttendancefromAbsentStudrollNumber(false);
    },
    restrictStudentByCourseGroup(courseGroup) {
      if (this.selectedCourseStudGroupIds.includes(courseGroup.id)) {
        this.selectedCourseStudGroupIds.splice(this.selectedCourseStudGroupIds.indexOf(courseGroup.id), 1);
      } else {
        this.selectedCourseStudGroupIds.push(courseGroup.id);
      }

      let selectedGroupStudIds = [];
      this.courseStudentGroups.forEach(grp => {
        if (this.selectedCourseStudGroupIds.includes(grp.id)) {
          selectedGroupStudIds = selectedGroupStudIds.concat(grp.studentsIds);
        }
      });

      this.courseStudents.forEach(stud => {
        stud.disabled = false;
        if (this.slotResponse.restrictedStudentsIds.includes(stud.id)) stud.disabled = true;
        if (selectedGroupStudIds.length > 0) {
          stud.disabled = selectedGroupStudIds.includes(stud.id) ? false : true;
        }
      });
    },
    copyPreviousSessionHistory() {
      this.minimalOption = false;
      let count = 0;
      this.courseStudents.forEach(stud => {
        if (!stud.disabled) {
          if (stud.id in this.previousSessionHistory) {
            count++;
            this.studentAttendanceTypeData[stud.id] = this.previousSessionHistory[stud.id];
          }
        }
      });
      this.previousSessionHistory = {};
      this.$toast.success(`Attendance of '${count}' students copied`, {
        position: "top",
        duration: 5000,
      });
      this.pageLoadProgressCount++;
    },
    studAttTypeSelect(student, attTypeId) {
      if (!this.editAttendanceForm) return;
      if (student.disabled) return;
      let isClearAttType = this.studentAttendanceTypeData[student.id] == attTypeId ? true : false;
      this.studentAttendanceTypeData[student.id] = this.studentAttendanceTypeData[student.id] == attTypeId ? '' : attTypeId;
      // if absent then push yo absent roll numbers 
      if (student.roll_number != '' && student.roll_number != null && this.absentAttTypeId != '') {
        if (this.absentAttTypeId == attTypeId && !isClearAttType) {
          if (!this.absentStudByRollNumber.includes(student.roll_number)) this.absentStudByRollNumber.push(student.roll_number);
        } else {
          if (this.absentStudByRollNumber.includes(student.roll_number)) this.absentStudByRollNumber.splice(this.absentStudByRollNumber.indexOf(student.roll_number), 1);
        }

      }
      // this.$forceUpdate();
    },
    async prepareAttendanceForm() {
      // load attendance search and avilables data
      await this.checkAttendanceSlots();
      if (this.slotResponse.error) {
        this.pageLoadProgressCount += 9;  // add count to finish load //++++++++++++++++++++++++++
        // this.attSlotLoaded = true;
        return;
      }
      // load students 
      // load att types
      await this.fetchCourseStudents();
      await this.fetchAttendanceTypes();
      // this.attSlotLoaded = true;

      // load course groups
      // previous session history
      // load lessonplan ,types...
      if (!this.slotResponse.alreadyMarked) {
        await this.fetchPreviousSessionHistory();
      } else {
        this.pageLoadProgressCount++; // increase incase fetchPreviousSessionHistory not used
        this.viewAttendanceAttachments = true;
      }
      await this.fetchCourseStudentGroups(); // lazy load group

      this.pageLoadProgressCount++;
      // this.initializePage = false;
    },

    async fetchCourseStudents() {
      this.courseStudents = [];
      const url = `${this.$store.getters.getAPIKey.mainAPI}/courseusersindex?role=2&course=${this.markAttendanceFor.course_id}`;
      let students = [];
      await this.$axios
        .get(url)
        .then((response) => {
          students = response.data;
        })
        .catch((error) => {
          error;
        });

      students.forEach(student => {
        if (student.roll_number != null && student.roll_number != '') this.availableRollNumbers.push(student.roll_number);
        this.courseStudents.push({
          id: student.institution_user_id,
          name: `${student.first_name} ${student.last_name}`,
          roll_number: student.roll_number,
          disabled: this.slotResponse.restrictedStudentsIds
            .includes(student.institution_user_id)
            ? true
            : false
        });
        // prepare studId: attType table data array
        this.$set(this.studentAttendanceTypeData, student.institution_user_id, '');
        // this.studentAttendanceTypeData[student.institution_user_id] = '';
        if (this.slotResponse.alreadyMarked == true &&
          (student.institution_user_id in this.slotResponse.studentAttHistory)) {
          if (this.slotResponse.studentAttHistory[student['institution_user_id']] !=
            null) {
            this.studentAttendanceTypeData[student.institution_user_id] =
              this.slotResponse.studentAttHistory[student['institution_user_id']];
          }
        }
      });
      this.pageLoadProgressCount++;
    },

    async fetchAttendanceTypes() {
      this.attendanceTypes = [];
      const url = `${this.$store.getters.getAPIKey.mainAPI}/attendancetypes`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.attendanceTypes = response.data;
          this.attendanceTypes.forEach(attType => {
            if (attType.name == 'Absent' || attType.name == 'ABSENT') this.absentAttTypeId = attType.id;
          });
        })
        .catch((error) => {
          error;
        });
      // push absentees roll number to 'mark absentees y roll no' variable
      this.courseStudents.forEach(student => {
        if (this.absentAttTypeId != '' && this.studentAttendanceTypeData[student.id] == this.absentAttTypeId && student.roll_number != null)
          this.absentStudByRollNumber.push(student.roll_number);
      });
      this.pageLoadProgressCount++;
    },
    async checkAttendanceSlots() {
      const url = `${this.$store.getters.getAPIKey.mainAPI}/attendances/checkslot`;
      await this.$axios
        .post(url, {
          date: this.markAttendanceFor.date,
          sessions: this.markAttendanceFor.sessions,
          course: this.markAttendanceFor.course_id
        })
        .then((response) => {
          let respData = response.data;
          this.slotResponse.alreadyMarked = respData.alreadyMarked;
          if (this.slotResponse.alreadyMarked) this.minimalOption = false;
          this.slotResponse.error = respData.error;
          this.slotResponse.message = respData.message;
          this.slotResponse.studentAttHistory = respData.studentAttHistory; // empty array or stud:att type object

          if (respData.attendance_alert != null) { // att sms alert preference from admin
            if (respData.attendance_alert.absentees_on_marking_to_student) {
              this.slotResponse.adminSettingAttAlertStudent = true;
              this.studentSmsAlert = true;
            } if (respData.attendance_alert.absentees_on_marking_to_parent) {
              this.slotResponse.adminSettingAttAlertParent = true;
              this.parentSmsAlert = true;
            }
          }
          this.slotResponse.existingAttendances = respData.existingAttendances;
          this.slotResponse.restrictedStudentsIds = respData.restrictedStudentsIds;
          this.slotResponse.sessionAttendanceAttachments = respData.sessionAttendanceAttachments;

        })
        .catch((error) => {
          error;
          this.slotResponse.error = true;
        });
      this.pageLoadProgressCount++;
    },
    async fetchPreviousSessionHistory() {
      this.previousSessionHistory = {};
      const url = `${this.$store.getters.getAPIKey.mainAPI}/attendancedates/previoussession/history`;
      await this.$axios
        .post(url, {
          date: this.markAttendanceFor.date,
          sessions: this.markAttendanceFor.sessions,
          course: this.markAttendanceFor.course_id
        })
        .then((response) => {
          if (!response.data.error) this.previousSessionHistory = response.data.previousSession_students_attType;
        })
        .catch((error) => {
          error;
        });
      this.pageLoadProgressCount++;
    },
    async fetchCourseStudentGroups() {
      const url = `${this.$store.getters.getAPIKey.mainAPI}/globalgroups?model_type=Course&model_id=${this.markAttendanceFor.course_id}&with_users=1`;
      await this.$axios
        .get(url)
        .then((response) => {
          let courseStudentGroups = response.data;
          courseStudentGroups.forEach(grp => {
            let studIds = [];
            grp.users.forEach(user => {
              studIds.push(user.id);
            });
            this.courseStudentGroups.push({
              id: grp.id,
              name: grp.name,
              studentsIds: studIds
            });
          });
        })
        .catch((error) => {
          error;
        });
      this.pageLoadProgressCount++;
      // console.log(this.courseStudentGroups);
    },
    // setSmsAlert(type) {
    //   if (type == 'student') {
    //     this.studentSmsAlert = this.slotResponse.adminSettingAttAlertStudent ?
    //       this.$toast.error("Attendance alert settings are enabled/controlled by institute admin") : !this.studentSmsAlert;
    //   } else {
    //     this.parentSmsAlert = this.slotResponse.adminSettingAttAlertParent ?
    //       this.$toast.error("Attendance alert settings are enabled/controlled by institute admin") : !this.parentSmsAlert;
    //   }
    // },
    avoidComma($event) {
      if (
        !/[a-zA-Z0-9 ]/.test($event.key) &&
        $event.key !== "." &&
        $event.key !== "-"
      )
        return $event.preventDefault();
    },

    reportPositiveAttType(attTypeId) {
      let positive = null;
      this.attendanceTypes.forEach(attType => {
        if (attType.id == attTypeId) positive = attType.positive_report_value == 1 ? true : false;
      });
      return positive;
    },
    async getSessions() {
      const url = this.$store.getters.getAPIKey.createSessions;
      await this.$axios
        .get(url)
        .then((response) => {
          let sessions = response.data;
          sessions.forEach((session) => {
            this.sessionObjects[session.id] = session;
          });
          this.$forceUpdate();
        })
        .catch((error) => {
          error;
        });
      this.pageLoadProgressCount++;
    },
    createCourseGlobalgroup() {
      this.createCourseGroupWithStudentIds = [];

      this.courseStudents.forEach(stud => {
        if (!stud.disabled) {
          let studAttTypeId = this.studentAttendanceTypeData[stud.id];
          if (studAttTypeId != '') {
            this.createCourseGroupWithStudentIds.push(stud.id);
          }
        }
      });

      this.$bvModal.show('createCourseglobalgroupModel');
    },
  },
};
</script>

<style >
.small-button {
  margin-right: 8px;
  min-width: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 6px !important;
  padding: 1px;
}

.disabled_student {
  background-color: #ffa2a2;
}

.restricted_student {
  color: #851b1b
}

.att-type-box {
  height: 20px;
  min-width: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  padding: 2px;
}

.selected-att-type-green-box {
  background-color: #10dc10;
}

.selected-att-type-red-box {
  background-color: #e02424;
}

.unselected-att-type-box {
  background-color: #d3d3d3;
}

.table-container {
  max-height: 90vh !important;
  overflow-y: auto !important;
  width: auto;
}


.black-border th,
.black-border td,
.black-border tr {
  border: 1px solid rgb(175, 175, 175);
}

.blink_me {
  animation: blinker 2.5s linear infinite;
  color: forestgreen;
}
</style>
