<template>
  <div>
    <b-skeleton-table
      v-if="fetchData"
      :rows="3"
      :columns="2"
      :table-props="{ bordered: true, striped: true }"
    >
    </b-skeleton-table>
    <div class="subgroupReports" v-else>
      <b-overlay
        :show="loadReport"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <table class="table-bordered table table-striped">
          <thead class="thead-dark">
            <tr>
              <th>Report Type</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="align-middle">Student Roll List</td>
              <td class="align-middle text-center">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->
                <div>
                  <b-button
                    variant="danger"
                    @click="downloadStudentList(false)"
                    size="sm"
                    >Download Report</b-button
                  >
                  <b-button
                    style="margin-left: 10px"
                    variant="primary"
                    @click="downloadStudentList(true)"
                    size="sm"
                    >View Report</b-button
                  >
                </div>
              </td>
            </tr>

            <tr>
              <td class="align-middle">
                Students Attendance Report (Student vs Date)
              </td>
              <td class="align-middle text-center">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->

                <b-modal
                  size="lg"
                  id="downloadStudentsDateWiseAttendanceModel"
                  hide-footer
                  centered
                >
                  <b-overlay
                    :show="loadReport"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    rounded="md"
                  >
                    <div>
                      <span
                        >Conditions: where student on a date to be considered as
                        absent (else present)</span
                      >
                      <b-form-checkbox
                        v-model="studentsDateWiseSelectedEquations"
                        :value="eq.value"
                        v-for="(eq, examIndex) in studentsDateWiseEquations"
                        :key="examIndex"
                        size="md"
                        class="text-uppercase"
                        :disabled="eq.value == 'EQ1'"
                      >
                        {{ eq.text }}
                      </b-form-checkbox>
                    </div>

                    <div class="mt-4">
                      <b-link
                        v-if="date_from != null || date_upto != null"
                        @click="
                          date_from = null;
                          date_upto = null;
                        "
                        >Clear Dates</b-link
                      >
                      <b-link
                        v-else
                        @click="
                          enableAttendanceIsDateCollapsed =
                            !enableAttendanceIsDateCollapsed
                        "
                      >
                        Attendance Date (optional)
                      </b-link>
                      <b-collapse
                        id="enableAttendanceDate"
                        class="mt-2"
                        v-model="enableAttendanceIsDateCollapsed"
                      >
                        <b-form-group
                          label="Start date (YYYY-MM-DD)"
                          v-slot="{ ariaDescribedby }"
                          class="m-1"
                        >
                          <b-form-datepicker
                            :aria-describedby="ariaDescribedby"
                            placeholder="Select Date"
                            v-model="date_from"
                          >
                          </b-form-datepicker>
                        </b-form-group>
                        <b-form-group
                          label="End date (YYYY-MM-DD)"
                          v-slot="{ ariaDescribedby }"
                          class="m-1 mb-3"
                        >
                          <b-form-datepicker
                            :aria-describedby="ariaDescribedby"
                            placeholder="Select Date"
                            v-model="date_upto"
                          >
                          </b-form-datepicker>
                        </b-form-group>
                      </b-collapse>
                    </div>
                    <div class="text-center">
                      <b-button
                        variant="danger"
                        @click="downloadStudentsDateWiseAttendance(false)"
                        size="sm"
                        >Save & Download Report</b-button
                      >
                      <b-button
                        style="margin-left: 10px"
                        variant="primary"
                        @click="downloadStudentsDateWiseAttendance(true)"
                        size="sm"
                        >Save & View Report</b-button
                      >
                    </div>
                  </b-overlay>
                </b-modal>
                <span
                  class="text-info btn p-0 m-0 font-weight-bolder"
                  @click="openDownloadStudentsDateWiseAttendanceModel"
                >
                  Configure & Download
                </span>
              </td>
            </tr>

            <tr>
              <td class="align-middle">
                Students Attendance Report (Student vs Date, Session, Course)
              </td>
              <td class="align-middle text-center">
                <b-modal
                  size="lg"
                  id="downloadStudentsDateSessionWiseAttendanceModel"
                  hide-footer
                  centered
                >
                  <b-overlay
                    :show="loadReport"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    rounded="md"
                  >
                    <div class="mt-4 mx-2 row">
                      <b-form-group
                        label="Start date (YYYY-MM-DD)"
                        v-slot="{ ariaDescribedby }"
                        class="m-1 col"
                      >
                        <b-form-datepicker
                          :aria-describedby="ariaDescribedby"
                          placeholder="Select Date"
                          v-model="date_from"
                        >
                        </b-form-datepicker>
                      </b-form-group>
                      <b-form-group
                        label="End date (YYYY-MM-DD)"
                        v-slot="{ ariaDescribedby }"
                        class="m-1 mb-3 col"
                      >
                        <b-form-datepicker
                          :aria-describedby="ariaDescribedby"
                          placeholder="Select Date"
                          v-model="date_upto"
                        >
                        </b-form-datepicker>
                      </b-form-group>
                      <b-link
                        v-if="date_from != null || date_upto != null"
                        @click="
                          date_from = null;
                          date_upto = null;
                        "
                        >Clear Dates</b-link
                      >
                    </div>
                    <div class="text-center">
                      <b-button
                        variant="danger"
                        @click="getSubgroupAttendanceReportPdf(false)"
                        size="sm"
                        >Save & Download Report</b-button
                      >
                      <b-button
                        style="margin-left: 10px"
                        variant="primary"
                        @click="getSubgroupAttendanceReportPdf(true)"
                        size="sm"
                        >Save & View Report</b-button
                      >
                    </div>
                  </b-overlay>
                </b-modal>
                <span
                  class="text-info btn p-0 m-0 font-weight-bolder"
                  @click="openDownloadStudentsDateSessionWiseAttendanceModel"
                >
                  Configure & Download
                </span>
              </td>
            </tr>

            <tr>
              <td class="align-middle">
                Consolidated Statement of Course Attendance
              </td>
              <td class="align-middle text-center">
                <b-modal
                  size="lg"
                  id="subgroupAttendenceReport"
                  hide-footer
                  centered
                >
                  <div class="text-center">
                    <b-row class="m-2 pl-1">
                      Choose date range (optional):
                      <span
                        class="ml-2"
                        v-if="!enableAttendanceReportDateRange"
                      >
                        <b-form-checkbox
                          v-model="enableAttendanceReportDateRange"
                        ></b-form-checkbox>
                      </span>
                      <b-link
                        v-if="date_from != null || date_upto != null"
                        @click="
                          date_from = null;
                          date_upto = null;
                        "
                        >Clear Dates</b-link
                      >
                    </b-row>
                    <b-row v-if="enableAttendanceReportDateRange">
                      <b-col md="6">
                        <label for="example-datepickerfrom">From:</label>
                        <b-form-datepicker
                          id="example-datepickerfrom"
                          v-model="date_from"
                          class="mb-2"
                        >
                        </b-form-datepicker>
                      </b-col>
                      <b-col md="6">
                        <label for="example-datepickerto">To:</label>
                        <b-form-datepicker
                          id="example-datepickerto"
                          v-model="date_upto"
                          class="mb-2"
                        >
                        </b-form-datepicker>
                      </b-col>
                    </b-row>
                    <div class="row ml-3">
                      <label for="example-datepickerto">Choose Courses:</label>
                      <b-form-checkbox
                        v-model="selectedCourses"
                        :value="course.id"
                        v-for="(course, courseIndex) in subgroupCourses"
                        :key="courseIndex"
                        size="sm"
                        class="mx-3 my-1"
                      >
                        {{ course.name }}
                      </b-form-checkbox>                    
                    </div>
                    <b-form-group
                      id="input-group-2"
                      label="Include by class type:"
                      label-for="input-2"
                    >
                      <b-form-checkbox-group
                        id="checkbox-group-2"
                        v-model="selectedClassTypes"
                        name="flavour-2"
                      >
                        <b-form-checkbox
                          v-for="(cType, scindex) in classTypesOptionsArray"
                          :key="scindex"
                          :value="cType.value"
                          >{{ cType.text }}</b-form-checkbox
                        >
                      </b-form-checkbox-group>
                    </b-form-group>

                    <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                      <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                      <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                    </div> -->
                    <br />
                    <br />
                    <b-button
                      variant="danger"
                      @click="downloadAttendanceStatement(true, false)"
                      size="sm"
                      >Save & Download Report</b-button
                    >
                    <b-button
                      style="margin-left: 10px"
                      variant="primary"
                      @click="downloadAttendanceStatement(true, true)"
                      size="sm"
                      >Save & View Report</b-button
                    >
                  </div>
                </b-modal>
                <span
                  class="text-info btn p-0 m-0 font-weight-bolder"
                  @click="openSubgroupAttendenceReportModel"
                >
                  Configure & Download
                </span>
              </td>
            </tr>

            <tr>
              <td class="align-middle">
                Students Individual Activity Report (Progress Card)
              </td>
              <td class="align-middle text-center">
                <span
                  class="text-info btn p-0 m-0 font-weight-bolder"
                  @click="studentsIndividualActivityReportModel()"
                >
                  Configure & Download
                </span>
                <b-modal
                  size="md"
                  id="studentsIndividualActivityReportModel"
                  hide-footer
                  centered
                >
                  <b-overlay
                    :show="loadReport"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    rounded="md"
                  >
                    <div>
                      <b-form-group
                        label="Select exams by:"
                        class="required"
                        v-slot="{ ariaDescribedby }"
                      >
                        <b-form-radio
                          id="radio-group-1"
                          v-model="examFilterOption"
                          :value="'date'"
                          :aria-describedby="ariaDescribedby"
                          name="radio-options1"
                          >Date</b-form-radio
                        >
                        <b-form-radio
                          id="radio-group-2"
                          v-model="examFilterOption"
                          :value="'exam'"
                          :aria-describedby="ariaDescribedby"
                          name="radio-options2"
                          >Exams</b-form-radio
                        >
                        <b-form-radio
                          id="radio-group-3"
                          v-model="examFilterOption"
                          :value="'examCatagories'"
                          :aria-describedby="ariaDescribedby"
                          name="radio-options3"
                          >Exam Catagories</b-form-radio
                        >
                      </b-form-group>
                    </div>

                    <div v-if="examFilterOption == 'examCatagories'">
                      <div>
                        <b-form-select v-model="examCategory" class="mb-3">
                          <b-form-select-option :value="null"
                            >{{
                              examCatagories.length > 0
                                ? "Please select an option"
                                : "Category not added in institute"
                            }}
                          </b-form-select-option>
                          <b-form-select-option
                            v-for="(
                              examCatagory, examCatagoryIndex
                            ) in examCatagories"
                            :key="examCatagoryIndex"
                            :value="examCatagory.id"
                            >{{ examCatagory.name }}
                          </b-form-select-option>
                        </b-form-select>
                      </div>
                    </div>

                    <div v-if="examFilterOption == 'date'">
                      <b-form-group
                        label="Start date (YYYY-MM-DD)"
                        v-slot="{ ariaDescribedby }"
                        class="m-1 required"
                      >
                        <b-form-datepicker
                          :aria-describedby="ariaDescribedby"
                          placeholder="Select Date"
                          v-model="activity_date_from"
                        >
                        </b-form-datepicker>
                      </b-form-group>
                      <b-form-group
                        label="End date (YYYY-MM-DD)"
                        v-slot="{ ariaDescribedby }"
                        class="m-1 mb-3"
                      >
                        <b-form-datepicker
                          :aria-describedby="ariaDescribedby"
                          placeholder="Select Date"
                          v-model="activity_date_upto"
                        >
                        </b-form-datepicker>
                      </b-form-group>
                      <b-link
                        v-if="
                          activity_date_from != null ||
                          activity_date_upto != null
                        "
                        @click="
                          activity_date_from = null;
                          activity_date_upto = null;
                        "
                        >Clear Dates</b-link
                      >
                    </div>
                    <div v-if="examFilterOption == 'exam'" class="mt-2">
                      <div
                        class="courses"
                        v-for="(course, courseIndex) in courses"
                        :key="courseIndex"
                      >
                        <div class="course" v-if="course.exams.length != 0">
                          <div class="courseName">
                            <div class="font-weight-bolder mb-2">
                              *{{ course.name }}
                            </div>
                            <div class="exams ml-3">
                              <b-form-checkbox
                                v-model="selectedExams"
                                :value="exam.id"
                                v-for="(exam, examIndex) in course.exams"
                                :key="examIndex"
                                size="md"
                                class="text-uppercase"
                              >
                                {{ exam.name }}
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <a
                        id="checkbox-1"
                        @click="
                          enableCourseSelectionCollapse =
                            !enableCourseSelectionCollapse
                        "
                        name="checkbox-1"
                      >
                        <b-link> Filter Courses (optional)</b-link>
                      </a>
                      <b-collapse
                        v-model="enableCourseSelectionCollapse"
                        class="mt-2"
                      >
                        <div class="exams ml-3">
                          <b-form-checkbox
                            v-model="selectedCourses"
                            :value="course.id"
                            v-for="(course, courseIndex) in courses"
                            :key="courseIndex"
                            size="md"
                            class="text-uppercase"
                          >
                            {{ course.name }}
                          </b-form-checkbox>
                        </div>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <a
                        id="checkbox-1"
                        @click="
                          enableAttendanceDateCollapse =
                            !enableAttendanceDateCollapse
                        "
                        name="checkbox-1"
                      >
                        <b-link> Attendance Date (optional)</b-link>
                      </a>
                      <b-collapse
                        v-model="enableAttendanceDateCollapse"
                        class="mt-2"
                      >
                        <b-form-group
                          label="Start date (YYYY-MM-DD)"
                          v-slot="{ ariaDescribedby }"
                          class="m-1 required"
                        >
                          <b-form-datepicker
                            :aria-describedby="ariaDescribedby"
                            placeholder="Select Date"
                            v-model="date_from"
                          >
                          </b-form-datepicker>
                        </b-form-group>
                        <b-form-group
                          label="End date (YYYY-MM-DD)"
                          v-slot="{ ariaDescribedby }"
                          class="m-1 mb-3 required"
                        >
                          <b-form-datepicker
                            :aria-describedby="ariaDescribedby"
                            placeholder="Select Date"
                            v-model="date_upto"
                          >
                          </b-form-datepicker>
                        </b-form-group>
                        <b-link
                          v-if="date_from != null || date_upto != null"
                          @click="
                            date_from = null;
                            date_upto = null;
                          "
                          >Clear Dates</b-link
                        >
                      </b-collapse>
                    </div>
                    <div class="text-center">
                      <b-button
                        variant="danger"
                        @click="downloadStudentsIndividualActivityReport(false)"
                        size="sm"
                        >Save & Download Report</b-button
                      >
                      <b-button
                        style="margin-left: 10px"
                        variant="primary"
                        @click="downloadStudentsIndividualActivityReport(true)"
                        size="sm"
                        >Save & View Report</b-button
                      >
                    </div>
                  </b-overlay>
                </b-modal>
              </td>
            </tr>

            <tr
              :class="selectExam ? null : 'collapsed'"
              :aria-expanded="selectExam ? 'true' : 'false'"
              aria-controls="exams"
            >
              <td class="align-middle">
                Statement of Assessment Marks With Result Analysis
              </td>
              <td class="align-middle text-center">
                <b-button
                  variant="danger"
                  @click="statementOfInternalAssessment()"
                  size="sm"
                  >{{
                    selectExam ? "Please Select Assessments" : "Download Report"
                  }}</b-button
                >
              </td>
            </tr>

            <tr>
              <td class="align-middle">
                Consolidated Statement of Internal Marks
              </td>
              <td class="align-middle text-center">
                <b-modal
                  size="lg"
                  id="subgroupContinuesEvaluationReportModel"
                  hide-footer
                  centered
                >
                  <div class="text-left">
                    <b-col
                      ><small
                        style="font-size: 80% !important"
                        class="text-danger"
                      >
                        <sup>*</sup> The Internal marks for course indicated in
                        red color are not yet calculated by course
                        instructor</small
                      ></b-col
                    >
                    <br />
                    <b-form-checkbox
                      v-model="selectedCourses"
                      :value="course.id"
                      v-for="(
                        course, courseIndex
                      ) in continuesEvaluationCourses"
                      :key="courseIndex"
                      size="md"
                      class="text-uppercase"
                      :disabled="!course.continuesEvaluationDataCacheAvailable"
                    >
                      <span
                        :class="[
                          course.continuesEvaluationDataCacheAvailable
                            ? 'text-success'
                            : 'text-danger',
                        ]"
                        >{{ course.name }}-{{ course.code }}-{{
                          course.usersubgroup.code
                        }}</span
                      >
                    </b-form-checkbox>
                    <br />
                    <br />
                  </div>

                  <div>
                    <b-button
                      variant="danger"
                      @click="downloadContinuesEvaluationCoursesReport(false)"
                      size="sm"
                      >Save & Downlod Report</b-button
                    >
                    <b-button
                      style="margin-left: 1px"
                      variant="primary"
                      @click="downloadContinuesEvaluationCoursesReport(true)"
                      size="sm"
                      >Save & View Report</b-button
                    >
                  </div>
                </b-modal>
                <span
                  class="text-info btn p-0 m-0 font-weight-bolder"
                  @click="getContinuesEvaluationCourses"
                >
                  Configure & Download
                </span>
              </td>
            </tr>

            <tr>
              <td class="align-middle">University Exams Result Analysis</td>
              <td class="align-middle text-center">
                <!-- <div class="selectReportType mb-2 d-flex justify-content-center">
                  <b-form-radio v-model="reportType" :value="0">Oridinary Report</b-form-radio>
                  <b-form-radio v-model="reportType" :value="1" class="ml-3">ISO Report</b-form-radio>
                </div> -->

                <b-modal
                  size="lg"
                  id="doanloadresultanalysisModel"
                  hide-footer
                  centered
                >
                  <div class="exams ml-3">
                    <b-form-checkbox
                      v-model="selectedCourses"
                      :value="course.id"
                      v-for="(course, courseIndex) in subgroupCourses"
                      :key="courseIndex"
                      size="md"
                      class="text-uppercase"
                    >
                      {{ course.name }}
                    </b-form-checkbox>
                  </div>
                  <br />
                  <br />
                  <div class="text-center">
                    <b-button
                      variant="danger"
                      @click="doanloadresultanalysis(true, false)"
                      size="sm"
                      >Save & Download Report</b-button
                    >
                    <b-button
                      style="margin-left: 1px"
                      variant="primary"
                      @click="doanloadresultanalysis(true, true)"
                      size="sm"
                      >Save & View Report
                    </b-button>
                  </div>
                </b-modal>
                <span
                  class="text-info btn p-0 m-0 font-weight-bolder"
                  @click="doanloadresultanalysisModel()"
                >
                  Configure & Download
                </span>
              </td>
            </tr>

            <tr>
              <td class="align-middle">
                PO Attainment Report (Programme Batch)
              </td>
              <td class="align-middle text-center">
                <b-button
                  variant="danger"
                  @click="
                    $router.push({
                      name: 'PoAttainment',
                      params: { usersubgroupId: subgroupReport.id },
                    })
                  "
                  size="sm"
                  >Download Report</b-button
                >
                <!-- <span style="color: #2929aa; text-decoration: underline; cursor: pointer;" v-b-popover.hover.top="
                  'Attainment module is under maintanance, This report will not be available for some time'
                " title="Not available">Updating...</span> -->
              </td>
            </tr>

            <tr>
              <td class="align-middle">
                Edit {{ subgroupCustomName }} Attendance
              </td>
              <td class="align-middle text-center">
                <b-modal
                  modal-class="subgroupReportsEditAttendanceReport"
                  id="subgroupAttendenceReportView"
                  hide-footer
                >
                  <editSubgroupAttendanceFormWithReport
                    :subgroupIdProp="subgroupReport.id"
                    @closeEditAttendanceWindow="closeEditAttendanceWindow"
                  />
                </b-modal>
                <span
                  class="text-info btn p-0 m-0 font-weight-bolder"
                  @click="$bvModal.show('subgroupAttendenceReportView')"
                >
                  View/Edit
                </span>
              </td>
            </tr>

            <tr>
              <td class="align-middle">Attendance Monitoring</td>
              <td class="align-middle text-center">
                <!-- <span
                    class="text-info btn p-0 m-0 font-weight-bolder"
                    @click="
                      $router.push({
                        name: 'UsersubgroupAttendanceMonitoring',
                        params: { usersubgroup: subgroupReport.id },
                      })
                    "
                  >
                    Configure & view
                  </span> -->
                <span
                  class="text-info btn p-0 m-0 font-weight-bolder"
                  v-b-modal="'subgroupattebdancemonitoringModel'"
                >
                  Configure & view
                </span>

                <b-modal
                  id="subgroupattebdancemonitoringModel"
                  no-close-on-backdrop
                  no-close-on-keyboard
                  no-close-on-esc
                  hide-header-close
                  hide-footer
                  hide-header
                  centered
                  size="xl"
                >
                  <div style="text-align: right">
                    <b-button
                      variant="danger"
                      @click="
                        $bvModal.hide('subgroupattebdancemonitoringModel')
                      "
                      >x</b-button
                    >
                  </div>

                  <UsersubgroupAttendanceMonitoringDash
                    :usersubgroupsIds="[subgroupReport.id]"
                  />
                </b-modal>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="selectExam">
          <b-collapse id="exams" v-model="selectExam" class="mt-2">
            <div style="text-align: center; color: red">
              <span>{{ selectExamStatus }}</span>
            </div>
            <b-row class="my-1 m-2">
              <b-col sm="5">
                <label for="input-none">Pass Mark (Optional):</label>
              </b-col>
              <b-col sm="7">
                <b-form-input
                  id="input-none"
                  v-model="passMark"
                  placeholder="Enter Mark"
                ></b-form-input>
              </b-col>
            </b-row>

            <div class="exams ml-3">
              <b-form-checkbox
                v-model="enableSignatureField"
                size="md"
                class="text-uppercase"
              >
                View Signature Fields
              </b-form-checkbox>
            </div>
            <div class="exams ml-3">
              <b-form-checkbox
                v-model="enableCoursesNameTable"
                size="md"
                class="text-uppercase"
              >
                View Courses Name Table
              </b-form-checkbox>
            </div>
            <div class="exams ml-3">
              <b-form-checkbox
                v-model="enableExamsNameTable"
                size="md"
                class="text-uppercase"
              >
                View Exams Name table
              </b-form-checkbox>
            </div>

            <div class="examsList w-100 p-3">
              <div
                class="courses"
                v-for="(course, courseIndex) in courses"
                :key="courseIndex"
              >
                <div class="course" v-if="course.exams.length != 0">
                  <div class="courseName">
                    <div class="font-weight-bolder mb-2">
                      *{{ course.name }}
                    </div>
                    <div class="exams ml-3">
                      <b-form-checkbox
                        v-model="selectedExams"
                        :value="exam.id"
                        v-for="(exam, examIndex) in course.exams"
                        :key="examIndex"
                        size="md"
                        class="text-uppercase"
                      >
                        {{ exam.name }}
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div>
                <b-button
                  size="sm"
                  variant="danger"
                  :disabled="selectedExams.length == 0"
                  @click="downloadInternalAssessmentReport(true, false)"
                >
                  {{
                    selectedExams.length == 0
                      ? "Please Select atlease 1 assessment"
                      : "Save & Download Report"
                  }}
                </b-button>
                <b-button
                  style="margin-left: 1px"
                  variant="primary"
                  :disabled="selectedExams.length == 0"
                  @click="downloadInternalAssessmentReport(true, true)"
                  size="sm"
                >
                  {{
                    selectedExams.length == 0
                      ? "Please Select atlease 1 assessment"
                      : "Save & View Report"
                  }}
                </b-button>
              </div>
            </div>
          </b-collapse>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import UsersubgroupAttendanceMonitoringDash from "../../attendence/monitoring/UsersubgroupAttMonitoringDash.vue";
import editSubgroupAttendanceFormWithReport from "./editSubgroupAttendanceFormWithReport.vue";
// import DatePicker from "vue2-datepicker";
export default {
  props: ["subgroupReport"],
  async created() {
    this.subgroupCustomName =
      this.$store.getters.getCustomNamings.userSubgroup.custom_name ??
      "Usersubgroup";
    this.fetchData = true;
    this.fetchData = false;
  },
  components: {
    // DatePicker,
    UsersubgroupAttendanceMonitoringDash,
    editSubgroupAttendanceFormWithReport,
  },
  data() {
    return {
      subgroupCustomName: "Usersubgroup",
      fetchData: false,
      loadReport: false,
      selectedExams: [],
      selectedClassTypes: [],
      classTypesOptions: [],
      selectExam: false,
      selectExamStatus: "",
      exams: [],
      courses: {},
      reportType: 0,
      reportDate: null,
      date_from: null,
      date_upto: null,
      activity_date_from: null,
      activity_date_upto: null,
      enabledoanloadresultanalysisModel: false,
      subgroupCourses: [],
      selectedCourses: [],
      // subgroupattebdancemonitoringModel:false,
      // resultanalysisreportoptions: false,
      enableAttendanceReportDateRange: false,
      examFilterOption: "date",
      examCatagories: [],
      examCategory: null,
      studentsDateWiseEquations: [
        {
          value: "EQ1",
          text: "Absent on 3 or more sessions (or absent on all marked sessions in case total marked 1 or 2)",
        },
        { value: "EQ2", text: "Absent on all afternoon sessions " },
        {
          value: "EQ3",
          text: "Absent on a forenoon session and an afternoon session",
        },
      ],
      studentsDateWiseSelectedEquations: ["EQ1"],
      passMark: null,
      enableExamsNameTable: false,
      enableCoursesNameTable: false,
      enableSignatureField: false,
      save_config: false,
      continuesEvaluationCourses: [],
      enableAttendanceIsDateCollapsed: false,
      enableAttendanceDateCollapse: false,
      enableCourseSelectionCollapse: false,
    };
  },
  computed: {
    classTypesOptionsArray() {
        if (this.classTypesOptions.length === 0) {
          this.getClassTypes();
        }
        return this.classTypesOptions;
      },
    },
  methods: {
    async getClassTypes() {
      // if (this.classTypesOptions.length > 0) return this.classTypesOptions;
      const url = this.$store.getters.getAPIKey.mainAPI + `/classtypes`;
      await this.$axios.get(url).then((res) => {
        const options = res.data;
        this.classTypesOptions = [{ value: 0, text: "Regular" }];
        this.selectedClassTypes.push(0);
        options.forEach(({ id, name }) => {
          this.classTypesOptions.push({ value: id, text: name });
          this.selectedClassTypes.push(id);
        });
      });
    },
    async openDownloadStudentsDateWiseAttendanceModel() {
      this.$bvModal.show("downloadStudentsDateWiseAttendanceModel");
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/usersubgroup/studentsvsdates/pdfexport`;
      await this.$axios
        .post(url, {
          usersubgroup: this.subgroupReport.id,
          reportOptions: true,
        })
        .then((response) => {
          this.date_from = response.data.start_date;
          this.date_upto = response.data.upto_date;
          this.studentsDateWiseSelectedEquations = response.data.equations;
          if (
            !this.enableAttendanceIsDateCollapsed &&
            (this.date_from || this.date_upto)
          )
            this.enableAttendanceIsDateCollapsed = true;
        })
        .catch(() => {});
    },
    closeEditAttendanceWindow() {
      this.$bvModal.hide("subgroupAttendenceReportView");
    },
    async getSubgroupAttendanceReportPdf(viewReport) {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/usersubgroup/detailedreport/pdfexport`;
      await this.$axios
        .post(
          url,
          {
            usersubgroup: this.subgroupReport.id,
            start_date: this.date_from,
            upto_date: this.date_upto,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "SubgroupAttendance.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        })
        .catch(() => {
          let errorText = "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
        });
    },

    async openDownloadStudentsDateSessionWiseAttendanceModel() {
      this.$bvModal.show("downloadStudentsDateSessionWiseAttendanceModel");

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/usersubgroup/detailedreport/pdfexport`;
      await this.$axios
        .post(url, {
          usersubgroup: this.subgroupReport.id,
          reportOptions: true,
        })
        .then((response) => {
          this.date_from = response.data.start_date;
          this.date_upto = response.data.upto_date;
        })
        .catch(() => {});
    },
    async openSubgroupAttendenceReportModel() {
      this.selectedCourses = [];
      this.subgroupCourses = [];
      this.$bvModal.show("subgroupAttendenceReport");
      await this.getSubgroupCourses();
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/usersubgroup/coursesvsstudents/pdfexport`;
      await this.$axios
        .post(url, {
          usersubgroup: this.subgroupReport.id,
          reportOptions: true,
        })
        .then((response) => {
          this.date_from = response.data.start_date;
          this.date_upto = response.data.upto_date;
          this.selectedCourses = response.data.courses;
          if (this.date_from || this.date_upto)
            this.enableAttendanceReportDateRange = true;
        })
        .catch(() => {});
      if (this.selectedCourses.length < 1)
        this.selectedCourses = this.subgroupCourses
          .filter((obj) => obj.type.slug != "non_curricular")
          .map((course) => course.id);
    },
    async getSubgroupCourses() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/${this.subgroupReport.id}/courses/withinstructors?interdepartmental_courses=1&`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.subgroupCourses = response.data;
        })
        .catch((error) => {
          if (error.response.data) {
            this.$toast.error(error.response.data.message, {
              position: "top",
              duration: 3000,
            });
          } else {
            this.$toast.error("Failed", {
              position: "top",
              duration: 3000,
            });
          }
        });
    },
    async downloadContinuesEvaluationCoursesReport(viewReport) {
      this.loadReport = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/continuesevaluation/report/pdfexport`;
      await this.$axios
        .post(
          url,
          {
            usersubgroup: this.subgroupReport.id,
            courses: this.selectedCourses,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "ContinuesEvaluationCoursesList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.loadReport = false;
        })
        .catch((error) => {
          error.response.data.text().then((text) => {
            const res = JSON.parse(text);
            this.$toast.error(res.message, {
              position: "top",
              duration: 6000,
            });
          });
          this.loadReport = false;
        });
      this.loadReport = false;
    },
    async getContinuesEvaluationCourses() {
      this.selectedCourses = [];
      // this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/continuesevaluation/report/options?usersubgroup=${this.subgroupReport.id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.continuesEvaluationCourses = response.data;
        })
        .catch((error) => {
          error;
        });
      this.$bvModal.show("subgroupContinuesEvaluationReportModel");

      let url1 =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/continuesevaluation/report/pdfexport`;
      await this.$axios
        .post(url1, {
          usersubgroup: this.subgroupReport.id,
          reportOptions: true,
        })
        .then((response) => {
          this.selectedCourses = response.data.courses;
        })
        .catch(() => {});
      // this.fetchData = false;
    },
    async downloadStudentsIndividualActivityReport(viewReport) {
      this.loadReport = true;

      let postData = {
        usersubgroup: this.subgroupReport.id,
        courses: this.selectedCourses, // course filter
        date_from: this.date_from, // att date filter
        date_upto: this.date_upto,
        activities: this.examFilterOption == "exam" ? this.selectedExams : [],
        activity_date_from:
          this.examFilterOption == "date" ? this.activity_date_from : null,
        activity_date_upto:
          this.examFilterOption == "date" ? this.activity_date_upto : null,
        examCategory:
          this.examFilterOption == "examCatagories" ? this.examCategory : null,
      };

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/individualassessment/report/pdfexport`;
      await this.$axios
        .post(url, postData, { responseType: "blob" })
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "StudentsIndividualActivityList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.loadReport = false;
        })
        .catch((error) => {
          error.response.data.text().then((text) => {
            const res = JSON.parse(text);
            this.$toast.error(res.message, {
              position: "top",
              duration: 3000,
            });
          });
          this.loadReport = false;
        });
      this.loadReport = false;
    },
    async studentsIndividualActivityReportModel() {
      this.$bvModal.show("studentsIndividualActivityReportModel");
      // this.fetchData = true;
      this.getExams();
      this.getExamCatagories();

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/individualassessment/report/pdfexport`;
      await this.$axios
        .post(url, {
          usersubgroup: this.subgroupReport.id,
          reportOptions: true,
        })
        .then((response) => {
          this.selectedCourses = response.data.courses;
          if (this.selectedCourses.length > 0)
            this.enableCourseSelectionCollapse = true;

          this.date_from = response.data.date_from;
          this.date_upto = response.data.date_upto;
          if (this.date_from || this.date_upto)
            this.enableAttendanceDateCollapse = true;

          this.selectedExams = response.data.activities;
          this.activity_date_from = response.data.activity_date_from;
          this.activity_date_upto = response.data.activity_date_upto;
          this.examCategory = response.data.examCategory;

          if (this.examCategory) {
            this.examFilterOption = "examCatagories";
          } else if (this.selectedExams.length > 0) {
            this.examFilterOption = "exam";
          } else {
            this.examFilterOption = "date";
          }
        })
        .catch(() => {});
    },
    async getCourseDetails() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/courses/withusers`;
      await this.$axios
        .get(url)
        .then((response) => {
          let courses = response.data;
          courses.forEach((course) => {
            this.$set(this.courses, course.id, {
              id: course.id,
              name: course.name + " (" + course.code + ")",
              exams: [],
            });
          });

          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getExams() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/${this.subgroupReport.id}/exams?interdepartmental_courses=1&`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.exams = [];
          let examsList = response.data;

          examsList.forEach((exam) => {
            if (exam.course.length != 0 && exam.course != null) {
              this.$set(this.courses, exam.course[0].id, {
                id: exam.course[0].id,
                name: exam.course[0].name + " (" + exam.course[0].code + ")",
                exams: [],
              });
            }
          });

          examsList.forEach((exam) => {
            this.courses[exam.course[0].id].exams.push({
              id: exam.id,
              name: exam.name,
            });
          });

          response;
        })
        .catch((error) => {
          error;
        });
    },

    async downloadStudentsDateWiseAttendance(viewReport) {
      this.loadReport = true;

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/usersubgroup/studentsvsdates/pdfexport`;
      await this.$axios
        .post(
          url,
          {
            usersubgroup: this.subgroupReport.id,
            start_date: this.date_from,
            upto_date: this.date_upto,
            equations: this.studentsDateWiseSelectedEquations,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "StudentsDateWiseAttendanceList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.loadReport = false;
        })
        .catch((error) => {
          error.response.data.text().then((text) => {
            const res = JSON.parse(text);
            this.$toast.error(res.message, {
              position: "top",
              duration: 3000,
            });
          });
          this.loadReport = false;
        });
      this.loadReport = false;
    },

    async doanloadresultanalysis(save_config, viewReport) {
      this.loadReport = true;

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/boardexams/usersubgroup/resultanalysis`;
      await this.$axios
        .post(
          url,
          {
            usersubgroup: this.subgroupReport.id,
            courses: this.selectedCourses,
            save_config: save_config,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "resultanalysisList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }

          this.loadReport = false;
        })
        .catch((error) => {
          error.response.data.text().then((text) => {
            const res = JSON.parse(text);
            this.$toast.error(res.message, {
              position: "top",
              duration: 3000,
            });
          });
          this.loadReport = false;
        });
      this.loadReport = false;
    },

    async doanloadresultanalysisModel() {
      this.selectedCourses = [];
      if (this.enabledoanloadresultanalysisModel) {
        this.enabledoanloadresultanalysisModel = false;
        this.$bvModal.hide("doanloadresultanalysisModel");
        return;
      }
      this.enabledoanloadresultanalysisModel = true;
      this.$bvModal.show("doanloadresultanalysisModel");
      this.loadReport = true;

      // this.subgroupReport.id
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/${this.subgroupReport.id}/courses/withinstructors?interdepartmental_courses=1&`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.subgroupCourses = response.data;
        })
        .catch((error) => {
          if (error.response.data) {
            this.$toast.error(error.response.data.message, {
              position: "top",
              duration: 3000,
            });
          } else {
            this.$toast.error("Failed", {
              position: "top",
              duration: 3000,
            });
          }
        });
      this.loadReport = false;
      let url1 =
        this.$store.getters.getAPIKey.mainAPI +
        `/boardexams/usersubgroup/resultanalysis`;
      await this.$axios
        .post(url1, {
          usersubgroup: this.subgroupReport.id,
          reportOptions: true,
        })
        .then((response) => {
          this.selectedCourses = response.data.courses;
        })
        .catch(() => {});
    },
    async downloadStudentList(viewReport) {
      this.loadReport = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroup/${this.subgroupReport.id}/users/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          { usersubgroup: this.subgroupReport.id },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "studentList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
        });
      this.loadReport = false;
    },
    async downloadStudentTimetable() {
      this.loadReport = true;

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courseschedules/usersubgroup/report/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(
          url,
          { usersubgroup: this.subgroupReport.id },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "studentTimetable.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          // this.loadReport = false;

          // console.log(response);
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
        });
      this.loadReport = false;
    },
    async downloadAttendanceStatement(save_config, viewReport) {
      let postData = {
        courses: this.selectedCourses,
        usersubgroup: this.subgroupReport.id,
        start_date: null,
        upto_date: null,
        save_config: save_config,
        class_types: this.selectedClassTypes,
      };
      postData.start_date = this.date_from;
      postData.upto_date = this.date_upto;
      this.loadReport = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/usersubgroup/coursesvsstudents/pdfexport?custom_report=${this.reportType}`;
      await this.$axios
        .post(url, postData, { responseType: "blob" })
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "AttendanceStatementList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
        });
      this.loadReport = false;
    },
    // async getResultanalysisreportoptions() {
    //   this.resultanalysisreportoptions = !this.resultanalysisreportoptions;
    //   if (this.resultanalysisreportoptions) {
    //     this.fetchData = true;
    //     // await this.getusersubgroups();
    //     this.fetchData = false;
    //   }
    // },
    async statementOfInternalAssessment() {
      this.selectExam = !this.selectExam;
      if (this.selectExam) {
        this.fetchData = true;
        await this.getExams();

        const url =
          this.$store.getters.getAPIKey.mainAPI +
          `/exams/usersubgroupcoursesexams/report`;
        await this.$axios
          .post(url, {
            usersubgroup: this.subgroupReport.id,
            reportOptions: true,
          })
          .then((response) => {
            this.selectedExams = response.data.exams;
            this.passMark = response.data.pass_mark;
            this.enableExamsNameTable = response.data.exams_table;
            this.enableCoursesNameTable = response.data.course_table;
            this.enableSignatureField = response.data.signature_fields;
            // response.data.result_analysis
          })
          .catch(() => {});
        this.fetchData = false;
      }
    },
    async downloadInternalAssessmentReport(save_config, viewReport) {
      this.loadReport = true;

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/exams/usersubgroupcoursesexams/report`;
      await this.$axios
        .post(
          url,
          {
            usersubgroup: this.subgroupReport.id,
            exams: this.selectedExams,
            pass_mark: this.passMark,
            signature_fields: this.enableSignatureField,
            course_table: this.enableCoursesNameTable,
            exams_table: this.enableExamsNameTable,
            save_config: save_config,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "InternalAssessmentList.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        })
        .catch((error) => {
          let errorText = "Failed to Download, please check connectivity";
          if (error.response != undefined)
            errorText =
              error.response.status == 422
                ? "Custom report is not configured by admin"
                : "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
        });
      this.loadReport = false;
    },

    async getExamCatagories() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/examcategorys`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.examCatagories = response.data;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style>
.subgroupReports table tbody td:nth-child(2) {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.subgroupReportsEditAttendanceReport .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
}

.subgroupReportsEditAttendanceReport .modal-body {
  overflow: scroll;
}
</style>
