var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"groupData"},[_c('b-modal',{attrs:{"title":_vm.getCustomNames.userGroupAdmin.custom_name != null
        ? _vm.getCustomNames.userGroupAdmin.custom_name + ' List'
        : 'Usergroup Admin List',"size":"lg","id":'userGroupAdmins' + _vm.id,"hide-footer":"","centered":""},on:{"close":_vm.checkAdminsStatus}},[_c('ListOfAdmins',{attrs:{"editInfo":_vm.editInfo,"adminList":_vm.adminList,"getCustomNames":_vm.getCustomNames},on:{"updateInstitutionUserCount":_vm.updateInstitutionUserCount}})],1),_c('b-overlay',{attrs:{"show":_vm.groupLoading,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"md"}},[_c('div',{},[_c('table',{staticClass:"table table-bordered table-striped"},[_c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.getCustomNames.userGroup.custom_name != null ? _vm.getCustomNames.userGroup.custom_name : "Usergroup")+" ")]),_c('th',[_vm._v("Type")]),_c('th',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.getCustomNames.userGroupAdmin.custom_name != null ? _vm.getCustomNames.userGroupAdmin.custom_name + " List" : "Usergroup Admin List")+" ")]),_c('th',[_vm._v("Affiliated to")]),_c('th',[_vm._v("Scheme")]),_c('th',{staticClass:"align-middle"},[_vm._v("PO")]),_c('th',{staticClass:"align-middle"},[_vm._v("PSO")])])]),_c('tbody',[(_vm.usergroupFromMain.length == 0)?_c('tr',[_c('td',{staticClass:"text-danger text-center font-weight-bolder",attrs:{"colspan":"100"}},[_vm._v(" "+_vm._s(_vm.getCustomNames.userGroup.custom_name != null ? "No " + _vm.getCustomNames.userGroup.custom_name + " Found!" : "No Usergroup Found!")+" ")])]):_vm._l((_vm.usergroupFromMain),function(ref,groupIndex){
        var id = ref.id;
        var name = ref.name;
        var code = ref.code;
        var institution_users = ref.institution_users;
        var programme_type = ref.programme_type;
        var affiliated_university = ref.affiliated_university;
        var scheme = ref.scheme;
        var programme_specificoutcomes_count = ref.programme_specificoutcomes_count;
        var programmeoutcomes_count = ref.programmeoutcomes_count;
return _c('tr',{key:groupIndex},[_c('td',{staticClass:"align-middle"},[_c('div',[_vm._v(" "+_vm._s(name + " (" + code + ")")+" "),_c('span',{staticClass:"text-info btn p-0 m-0 ml-2",on:{"click":function($event){return _vm.getGroup({ id: id, admins: institution_users, group: true })}}},[_c('i',{staticClass:"fas fa-pen"})])])]),_c('td',[_vm._v(_vm._s(programme_type ? programme_type.name : ''))]),_c('td',{staticClass:"align-middle"},[_c('b-popover',{attrs:{"target":'disabled-groupAdmin' + groupIndex,"triggers":"hover","placement":"top"}},[_c('b-list-group',{staticStyle:{"height":"200px","overflow-y":"scroll"}},_vm._l((institution_users),function(ref,adminIndex){
        var first_name = ref.first_name;
        var last_name = ref.last_name;
return _c('b-list-group-item',{key:adminIndex},[_vm._v(" "+_vm._s(first_name.concat(" ", last_name))+" ")])}),1)],1),(institution_users.length == 1)?_c('span',{staticClass:"btn p-0 m-0 text-info font-weight-bolder",attrs:{"id":'groupAdmin' + groupIndex},on:{"click":function($event){return _vm.openAdminsModal({
                      id: id,
                      name: name,
                      group: true,
                      dataChanged: false,
                    })}}},[_vm._v(" "+_vm._s(institution_users[0].first_name.concat( institution_users[0].last_name ))+" ")]):(institution_users.length > 1)?_c('span',{staticClass:"btn p-0 m-0 text-info font-weight-bolder",attrs:{"id":'groupAdmin' + groupIndex},on:{"click":function($event){return _vm.openAdminsModal({
                      id: id,
                      name: name,
                      group: true,
                      dataChanged: false,
                    })}}},[_vm._v(" "+_vm._s(institution_users[0].first_name.concat( " ", institution_users[0].last_name ))+" & "+_vm._s(institution_users.length - 1)+" more ")]):_c('span',{staticClass:"btn p-0 m-0 text-danger font-weight-bolder",on:{"click":function($event){return _vm.openAdminsModal({
                    id: id,
                    name: name,
                    group: true,
                    dataChanged: false,
                  })}}},[_vm._v(" No Admins Assigned Yet! ")])],1),_c('td',[_vm._v(_vm._s(affiliated_university ? affiliated_university : 'Not Added'))]),_c('td',[_vm._v(_vm._s(scheme ? scheme : 'Not Added'))]),_c('td',{staticClass:"align-middle"},[_c('span',{staticClass:"text-info btn p-0 m-0 font-weight-bolder",on:{"click":function($event){return _vm.openManagePoModal(id)}}},[_vm._v(" "+_vm._s(programmeoutcomes_count)+" PO Added ")])]),_c('td',{staticClass:"align-middle"},[_c('span',{staticClass:"text-info btn p-0 m-0 font-weight-bolder",on:{"click":function($event){return _vm.$emit('goToPSO', id)}}},[_vm._v(" "+_vm._s(programme_specificoutcomes_count)+" PSO Added ")])])])})],2)])])])],1),_c('b-modal',{attrs:{"id":('managePoModel' + _vm.id),"no-close-on-backdrop":"","no-close-on-keyboard":"","no-close-on-esc":"","hide-footer":"","centered":"","size":"lg","lazy":""}},[_c('UsergroupPoDashboard',{attrs:{"usergroup_id":_vm.managePoOfUsergroup}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }