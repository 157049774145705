<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="surveyReport" v-else>
      <b-overlay
        :show="loadSurveyReport"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <b-form
          @submit.prevent="getTeacherFeedbackReport()"
          class="border-0 p-3"
        >
          <b-form-group label="Select Survey Type">
            <b-form-select
              :options="surveyTypesOptions"
              v-model="selectedSurveyType"
              @change="getSurveys()"
              class="w-50"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            label="Select Course"
            v-if="selectedSurveyType == 'course_exit'"
          >
            <b-form-select
              class="w-50"
              :options="coursesOptions"
              v-model="selectedCourse"
              @change="getSurveys()"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            :label="
              getCustomNames.userGroup.custom_name != null
                ? 'Select ' + getCustomNames.userGroup.custom_name
                : 'Select Usergroup'
            "
            v-if="selectedSurveyType == 'programme_exit'"
          >
            <b-form-select
              class="w-50"
              :options="usergroupOptions"
              v-model="selectedGroupSubgroup"
              @change="getSurveys()"
            ></b-form-select>
          </b-form-group>

          <b-form-group v-if="selectedSurveyType != null">
            <div
              class="table-responsive rounded"
              v-if="
                selectedSurveyType != 'programme_exit' &&
                  selectedSurveyType != 'course_exit'
              "
            >
              <table class="table bordered table-bordered table-striped">
                <thead class="thead-dark">
                  <tr
                    style="font-size: 14px; font-weight: 600; text-align: center"
                  >
                    <th>Name</th>
                    <th
                      v-if="
                        selectedSurveyType == 'course_exit' ||
                          selectedSurveyType == 'programme_exit'
                      "
                    >
                      Created For
                    </th>
                    <th>Created Date</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="filterSurveys.length == 0">
                    <td
                      class="text-danger text-center align-middle"
                      colspan="100"
                    >
                      No Surveys Found!
                    </td>
                  </tr>
                  <tr
                    v-for="(survey, surveyIndex) in filterSurveys"
                    :key="surveyIndex"
                    class="text-center"
                    style="font-size: 14px; font-weight: 600"
                    v-else
                  >
                    <td class="align-middle">
                      <div class="">
                        <span :id="'survey' + surveyIndex"
                          >{{ survey.name }}
                        </span>
                      </div>
                      <br />

                      <b-tooltip
                        :target="'survey' + surveyIndex"
                        triggers="hover"
                        placement="topright"
                      >
                        <p style="font-size: 14px">{{ survey.summary }}</p>
                      </b-tooltip>
                    </td>
                    <td
                      class="align-middle text-center"
                      v-if="
                        selectedSurveyType == 'course_exit' ||
                          selectedSurveyType == 'programme_exit'
                      "
                    >
                      <span v-if="selectedSurveyType == 'course_exit'">
                        {{
                          survey.course && survey.course.name
                            ? survey.course.code +
                              "-" +
                              survey.course.usersubgroup.usergroup.code +
                              "-" +
                              survey.course.usersubgroup.code
                            : ""
                        }}
                      </span>
                      <span v-if="selectedSurveyType == 'programme_exit'">
                        {{
                          survey.usersubgroup != null &&
                          survey.usersubgroup.name
                            ? survey.usersubgroup.usergroup.code +
                              " - " +
                              survey.usersubgroup.code
                            : ""
                        }}
                      </span>
                    </td>
                    <td class="align-middle">{{ survey.created_at }}</td>
                    <td class="align-middle">
                      {{ survey.start_at.format("DD/MM/YYYY") }}
                    </td>
                    <td class="align-middle">
                      {{ survey.end_at.format("DD/MM/YYYY") }}
                    </td>
                    <td class="align-middle text-left">
                      <b-button
                        size="sm"
                        :disable="selectedSurvey == null ? true : false"
                        variant="primary"
                        @click="getTeacherFeedbackReport(survey.id)"
                        v-if="
                          (institutionAdmin || groupAdmin || subgroupAdmin) &&
                            selectedSurveyType == 'student_feedback'
                        "
                        >Survey Report</b-button
                      >
                      <b-button
                        size="sm"
                        :disable="selectedSurvey == null ? true : false"
                        variant="danger"
                        @click="generateReport(survey.id)"
                        v-if="
                          (institutionAdmin || groupAdmin) &&
                            selectedSurveyType == 'programme_exit'
                        "
                        >Download Survey Report</b-button
                      >
                      <b-button
                        size="sm"
                        :disable="selectedSurvey == null ? true : false"
                        variant="danger"
                        @click="generateReport(survey.id)"
                        v-if="
                          (institutionAdmin || groupAdmin) &&
                            selectedSurveyType == 'course_exit'
                        "
                        >Download Survey Report</b-button
                      >
                      <br /><br />
                      <b-button
                        v-if="surveyParticipantsReport"
                        size="sm"
                        :disable="selectedSurvey == null ? true : false"
                        variant="primary"
                        @click="
                          getParticipantsReport(
                            survey.id,
                            survey.course,
                            survey.usersubgroup
                          )
                        "
                        >Participants Report</b-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              class="table-responsive rounded"
              v-if="
                (selectedGroupSubgroup != null &&
                  selectedSurveyType == 'programme_exit') ||
                  (selectedCourse != null &&
                    selectedSurveyType == 'course_exit')
              "
            >
              <table class="table bordered table-bordered table-striped">
                <thead class="thead-dark">
                  <tr
                    style="font-size: 14px; font-weight: 600; text-align: center"
                  >
                    <th>Name</th>
                    <th
                      v-if="
                        selectedSurveyType == 'course_exit' ||
                          selectedSurveyType == 'programme_exit'
                      "
                    >
                      Created For
                    </th>
                    <th>Created Date</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="filterSurveys.length == 0">
                    <td
                      class="text-danger text-center align-middle"
                      colspan="100"
                    >
                      No Surveys Found!
                    </td>
                  </tr>
                  <tr
                    v-for="(survey, surveyIndex) in filterSurveys"
                    :key="surveyIndex"
                    class="text-center"
                    style="font-size: 14px; font-weight: 600"
                    v-else
                  >
                    <td class="align-middle">
                      <div class="">
                        <span :id="'survey' + surveyIndex"
                          >{{ survey.name }}
                        </span>
                      </div>
                      <br />

                      <b-tooltip
                        :target="'survey' + surveyIndex"
                        triggers="hover"
                        placement="topright"
                      >
                        <p style="font-size: 14px">{{ survey.summary }}</p>
                      </b-tooltip>
                    </td>
                    <td
                      class="align-middle text-center"
                      v-if="
                        selectedSurveyType == 'course_exit' ||
                          selectedSurveyType == 'programme_exit'
                      "
                    >
                      <span v-if="selectedSurveyType == 'course_exit'">
                        {{
                          survey.course && survey.course.name
                            ? survey.course.code +
                              "-" +
                              survey.course.usersubgroup.usergroup.code +
                              "-" +
                              survey.course.usersubgroup.code
                            : ""
                        }}
                      </span>
                      <span v-if="selectedSurveyType == 'programme_exit'">
                        {{
                          survey.usersubgroup != null &&
                          survey.usersubgroup.name
                            ? survey.usersubgroup.usergroup.code +
                              " - " +
                              survey.usersubgroup.code
                            : ""
                        }}
                      </span>
                    </td>
                    <td class="align-middle">{{ survey.created_at }}</td>
                    <td class="align-middle">
                      {{ survey.start_at.format("DD/MM/YYYY") }}
                    </td>
                    <td class="align-middle">
                      {{ survey.end_at.format("DD/MM/YYYY") }}
                    </td>
                    <td class="align-middle text-left">
                      <b-button
                        size="sm"
                        :disable="selectedSurvey == null ? true : false"
                        variant="primary"
                        @click="checkReportTypeForSurvey(survey.id)"
                        v-if="
                          (institutionAdmin || groupAdmin || subgroupAdmin) &&
                            selectedSurveyType == 'student_feedback'
                        "
                        >Survey Report</b-button
                      >
                      <b-button
                        size="sm"
                        :disable="selectedSurvey == null ? true : false"
                        variant="danger"
                        @click="generateReport(survey.id)"
                        v-if="
                          (institutionAdmin || groupAdmin) &&
                            selectedSurveyType == 'programme_exit'
                        "
                        >Download Survey Report</b-button
                      >
                      <b-button
                        size="sm"
                        :disable="selectedSurvey == null ? true : false"
                        variant="danger"
                        @click="generateReport(survey.id)"
                        v-if="
                          (institutionAdmin || groupAdmin) &&
                            selectedSurveyType == 'course_exit'
                        "
                        >Download Survey Report</b-button
                      >
                      <br /><br />
                      <b-button
                        v-if="surveyParticipantsReport"
                        size="sm"
                        :disable="selectedSurvey == null ? true : false"
                        variant="primary"
                        @click="
                          getParticipantsReport(
                            survey.id,
                            survey.course,
                            survey.usersubgroup
                          )
                        "
                        >Participants Report</b-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-form-group>
        </b-form>
      </b-overlay>

      <!-- stud feedback survey report componant -->
      <b-modal
        id="surveyCourses"
        no-close-on-backdrop
        no-close-on-keyboard
        no-close-on-esc
        hide-footer
        modal-class="courseReport"
      >
        <SurveyCourseReport
          :survey_id="survey_id"
          :groupsWithSubgroupsOptions="groupsWithSubgroupsOptions"
          :selectedSurveyType="selectedSurveyType"
          :coursesOptions="coursesOptions"
          :getCustomNames="getCustomNames"
        />
      </b-modal>

      <!-- <div id="printSection"> -->
      <b-modal
        id="surveyParticipants"
        no-close-on-backdrop
        no-close-on-keyboard
        no-close-on-esc
        hide-footer
        :title="participantsReportTitle"
        modal-class="courseReport"
      >
        <SurveyParticipantReport
          :students="students"
          :groupsWithSubgroupsOptions="groupsWithSubgroupsOptions"
          :survey_id="survey_id"
          :selectedSurveyType="selectedSurveyType"
          :coursesOptions="coursesOptions"
          :getCustomNames="getCustomNames"
        />
      </b-modal>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SurveyCourseReport from "./SurveyCourseReport.vue";
import SurveyParticipantReport from "./SurveyParticipantReport.vue";
import moment from "moment";
import acl from "../../../../../authorization/acl.js";
export default {
  async created() {
    this.fetchData = await true;

    const surveyParticipantsReport = await acl.isUserGranted(
      "surveyParticipantsReport"
    );
    this.surveyParticipantsReport = await surveyParticipantsReport;

    const institutionAdmin = await acl.isUserGranted("institutionAdmin");
    this.institutionAdmin = institutionAdmin;

    const groupAdmin = await acl.isUserGranted("groupAdmin");
    this.groupAdmin = groupAdmin;

    const subgroupAdmin = await acl.isUserGranted("subgroupAdmin");
    this.subgroupAdmin = subgroupAdmin;

    this.surveyTypesOptions = [
      { value: null, text: "Select Survey Type", disabled: true },
      { value: "student_feedback", text: "Instructor Feedback Survey" },
      { value: "course_exit", text: "Course Exit Survey" },
      { value: "programme_exit", text: "Programme Exit Survey" },
    ];

    // await this.getSurveys();
    await this.getGroupList();
    await this.getMyCourses();
    this.fetchData = await false;
  },
  components: {
    // DatePicker,
    SurveyCourseReport,
    SurveyParticipantReport,
  },
  data() {
    return {
      institutionAdmin: null,
      groupAdmin: null,
      subgroupAdmin: null,
      courseInstructor: null,
      surveyCourseReport: null,
      fetchData: false,
      loadSurveyReport: false,
      survey: {},
      students: [],
      questionsChoices: [],
      usergroupOptions: [],
      courseFaculties: {},
      answersCounts: {},
      surveys: [],
      surveyTypesOptions: [
        { value: null, text: "Select Survey Type", disabled: true },
        { value: "student_feedback", text: "Instructor Feedback Survey" },
        { value: "course_exit", text: "Course Exit Survey" },
        { value: "programme_exit", text: "Programme Exit Survey" },
      ],
      surveyOptions: [],
      selectedSurveyType: null,
      selectedSurvey: null,
      coursesOptions: [],
      selectedCourse: null,
      groupsWithSubgroupsOptions: [],
      survey_id: null,
      selectedGroupSubgroup: null,
      surveyParticipantsReport: null,
      participantsReportTitle: null,
    };
  },

  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
    filterSurveys() {
      if (
        this.selectedSurveyType == "programme_exit" &&
        this.selectedGroupSubgroup != null
      ) {
        return this.surveys.filter((survey) => {
          return survey.usersubgroup_id == Number(this.selectedGroupSubgroup);
        });
      } else {
        return this.surveys;
      }
    },
  },
  methods: {
    generateReport(surveyId) {
      this.loadSurveyReport = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/studfbsurveys/${surveyId}/report/pdfexport`;
      this.$axios
        .post(
          url,
          {
            usersubgroup:
              this.selectedGroupSubgroup != null
                ? this.selectedGroupSubgroup
                : null,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            this.selectedSurveyType == "course_exit"
              ? "CourseExit.pdf"
              : "ProgrammeExit.pdf"
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.loadSurveyReport = false;
        })
        .catch(() => {
          this.loadSurveyReport = false;
        });
    },

    async getTeacherFeedbackReport(surveyId) {
      // function moved to that copnnant for better working
      this.survey_id = surveyId;
      this.loadSurveyReport = true;
      // const url =
      //   this.$store.getters.getAPIKey.mainAPI +
      //   `/studfbsurveys/${surveyId}/report`;
      // await this.$axios
      //   .post(url)
      //   .then((response) => {
      //     let responseData = response.data;

      // this.students = responseData.students;
      // this.questionsChoices = responseData.questions_choices;
      // this.courseFaculties = responseData.courses_faculties;
      // this.answersCounts = responseData.students_in_question_choices;
      // this.survey = responseData.survey;
      this.students = [];
      this.questionsChoices = [];
      this.courseFaculties = {};
      this.answersCounts = {};
      this.survey = {};
      if (this.selectedSurveyType == "student_feedback") {
        this.$bvModal.show("surveyCourses");
      }

      this.loadSurveyReport = false;
      // })
      // .catch((error) => {
      //   error;
      //   this.loadSurveyReport = false;
      // });
    },

    async getParticipantsReport(surveyId, course, usersubgroup) {
      if (this.selectedSurveyType == "student_feedback") {
        this.participantsReportTitle =
          "Participants Report for Instructor Feedback";
      } else if (this.selectedSurveyType == "course_exit") {
        this.participantsReportTitle =
          course != null && course.name
            ? `Participants Report for ${course.name}`
            : `Participants Report for Course Exit`;
      } else {
        this.participantsReportTitle =
          usersubgroup != null && usersubgroup.name
            ? `Participants Report for ${usersubgroup.name}`
            : `Participants Report for Programme Exit`;
      }
      this.survey_id = surveyId;
      this.loadSurveyReport = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/studfbsurveys/${surveyId}/studfbsurveystudents`;
      await this.$axios.get(url).then((response) => {
        this.students = response.data;
        this.$bvModal.show("surveyParticipants");
        this.loadSurveyReport = false;
        response;
      });
    },
    async getSurveys() {
      if (
        (this.selectedSurveyType == "course_exit" &&
          this.selectedCourse == null) ||
        (this.selectedSurveyType == "programme_exit" &&
          this.selectedGroupSubgroup == null)
      ) {
        return (this.surveys = []);
      }
      let surveyPostData;

      if (this.selectedSurveyType == "course_exit") {
        surveyPostData = {
          type: this.selectedSurveyType,
          course: this.selectedCourse,
        };
      } else if (this.selectedSurveyType == "programme_exit") {
        surveyPostData = {
          type: this.selectedSurveyType,
          usersubgroup: this.selectedGroupSubgroup,
        };
      } else {
        surveyPostData = {
          type: this.selectedSurveyType,
        };
      }

      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey + `/report`;
      await this.$axios
        .post(url, surveyPostData)
        .then((response) => {
          this.surveyOptions = [{ value: null, text: "Select Survey" }];
          this.surveys = [];
          const surveysData = response.data.surveys;
          surveysData.forEach((survey) => {
            this.surveys.push({
              id: survey.id,
              name: survey.name,
              summary: survey.summary,
              academic_year: survey.academic_year,
              created_at: moment(survey.created_at).format("DD/MM/YYYY"),
              start_at: moment(
                moment.utc(survey.start_at, "YYYY-MM-DD HH:mm:ss").toDate()
              ),
              end_at: moment(
                moment.utc(survey.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
              ),
              comment_feedback: survey.comment_feedback == 0 ? false : true,
              is_anonymous: survey.is_anonymous == 0 ? false : true,
              hidden: survey.hidden,
              time_required: survey.time_required,
              survey_type: survey.survey_type,
              course: survey.course,
              usersubgroup: survey.usersubgroup,
              usersubgroup_id: survey.usersubgroup_id,
            });

            this.surveyOptions.push({
              value: survey.id,
              text: survey.name,
            });
          });
          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.selectedSurveyType == null;
          this.fetchData = false;
          error;
        });
      this.fetchData = false;
    },
    getGroupList() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/usersubgroups`;
      this.$axios
        .get(url)
        .then((response) => {
          let custom =
            +this.getCustomNames.userSubgroup.custom_name != null
              ? "Select " + this.getCustomNames.userSubgroup.custom_name
              : "Select Usersubgroup";
          this.groupsWithSubgroupsOptions = [
            { value: null, text: custom, disabled: true },
          ];
          this.usergroupOptions = [
            { value: null, text: custom, disabled: true },
          ];
          let groupSubgroup = response.data;
          groupSubgroup.forEach((subgroup) => {
            this.groupsWithSubgroupsOptions.push({
              value: subgroup.id,
              text: subgroup.usergroup.name + " - " + subgroup.name,
            });
            this.usergroupOptions.push({
              value: subgroup.id,
              text: subgroup.usergroup.name + " - " + subgroup.name,
            });
          });

          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    getMyCourses() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/courses/withusers`;
      this.$axios
        .get(url)
        .then((response) => {
          let courses = response.data;
          this.coursesOptions = [{ value: null, text: "Select Course" }];
          courses.forEach((course) => {
            this.coursesOptions.push({
              value: course.id,
              text:
                course.name +
                " (" +
                course.code +
                "-" +
                course.usersubgroup.usergroup.code +
                "-" +
                course.usersubgroup.code +
                ")",
            });
          });
          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style >
.courseReport .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}
.courseReport .modal-body {
  overflow: scroll;
}
</style>
