<template>

  <div id="questionPaperPreview" class="" style="background-color: white;">

    <!-- header ----------------- -->
    <div class="customHeaderDiv mt-2" v-html="customHeader"></div>
    <!-- ------------------------------- -->
    <!-- questions --------------------- -->
    <b-row class="p-2" >
      <b-col class="ml-0 px-0" cols="1">Si.No.</b-col>
      <b-col class="ml-0 pl-0">Question</b-col>
      <b-col class="mr-0 px-0" cols="4">
        <div style="display: flex;">
          <div class="question-propertycol-20 border-right">Mark</div>
          <div class="question-propertycol-40 border-right">CO</div>
          <div class="question-propertycol-30 border-right">ML</div>
          <div class="question-propertycol-10">BL</div>
        </div>
      </b-col>
    </b-row>

    <div class="text-danger" v-if="loading"> Loading...</div>
    <div v-else v-for="(qORg, qgindex) in questionsAndGroupsAndSectionView" :key="qgindex">
      <div v-if="qORg.type == 'group'" class=" border-dark border-bottom border-top p-1 my-3 mx-1">
        <div class="text-center text-info" style="font-size: 14px !important;">
          Answer {{ groupObjects[qORg.id].best_of_questions }} out of {{ groupObjects[qORg.id].questions.length }}
          questions
          <!-- (score: {{ groupObjects[qORg.id].maximum_mark_of_question }}) -->
        </div>
        <!-- or question  -->
        <div v-for="(question, orQindex) in groupObjects[qORg.id].questions" :key="orQindex">
          <b-row>
            <b-col class="ml-0 px-0" cols="1"> {{ question.question_no }}.</b-col>
            <b-col class="ml-0 pl-0">
              <span v-for="(qData, qdIndex) in question.evaluation_scheme" :key="qdIndex" class="mr-1">
                <span class="big-font" v-if="qData.type == 'text'" v-html="latexCodeToMathLive(qData.value)"></span>
                <img class="questionPaperPreviewImg" v-if="qData.type == 'image'" style=""
                  :src="questionAttachments[qData.value].url">
              </span>
            </b-col>
            <b-col class="mr-0 px-0" cols="4">

              <div style="display: flex;" v-if="(question.subQuestions.length == 0 )">
                <div class="question-propertycol-20 border-right">{{ question.maximum_mark }}</div>
                <div class="question-propertycol-40 border-right"><span v-if="(question.course_outcome.length > 0)">
                    {{ question.course_outcome.map(item => item.code).join(', ') }}
                  </span></div>
                <div class="question-propertycol-30 border-right"> <span v-if="(question.module_id != null)">{{
                    moduleName(question.module_id)
                }}</span></div>
                <div class="question-propertycol-10"><span v-if="(question.blooms_taxonamy_level != null)">
                    {{ question.blooms_taxonamy_level }}
                  </span></div>
              </div>
            </b-col>
          </b-row>
          <!-- subquestion in orquestion  -->
          <div v-if="(question.subQuestions.length > 0)" class="mt-2 mb-2">
            <div v-for="(subQuestion, subQindex) in question.subQuestions" :key="subQindex" class=" mb-2">
              <b-row>
                <b-col class="ml-0 px-0 text-right  pr-2" cols="1">{{ subQuestion.question_no }}.</b-col>
                <b-col class="ml-0 pl-0">
                  <span v-for="(qData, qdIndex) in subQuestion.evaluation_scheme" :key="qdIndex" class="mr-1">
                    <span class="big-font" v-if="qData.type == 'text'" v-html="latexCodeToMathLive(qData.value)"></span>
                    <img class="questionPaperPreviewImg" v-if="qData.type == 'image'" style=""
                      :src="questionAttachments[qData.value].url">
                  </span>
                </b-col>
                <b-col class="mr-0 px-0" cols="4">

                  <div style="display: flex;" >
                    <div class="question-propertycol-20 border-right">{{ subQuestion.maximum_mark }}</div>
                    <div class="question-propertycol-40 border-right"><span
                        v-if="(subQuestion.course_outcome.length > 0)">
                        {{ subQuestion.course_outcome.map(item => item.code).join(', ') }}
                      </span></div>
                    <div class="question-propertycol-30 border-right">
                      <span v-if="(subQuestion.module_id != null)">{{ moduleName(subQuestion.module_id) }}</span>
                    </div>
                    <div class="question-propertycol-10"><span v-if="(subQuestion.blooms_taxonamy_level != null)">
                        {{ subQuestion.blooms_taxonamy_level }}
                      </span></div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
          <div v-if="(groupObjects[qORg.id].questions.length != orQindex + 1)" class="m-2 text-center font-weight-bold">
            OR</div>
        </div>
      </div>
      <!-- normal question  -->
      <div v-if="qORg.type == 'question'" class="mt-3 mb-2 mx-2 p-1">
        <b-row>
          <b-col class="ml-0 px-0" cols="1">{{ questionObjects[qORg.id].question_no }}.</b-col>
          <b-col class="ml-0 pl-0">
            <span v-for="(qData, qdIndex) in questionObjects[qORg.id].evaluation_scheme" :key="qdIndex" class="mr-1">
              <span class="big-font" v-if="qData.type == 'text'" v-html="latexCodeToMathLive(qData.value)"></span>
              <img class="questionPaperPreviewImg" v-if="qData.type == 'image'" style=""
                :src="questionAttachments[qData.value].url">
            </span>
          </b-col>
          <b-col class="mr-0 px-0" cols="4">

            <div style="display: flex;"
              v-if="(questionObjects[qORg.id].subQuestions.length == 0 )">
              <div class="question-propertycol-20 border-right">{{ questionObjects[qORg.id].maximum_mark }}</div>
              <div class="question-propertycol-40 border-right"><span
                  v-if="(questionObjects[qORg.id].course_outcome.length > 0)">
                  {{ questionObjects[qORg.id].course_outcome.map(item => item.code).join(', ') }}
                </span></div>
              <div class="question-propertycol-30 border-right"><span
                  v-if="(questionObjects[qORg.id].module_id != null)">{{
                      moduleName(questionObjects[qORg.id].module_id)
                  }}</span></div>
              <div class="question-propertycol-10"><span
                  v-if="(questionObjects[qORg.id].blooms_taxonamy_level != null)">{{
                      questionObjects[qORg.id].blooms_taxonamy_level
                  }}</span></div>
            </div>
          </b-col>
        </b-row>
        <!-- subquestions of normal question  -->
        <div v-if="(questionObjects[qORg.id].subQuestions.length > 0)" class="mt-2 mb-2 ">
          <div v-for="(subQuestion, subQindex) in questionObjects[qORg.id].subQuestions" :key="subQindex" class=" mb-2">
            <b-row>
              <b-col class="ml-0 px-0 text-right pr-2" cols="1"> {{ subQuestion.question_no }}.</b-col>
              <b-col class="ml-0 pl-0">
                <span v-for="(qData, qdIndex) in subQuestion.evaluation_scheme" :key="qdIndex" class="mr-1">
                  <span class="big-font" v-if="qData.type == 'text'" v-html="latexCodeToMathLive(qData.value)"></span>
                  <img class="questionPaperPreviewImg" v-if="qData.type == 'image'" style=""
                    :src="questionAttachments[qData.value].url">
                </span>
              </b-col>
              <b-col class="mr-0 px-0" cols="4">
                <div style="display: flex;" >
                  <div class="question-propertycol-20 border-right">{{ subQuestion.maximum_mark }}</div>
                  <div class="question-propertycol-40 border-right"><span
                      v-if="(subQuestion.course_outcome.length > 0)">
                      {{ subQuestion.course_outcome.map(item => item.code).join(', ') }}
                    </span></div>
                  <div class="question-propertycol-30 border-right">
                    <span v-if="(subQuestion.module_id != null)">{{ moduleName(subQuestion.module_id) }}</span>
                  </div>
                  <div class="question-propertycol-10"><span v-if="(subQuestion.blooms_taxonamy_level != null)">
                      {{ subQuestion.blooms_taxonamy_level }}
                    </span></div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div v-if="qORg.type == 'section'" class="m-2 p-1 text-center font-weight-bold">
        {{ sectionObjects[qORg.id].name }}
      </div>
    </div>
    <!-- ------------------------------- -->
    <!-- ---------footer  -->
    <!-- ---------------- -->
    <!-- graphs  ------------------------->

    <!-- -------------------------------- -->
  </div>
</template>

<script>

import Vue from 'vue';
import * as MathLive from 'mathlive';
Vue.use(MathLive);

export default {
  props: ["questionsAndGroupsAndSectionView", "groupObjects", "exam", "questionObjects", "sectionObjects"],
  async created() {
    // console.log(this.questionObjects);
    // this.questionsAndGroupsAndSectionView=this.questionsAndGroupsAndSectionViewProps,
    // this.groupObjects=this.groupObjectsProps,
    // this.exam=this.examProps,
    // this.questionObjects=this.questionObjectsProps,
    // this.sectionObjects=this.sectionObjectsProps,
    this.cloudStorageRoot = this.$store.getters.getAPIKey.userAttachments;

    for (const key in this.questionObjects) {
      const q = this.questionObjects[key];
      q.files.forEach(file => {
        this.questionAttachments[file.id] = {
          id: file.id, name: file.file_name, url: this.cloudStorageRoot + file.file_path + file.file_name
        };
      });
    }

    this.loading = true;
    await Promise.all([
      this.getCourseModules(),
      this.ExamEvaluationSchemeCustomHeader(),
    ]);
    this.loading = false;
    await MathLive.renderMathInDocument();

    for (const key in this.questionObjects) {
      const q = this.questionObjects[key];
      q.course_outcome.forEach(co => {
        this.courseOutcomes[co.id] = co;
      });
    }
    // this.prepareMarkDistributionGraph();
  },
  components: {
  },
  data() {
    return {
      courseOutcomes: {},
      bloomTaxonomyOptions: [
        {
          value: "6",
          text: "Creating",
          description: "User information to create something new",
        },
        {
          value: "5",
          text: "Evaluating",
          description: "Examine information and make judgement.",
        },
        {
          value: "4",
          text: "Analyzing",
          description: "Take apart from known and identify relationships.",
        },
        {
          value: "3",
          text: "Applying",
          description: "Use information in a new (but similar) situation.",
        },
        {
          value: "2",
          text: "Understanding",
          description: "Grasp meaning of instructional materials.",
        },
        {
          value: "1",
          text: "Remembering",
          description: "Recall specific facts.",
        },
      ],
      // questionsAndGroupsAndSectionView:[],
      // groupObjects:[],
      // exam:{},
      // questionObjects:[],
      // sectionObjects:[],
      loading: false,
      cloudStorageRoot: '',
      courseModules: [],
      customHeader: null,
      // slot_number: '',
      // footerData: { course_admin: '', maingroup_admin: '' },

      coScoreDistribution: {},
      taxonamyScoreDistribution: {},
      questionAttachments: {},
    };
  },
  methods: {
    latexCodeToMathLive(codeText){
      if (codeText==null ||codeText=='') return '';
      return codeText.replace(/\$\$(.*?)\$\$/g, '<math-field class="p-0" style="border: none;" readonly>$1</math-field>');
    },
    // questionAttachmentDetails(attachmentId, question) {
    //   // console.log(question);
    //   return question.files.find(item => item.id == attachmentId);
    // },
    moduleName(id) {
      let module = this.courseModules.find(item => item.id === id);
      if (module) return module.module;
      return id;
    },
    async getCourseModules() {
      let courseModuleUrl = this.$store.getters.getAPIKey.getCourseModules;
      const url = courseModuleUrl.replace("course_id", this.exam.course_id);
      await this.$axios.get(url).then((response) => {
        this.courseModules = response.data;
      });
    },

    async ExamEvaluationSchemeCustomHeader() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/exams/${this.exam.id}/evaluationscheme/customheader`;
      await this.$axios.get(url).then((response) => {
        this.customHeader = response.data;
      });
    },
  },
};
</script>

<style >
.question-propertycol-10 {
  width: 10%;
  word-break: break-all;
  text-align: center;
}

.question-propertycol-20 {
  width: 20%;
  word-break: break-all;
  text-align: center;
}

.question-propertycol-30 {
  width: 30%;
  word-break: break-all;
  text-align: center;
}

.question-propertycol-40 {
  width: 40%;
  word-break: break-all;
  text-align: center;
}

.questions-container button {
  padding: 1px !important;
}

@media print {
  .no-print {
    display: none !important;
  }

  .tempInput {
    border: none;
  }

  .questionPaperPreview {
    font-size: 14pt !important;
  }

  * {
    font-size: 14pt !important;
  }

}

.questionPaperPreviewImg {
  max-width: 100%;
  max-height: 600px;
}

.customHeaderDiv th {
  font-size: 12pt;
  background-color: #d7dcda;
}

.customHeaderDiv .data-tbody {
  font-size: 11pt;
  /* text-align: left; */
}

/* .data-table{
            table-layout: fixed;
            width: 660pt;
        } */
.customHeaderDiv .text-center {
  text-align: center;
}

.customHeaderDiv .header-table {
  font-size: 11pt !important;
  text-align: center;
}

.customHeaderDiv .header-table strong {
  font-size: 17pt !important;
  font-weight: 800;
}

.customHeaderDiv table {
  /* text-align: left; */
  width: 100%;
  border-collapse: collapse;
}

.customHeaderDiv table,
th,
td {
  border: 1px solid #494b4d;
}

.customHeaderDiv td {
  padding-bottom: 2pt;
  padding-top: 4pt;
  padding-left: 4pt;
  padding-right: 4pt;
}

.customHeaderDiv th {
  padding-bottom: 4pt;
  padding-top: 7pt;
  padding-left: 7pt;
  padding-right: 7pt;
}

.customHeaderDiv table tr td {
  height: auto;
}

.customHeaderDiv .wordbreak {
  word-wrap: break-word;
}
</style>
