<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-else>
      <b-form-checkbox v-if="editType == 'Users'" v-model="tableView"
        >Load Complete User Data</b-form-checkbox
      >

      <InstitutionUserTable v-if="tableView" :users="listOfUsersEnrolled" />
      <div v-else>
        <div v-if="editType == 'Profile'">
          <profile-update
            @cancelProfile="cancelProfile"
            :sortByOrder="sortByOrder"
            :institutionUserIds="institutionUsers"
            :groupInfo="groupInfo"
          >
          </profile-update>
        </div>
        <div v-else-if="editType == 'RollNumber'">
          <roll-number-update
            @rollNumbersUpdated="rollNumbersUpdated"
            :listOfUsersEnrolled="listOfUsersEnrolled"
            :selectedSubgroupUsers="selectedSubgroupUsers"
            :groupInfo="groupInfo"
          ></roll-number-update>
        </div>
        <div v-else class="subgroupUserList p-3">
          <div
            class="editOptions d-flex align-items-center p-3"
            style="
              border: 2px solid #eeeeeb;
              border-left-style: none;
              border-right-style: none;
            "
          >
            <b-form-checkbox
              size="lg"
              v-model="allUsersSelected"
              @change="toggleAll"
              :indeterminate="indeterminate"
              >{{
                allUsersSelected ? "Un-select All" : "Select All"
              }}</b-form-checkbox
            >
            <b-button
              variant="primary"
              size="sm"
              @click="subgroupInstitutionProfileUpdate()"
              :disabled="
                selectedSubgroupUsers.length == 0 || deleteBtn.disabled
              "
              >Profile <i class="fas fa-edit text-white ml-2"></i
            ></b-button>
            <b-button
              variant="primary"
              size="sm"
              @click="rollNumberUpdate()"
              :disabled="
                selectedSubgroupUsers.length == 0 || deleteBtn.disabled
              "
              >Roll Number <i class="fas fa-edit text-white ml-2"></i
            ></b-button>
            <b-button
              variant="danger"
              v-html="deleteBtn.text"
              @click="prepareSubgroupStudentForDelete()"
              size="sm"
              :disabled="
                selectedSubgroupUsers.length == 0 || deleteBtn.disabled
              "
              >{{ deleteBtn.text }}</b-button
            >
            <b-button
              variant="success"
              size="sm"
              :disabled="
                exportCsvBtn.disabled || listOfUsersEnrolled.length == 0
              "
              @click="exportToExcel('xlsx')"
              >{{ exportCsvBtn.text }}</b-button
            >

            <b-button variant="success" size="sm" @click="sortBy('name')"
              >Sort by name</b-button
            >
            <b-button variant="success" size="sm" @click="sortBy('invitation')"
              >Sort by invite</b-button
            >
            <b-button
              variant="success"
              :disabled="
                !enableFeeAlertButtons || selectedSubgroupUsers.length == 0
              "
              size="sm"
              @click="$bvModal.show('feeReminder-confirm')"
              >{{
                enableFeeAlertButtons ? "Send fee reminder" : "Please wait..."
              }}</b-button
            >
            <b-button
              variant="danger"
              class="ml-3"
              size="sm"
              @click="updateStudentsEnrollStatus('blocked')"
              :disabled="!enableEnrollStatusBtn"
              >{{ enableEnrollStatusBtn ? "Deactivate Account" : "working..." }}</b-button
            >
            <b-button
              variant="success"
              class="ml-3"
              size="sm"
              @click="updateStudentsEnrollStatus('active')"
              :disabled="!enableEnrollStatusBtn"
              >{{ enableEnrollStatusBtn ? "Activate Account" : "working..." }}</b-button
            >
            <b-modal
              id="feeReminder-confirm"
              no-close-on-backdrop
              no-close-on-keyboard
              no-close-on-esc
              hide-footer
              hide-header
              centered
            >
              <h4>Send fee Reminder SMS</h4>
              <p class="my-4">
                WARNING: You are going to send fee due SMS message to all
                parents of selected students. <br />
                (Sample SMS: Hi HARITHA ANU fee payment is due. Please make the
                payment at the earliest. Principal `COLLEGE CODE` Ignore if
                already paid.)
              </p>
              <b-row>
                <b-col
                  ><b-button
                    variant="danger"
                    size="sm"
                    @click="$bvModal.hide('feeReminder-confirm')"
                    >Cancel</b-button
                  ></b-col
                >
                <b-col class="text-right"
                  ><b-button
                    variant="success"
                    size="sm"
                    @click="sentFeeRemainder"
                    >Send</b-button
                  ></b-col
                >
              </b-row>
              <b-row class="mt-4">
                <b-col> Balance SMS Count: {{ smsCount }}</b-col></b-row
              >
            </b-modal>
          </div>
          <br />
          <div class="enrolledUsersTableView">
            <div class="table-responsive">
              <table
                class="table table-striped table-bordered"
                style="font-size: 14px; font-weight: 600"
                ref="subgroupUsers"
              >
                <thead class="thead-dark">
                  <tr>
                    <th></th>
                    <th>Roll No.</th>
                    <th>Name</th>
                    <th>Admission No.</th>
                    <th>Registration No.</th>
                    <th>Gender</th>
                    <th>Email</th>
                    <th>Phone No.</th>
                    <th>Guardian Mobile.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-if="listOfUsersEnrolled.length == 0"
                    class="text-danger"
                  >
                    <td colspan="100">No Students Found!</td>
                  </tr>
                  <tr
                    v-else
                    v-for="(student, studentIndex) in listOfUsersEnrolled"
                    :key="studentIndex"
                  >
                    <td>
                      <b-form-checkbox
                        size="sm"
                        v-model="selectedSubgroupUsers"
                        :value="student.id"
                      >
                      </b-form-checkbox>
                    </td>
                    <td class="align-middle">
                      {{
                        student.roll_number != null ? student.roll_number : "RN"
                      }}
                    </td>
                    <td class="align-middle">
                      <span
                        class="btn p-0 m-0 text-info font-weight-bolder text-capitalize text-capitalize"
                        @click="
                          goToUserProfile(
                            'UserProfile',
                            student.pivot.institution_user_id
                          )
                        "
                      >
                        {{ student.first_name || " " }}
                        {{ student.last_name || " " }}
                      </span>
                      <b-badge
                        class="m-1 p-1"
                        v-if="!student.user_id"
                        pill
                        variant="warning"
                        >Unenrolled
                      </b-badge>
                      <b-badge
                        v-if="student.enroll_status != 'active'"
                        variant="danger"
                        class="ml-2"
                      >
                        disabled
                      </b-badge>
                      <b-button
                        variant="info"
                        v-if="!student.user_id"
                        class="m-1 p-1"
                        size="sm"
                        @click="resendInvitation(student.institution_user_id)"
                        >Resend</b-button
                      >
                    </td>
                    <td class="text-uppercase align-middle">
                      {{ student.admission_no }}
                    </td>
                    <td class="text-uppercase align-middle">
                      {{ student.register_no }}
                    </td>
                    <td class="align-middle">
                      <span class="text-capitalize">
                        {{ student.gender != null ? student.gender : "-" }}
                      </span>
                    </td>
                    <td class="align-middle">{{ student.email }}</td>
                    <td class="align-middle">{{ student.mobile }}</td>
                    <td class="align-middle">{{ student.guardian_mobile }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ProfileUpdate from "./ProfileUpdate.vue";
import RollNumberUpdate from "./RollNumberUpdate.vue";
import XLSX from "xlsx";
import InstitutionUserTable from "../../../institution_users//InstitutionUserTable.vue";

export default {
  props: ["groupInfo"],
  async created() {
    await this.getEnrolledSubgroupUsers();
    this.smsCount = this.$store.getters.getSmsCount.smsBalance;
  },

  components: {
    profileUpdate: ProfileUpdate,
    rollNumberUpdate: RollNumberUpdate,
    InstitutionUserTable,
  },
  data() {
    return {
      institution_users: [],
      enableEnrollStatusBtn: true,
      enableFeeAlertButtons: true,
      fetchData: false,
      listOfUsersEnrolled: [],
      selectedSubgroupUsers: [],
      institutionUsers: [],
      allUsersSelected: false,
      indeterminate: false,
      editType: "Users",
      deleteBtn: {
        text: `Remove <i class="fas fa-trash text-white ml-2"></i>`,
        disabled: false,
      },
      tableView: false,
      exportCsvBtn: {
        text: "Export to Excel",
        disabled: false,
      },
      sortByOrder: "",
      smsCount: 0,
    };
  },
  methods: {
    updateStudentsEnrollStatus(status) {
      this.listOfUsersEnrolled.forEach((user) => {
        if (this.selectedSubgroupUsers.includes(user.id))
          this.institution_users.push(user.institution_user_id);
      });
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        "/institutionusers/enroll_status";
      axios
        .post(url, {
          institution_users: this.institution_users,
          enroll_status: status,
        })
        .then(() => {
          this.$toast.success("Enroll status updated successfully");
          this.selectedSubgroupUsers = [];
          this.institution_users = [];

          this.getEnrolledSubgroupUsers();
        })
        .catch(() => {
          this.$toast.error("Failed to update enroll status, please try again");
        });
      this.enableEnrollStatusBtn = true;
    },
    goToUserProfile(path, profileId) {
      let routeData = this.$router.resolve({
        name: path,
        params: {
          profileId: btoa(profileId),
        },
      });
      window.open(routeData.href, "_blank");
    },
    async exportToExcel(type, fn, dl) {
      this.exportCsvBtn.text = "Please wait...";
      this.exportCsvBtn.disabled = true;
      var elt = this.$refs.subgroupUsers;
      var wb = await XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      this.exportCsvBtn.text = "Export to Excel";
      this.exportCsvBtn.disabled = false;
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(
            wb,
            fn || this.groupInfo.name + "_users." + (type || "xlsx")
          );
    },
    cancelProfile() {
      this.editType = "Users";
      this.selectedSubgroupUsers = [];
      this.allUsersSelected = false;
      this.institutionUsers = [];
      this.getEnrolledSubgroupUsers();
    },
    async subgroupInstitutionProfileUpdate() {
      this.institutionUsers = [];
      await this.listOfUsersEnrolled.forEach((student) => {
        this.selectedSubgroupUsers.forEach((id) => {
          if (student.id == id) {
            this.institutionUsers.push(student.pivot.institution_user_id);
          }
        });
      });
      this.editType = "Profile";
    },
    async sentFeeRemainder() {
      this.$bvModal.hide("feeReminder-confirm");
      this.enableFeeAlertButtons = false;
      const url = `${this.$store.getters.getAPIKey.mainAPI}/usersubgroupusers/studentfeeduealert`;
      await this.$axios
        .post(url, {
          usersubgroup: this.groupInfo.id,
          students: this.selectedSubgroupUsers,
        })
        .then(() => {
          this.$toast.success(
            `Fee due alert successfully sent to selected students.`,
            {
              top: "top",
              duration: 3000,
            }
          );
        })
        .catch(() => {
          this.$toast.error(`Failed to sent Fee due alert`, {
            top: "top",
            duration: 6000,
          });
        });

      this.selectedSubgroupUsers = [];
      this.allUsersSelected = false;

      this.enableFeeAlertButtons = true;
    },
    rollNumbersUpdated() {
      this.editType = "Users";
      this.selectedSubgroupUsers = [];
      this.allUsersSelected = false;
      this.getEnrolledSubgroupUsers();
    },
    rollNumberUpdate() {
      this.editType = "RollNumber";
    },
    toggleAll(checked) {
      let subgroupUsers = [];
      this.listOfUsersEnrolled.forEach((user) => {
        subgroupUsers.push(user.id);
      });
      this.selectedSubgroupUsers = checked ? subgroupUsers.slice() : [];
    },

    async sortBy(order) {
      this.sortByOrder = order;
      this.getEnrolledSubgroupUsers();
    },
    async getEnrolledSubgroupUsers() {
      this.fetchData = true;
      let userList = this.$store.getters.getAPIKey.getEnrollSubgroupUsersList;
      let url = userList.replace("usersubgroup_id", this.groupInfo.id);
      if (this.sortByOrder != "") url = url + `?sortby=${this.sortByOrder}`;

      await this.$axios
        .get(url)
        .then((response) => {
          this.listOfUsersEnrolled = [];
          this.listOfUsersEnrolled = response.data;
          this.listOfUsersEnrolled = this.listOfUsersEnrolled.filter(
            (students) => {
              return students.pivot.usersubgrouprole_id == 2;
            }
          );
          this.sortedArray();
          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    sortedArray() {
      // this.listOfUsersEnrolled.sort(function(a, b) {
      //   return (
      //     a.roll_number - b.roll_number ||
      //     a.first_name.localeCompare(b.first_name)
      //   );
      // });
      return;
    },
    async prepareSubgroupStudentForDelete() {
      this.deleteBtn.text = `Removing <div class="spinner-grow spinner-grow-sm ml-2"></div>`;
      this.deleteBtn.disabled = true;

      for (const student in this.selectedSubgroupUsers) {
        let studentId = this.selectedSubgroupUsers[student];
        const deleteStudent = await this.deleteEnrolledSubgroupStudent(
          studentId,
          student
        );
        if (deleteStudent == "Completed") {
          this.deleteBtn.text = `Remove <i class="fas fa-trash text-white ml-2"></i>`;
          setTimeout(() => {
            this.deleteBtn.disabled = false;
          }, 3000);
          this.$toast.success("All students removed successfully.", {
            position: "top",
            duration: 3000,
          });
          this.selectedSubgroupUsers = [];
          this.allUsersSelected = false;
          this.getEnrolledSubgroupUsers();
        }
        if (!deleteStudent) {
          this.deleteBtn.text = `Remove <i class="fas fa-trash text-white ml-2"></i>`;
          setTimeout(() => {
            this.deleteBtn.disabled = false;
          }, 3000);
          this.$toast.error("Something went wrong!", {
            position: "top",
            duration: 3000,
          });
          return;
        }
      }
    },
    async deleteEnrolledSubgroupStudent(studentId, studentIndex) {
      let userId = this.$store.getters.getAPIKey.deleteSubGroupUser;
      const url = userId.replace("usersubgroup_user_id", studentId);
      let status = null;
      await this.$axios
        .delete(url)
        .then((response) => {
          response;
          if (studentIndex == this.selectedSubgroupUsers.length - 1) {
            status = "Completed";
          } else {
            status = "Deleting";
          }
        })
        .catch((error) => {
          error;
          status = false;
        });
      return status;
    },

    async resendInvitation(InstitutionUserId) {
      const url = `${this.$store.getters.getAPIKey.mainAPI}/institutionusers/${InstitutionUserId}/invitation/resent`;
      this.$axios
        .get(url)
        .then(() => {
          this.$toast.success(`Enrollment code sent successfully`, {
            top: "top",
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast.error(`Failed to sent enrollment code`, {
            top: "top",
            duration: 3000,
          });
        });
    },
  },
};
</script>

<style>
.subgroupUserList .editOptions button {
  margin-left: 10px;
}

.subgroupUserList i {
  font-size: 12px !important;
}
</style>
